<div class="card main-card mb-3">
    <div class="card-header">Condiciones Comerciales Aplicadas</div>
    <div class="card-body">
        <ngb-alert type="info" [dismissible]="false" *ngIf="condiciones?.length && pedidoTieneCambios">
            <strong> 
                Hay modificaciones en uno o más items del pedido.
                Las reglas se actualizarán luego de guardar.
            </strong>
        </ngb-alert>
        <ng-container *ngIf="condiciones && condiciones.length > 0; else noData">
            <app-cc-aplicada [condicion]="x" [pedido]="pedido" [edit]="edit" *ngFor="let x of condiciones"></app-cc-aplicada>
        </ng-container>
        <ng-template #noData>
            <p *ngIf="!loading">No hay condiciones comerciales aplicadas</p>
            <p *ngIf="loading">Cargando...</p>
        </ng-template>
    </div>
</div>