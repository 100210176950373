import { Component, Input, Output, EventEmitter } from "@angular/core";
import { GroupsService } from './groups.service';
import { ColumnDefinition, GroupOrdering, CellDecorator, GroupDefinition } from './groups-table.model';
import { BehaviorSubject, combineLatest, Observable, Subscription } from 'rxjs';
import { tap, map, catchError, filter, startWith, distinctUntilChanged, debounceTime } from 'rxjs/operators';
import { Group } from './groups.model';
import { FormControl } from '@angular/forms';

@Component({
    selector: 'app-groups-table',
    templateUrl: './groups-table.component.html',
    styleUrls: ['./groups-table.component.scss']
})
export class GroupsTableComponent {
    private subscriptions: Subscription[] = [];

    @Input() paginate: boolean

    @Input() columnDefs: ColumnDefinition[] = [];
    @Input() groupsDefs: GroupDefinition[] = [];
    @Input() ordering: GroupOrdering[] = [];
    
    private data$: BehaviorSubject<any[]> = new BehaviorSubject([]);
    
    @Input() set data (value: any[]) {
        this.data$.next(value);
    }
    
    get data(): any[] {
        return this.data$.getValue();
    }

    hiddenColumns$: Observable<string[]> = this.data$
        .pipe(
            startWith([]),
            map(data => this.columnDefs
                .filter(col => col.hidden || (col.hideIfNoData && data.every(entry => !entry[col.bindProperty])))
                .map(col => col.bindProperty)
            )
        );

    private groupBy$ = new BehaviorSubject<string>('');
    @Input() set groupBy (val: string) {
        this.groupBy$.next(val);
    }

    get groupBy() {
        return this.groupBy$.getValue();
    }

    private collapse$ = new BehaviorSubject<boolean>(true);
    @Input() set collapse (val: boolean) {
        this.collapse$.next(val);
    }

    get collapse() {
        return this.collapse$.getValue();
    }

    private _groups$: BehaviorSubject<Group<any>[]> = new BehaviorSubject([]);
    groups$ = combineLatest([this._groups$, this.collapse$])
        .pipe(
            tap(([groups, collapse]) => groups.forEach(g => g.setCollapse(!collapse))),
            map(([groups]) => groups),
        );
    
    collectionSize$ = this._groups$.pipe(
        distinctUntilChanged(),
        map((groups) => groups.length),
        distinctUntilChanged()
    );

    searchBox = new FormControl('');
    @Output() search = new EventEmitter<string>();

    constructor(private groupsService: GroupsService) {}

    ngOnInit () {
        this.subscriptions.push(
            combineLatest([this.data$, this.groupBy$])
                .pipe(
                    filter(([data]) => ![null, undefined].includes(data)),
                    map(([data, groupByProp]): Group<any>[] => this.groupsService.group<any>(data, groupByProp, 'grupo_txt', this.collapse)),
                    map((groups: Group<any>[]) => groups.sort((a, b) => a.label < b.label ? -1 : 1)),
                    map((groups: Group<any>[]) => {
                        for (var order of this.ordering) {
                            if (order.order == 'last') {
                                groups = this.groupsService.moveToLastPosition<any>(groups, i => i.value == order.group);
                            }
                        }
                        return groups;
                    }),
                    // map((groups: Group<any>[]) => this.groupsService.moveToLastPosition<any>(groups, i => i.value == GRUPO_OTROS)),
                    map((groups: Group<any>[]) => this.groupsService.moveToLastPosition<any>(groups, i => i.value == '?')),
                    /* catchError((): Group<any>[] => {
                        return [new Group<any>('*', '', '', [])];
                    }), */
                )
                .subscribe(this._groups$)
        );

        this.subscriptions.push(
            this.searchBox.valueChanges
                .pipe(
                    debounceTime(500),
                    distinctUntilChanged()
                )
                .subscribe(this.search)
        );
    }

    ngOnDestroy() {
        this.subscriptions.forEach(s => s.unsubscribe());
    }

    renderCell(def: ColumnDefinition, data: any) {
        var content: string = def.transform? def.transform(data[def.bindProperty]) : data[def.bindProperty];
        /* if (def.decorator) {
            var d: CellDecorator = (def.decorator instanceof Function) ? def.decorator(data[def.bindProperty]) : def.decorator;
            content = `<${d.tag} class=${d.class}>${content}</${d.tag}`;
        }
        var align = def.textAlign || 'left';
        var tag = def.bold ? 'th' : 'td'; */
        // return `<${tag} class="text-${align}">${content}</${tag}>`;
        return content;
    }

    renderGroup(group: any, def: ColumnDefinition) {
        if (def.groupdata === true && def.hidden != true) {
            var total = 0;
            group.data.forEach(data => {
                if (def.bindProperty !== 'objetivo_ps' && 
                    def.bindProperty !== 'c_monto' && 
                    def.bindProperty !== 'a_monto' &&
                    def.bindProperty !== 'saldo_ps') { 
                total += (def.transform ? def.transform(data[def.bindProperty]) : data[def.bindProperty]);
                    } else {
                        if (data[def.bindProperty] === undefined ) {
                            total += 0;
                        } else {
                            total += Math.round((parseFloat(data[def.bindProperty]) + Number.EPSILON) * 100) / 100
                        }
                    }
            });
            return (def.transform ? def.transform(total) : total);
        }else{
            return ''; // titlecase
        }
        
    }

    makeHiddenColumnsList() {
        
    }
}