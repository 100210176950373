import { ItemPedido } from "./ItemPedido.model";
import { ClientePedido } from "./ClientePedido.model";
import { TextPedido } from "./TextPedido.model";
import { Cliente, ClienteDatoComercial, Interlocutor } from "src/app/clientes/models/Cliente.model";
import { ClassField } from "@angular/compiler";
import { User } from "src/app/models/User.model";
import { MaterialBasico } from "src/app/lista-precios/models/ListaPrecios.model";
import { UnidadMedida } from "../../lista-precios/models/ListaPrecios.model";
import {
  Condicion,
  CondicionCanal,
  CondicionVarianteOrderHeaderIn,
  IAccionSecundariaAplicada,
} from "src/app/condiciones-comerciales/condiciones.models";

export type CondicionesEntrega = {
  interlocutor: Interlocutor;
  incoterms: 'UN'|'RET'
}

export class Pedido implements OrderHeaderIn {
  accionesSecundariasManuales: IAccionSecundariaAplicada[];
  claseDocsSap?: any;
  vendedor?: User;
  peso_total?: number;
  apr_cred?: string;
  pernrAndNombreVendedor?: string;
  estadoColor?: string;
  id?: number;
  estado?: number;
  estado0?: EstadoPedido;
  wfl_status: string;
  estadoDesc: string;
  activo: boolean;
  confirmado?: boolean;
  orderItems: OrderItemsIn[] = [];
  orderPartners: OrderPartners[] = [];
  //combos: number[];
  aplicar_combos: { id: number; variante_id: number }[];
  //orderHeaderInCombos: IOrderHeaderInCombo[];
  condicionVarianteOrderHeaderIns: CondicionVarianteOrderHeaderIn[];
  entregaGratuita?: Omit<OrderHeaderIn, 'orderItems'> & {
    orderItemsIns: OrderItemsIn[];
  };
  direccion_entrega: OrderPartners;
  importe_total?: number;
  impNetoPendiente?: number;
  valorFacturado?: number;
  diasAtraso?: number;
  ultimaObservacion?: IOrderHeaderObservacion;
  PMNTTRMS: string;
  text?: TextPedido;
  mensajes?: string[];
  kUNNR: Cliente;
  KUNNR: string;
  SALESDOCUMENTIN?: string;
  DOC_TYPE?: string;
  SALES_ORG: string;
  DISTR_CHAN: string;
  DIVISION: string;
  SALES_GRP?: string;
  SALES_OFF?: string;
  REQ_DATE_H?: string;
  DATE_TYPE?: string;
  PURCH_DATE: string;
  PO_METHOD?: string;
  REF_1?: string;
  PRICE_GRP?: string;
  SALES_DIST?: string;
  PRICE_LIST?: string;
  INCOTERMS1?: string;
  INCOTERMS2?: string;
  DLV_BLOCK?: string;
  BILL_BLOCK?: string;
  ORD_REASON?: string;
  COMPL_DLV?: string;
  PRICE_DATE?: string;
  PURCH_NO_C?: string;
  PURCH_NO_S?: string;
  PO_DAT_S?: string;
  PO_METH_S?: string;
  SD_DOC_CAT?: string;
  DOC_DATE?: string;
  SHIP_COND?: string;
  DLV_TIME?: string;
  CURRENCY?: string;
  SHIP_TYPE?: string;
  pLANT: string;
}

export class Descuento implements OrderConditionsIn {
  id?: number;
  order_items_in_id?: number;
  SALESDOCUMENTIN?: string;
  ITM_NUMBER?: string;
  COND_ST_NO?: string;
  COND_TYPE: string;
  COND_VALUE?: number;
  CURRENCY?: string;
  CONDVALUE: number;
}

export class PedidoItem implements OrderItemsIn {
  id?: number;
  order_header_in_id?: number;
  SALESDOCUMENTIN?: string;
  ITM_NUMBER?: string;
  PO_ITM_NO?: string;
  MATERIAL: string;
  DLV_GROUP?: string;
  REASON_REJ?: string;
  BILL_BLOCK?: string;
  PLANT: string;
  STOR_LOC?: string;
  TARGET_QTY: number;
  TARGET_QU: string;
  ITEM_CATEG?: string;
  SALES_UNIT?: string;
  DLV_PRIO?: string;
  SHIP_POINT?: string;
  ROUTE?: string;
  ORDER_PROB?: string;
  CUST_MAT35?: string;
  LOG_SYSTEM_OWN?: string;
  orderConditionsIns?: OrderConditionsIn[] = [];
  tARGETQU?: UnidadMedida;
  cantidadBonificada: number;
  estadoDespacho: null | { fecha: string; preparacion: number; distribucion: number; confirmado: number; pendiente: number; };
  estadoLogistico: {
    estado: string;
  };
  bonifica_canal: boolean;
  
  constructor(data: Partial<OrderItemsIn> = {}) {
    Object.assign(this, data);
  }
}

export class OrderHeaderInCombo implements IOrderHeaderInCombo {
  order_header_in_id: number;
  combo_id: number;
  combo_behavior_id: number;
  valida: number;
  condicion: Condicion;
}

export interface IOrderHeaderInCombo {
  order_header_in_id: number;
  combo_id: number;
  combo_behavior_id: number;
  valida: number;
  condicion: Condicion;
}

export interface OrderHeaderInK extends OrderHeaderIn {
  accionSecundariaAplicada: IAccionSecundariaAplicada;
}
export interface OrderHeaderIn {
  id?: number;
  accionesSecundariasManuales: IAccionSecundariaAplicada[];
  datoComercial?: ClienteDatoComercial;
  estado?: number;
  estado0?: EstadoPedido;
  wfl_status: string;
  estadoDesc: string;
  activo: boolean;
  confirmado?: boolean;
  // cliente?: ClientePedido;
  orderItems: OrderItemsIn[];
  orderPartners: OrderPartners[];
  direccion_entrega: OrderPartners;
  claseDocsSap?: any;
  vendedor?: User;
  importe_total?: number;
  impNetoPendiente?: number;
  valorFacturado?: number;
  diasAtraso?: number;
  peso_total?: number;
  apr_cred?: string;
  ultimaObservacion?: IOrderHeaderObservacion;
  //combos: number[];
  aplicar_combos: {
    id: number;
    variante_id: number;
    acciones_secundarias?: IAccionSecundariaAplicada[];
  }[];
  //orderHeaderInCombos: IOrderHeaderInCombo[];
  condicionVarianteOrderHeaderIns: CondicionVarianteOrderHeaderIn[];
  entregaGratuita?: Omit<OrderHeaderIn, 'orderItems'> & {
    orderItemsIns: OrderItemsIn[];
  };
  PMNTTRMS: string;
  text?: TextPedido;
  kUNNR: Cliente;
  mensajes?: string[];
  pernrAndNombreVendedor?: string;
  estadoColor?: string;
  advertencias?: string[];

  /**
   * Auxiliar:
   * Cliente
   */
  KUNNR: string;

  /** Atributos de SAP */

  /**
   * Número de Documento de ventas
   */
  SALESDOCUMENTIN?: string;

  /**
   * Clase de documento de ventas (sigla)
   */
  DOC_TYPE?: string;

  /**
   * Organización de ventas (ID)
   */
  SALES_ORG: string;

  /**
   * Canal de Distribución (id)
   */
  DISTR_CHAN: string;

  /**
   * Sector
   */
  DIVISION: string;

  /**
   * Grupo de Vendedores
   */
  SALES_GRP?: string;

  /**
   * Oficina de Ventas
   */
  SALES_OFF?: string;

  /**
   * Fecha de preferencia de entrega
   */
  REQ_DATE_H?: string;

  /**
   * Formato de Fecha (por defecto es "D" del lado del servidor)
   */
  DATE_TYPE?: string;

  /**
   * Fecha pedido de compra de cliente
   */
  PURCH_DATE: string;

  /**
   * Clase de Pedido de Cliente
   */
  PO_METHOD?: string;

  /**
   * Referencia
   */
  REF_1?: string;

  /**
   * Grupo de Precios - Cliente
   */
  PRICE_GRP?: string;

  /**
   * Zona de Ventas
   */
  SALES_DIST?: string;

  /**
   * Tipo de Lista de Precios
   */
  PRICE_LIST?: string;

  /**
   * Incoterms pt 1
   */
  INCOTERMS1?: string;

  INCOTERMS2?: string;

  /**
   * Bloqueo de nota de entrega
   */
  DLV_BLOCK?: string;

  /**
   * Bloqueo de clase de factura
   */
  BILL_BLOCK?: string;

  /**
   * Motivo del Pedido
   */
  ORD_REASON?: string;

  /**
   * Entrega completa del pedido
   */
  COMPL_DLV?: string;

  /**
   * Fecha de determinación de precios
   * (Por defecto es la fecha de alta del documento, se pone en el servidor)
   */
  PRICE_DATE?: string;

  /**
   * Número de pedido de cliente
   */
  PURCH_NO_C?: string;

  /**
   * Número de pedido según destinatario
   */
  PURCH_NO_S?: string;

  /**
   * Fecha de pedido según destinatario
   */
  PO_DAT_S?: string;

  /**
   * Clase de pedido del destinatario
   */
  PO_METH_S?: string;

  /**
   * Tipo de Documento Comercial
   */
  SD_DOC_CAT?: string;

  /**
   * Fecha de Documento (por defecto el servidor pone la fecha actual)
   */
  DOC_DATE?: string;

  /**
   * Condición de Expedición
   */
  SHIP_COND?: string;

  /**
   * Plazo de entrega acordado
   */
  DLV_TIME?: string;

  /**
   * Moneda del documento
   */
  CURRENCY?: string;

  /**
   * Clase de expedición
   */
  SHIP_TYPE?: string;

  /**
   * Centro de Suministro por default
   */
  pLANT: string;
}

export interface OrderPartners {
  id?: number;
  order_header_in_id?: number;
  SALESDOCUMENTIN?: string;
  PARTN_NUMB: string;
  PARTN_ROLE: string;
  ITM_NUMBER: string;
  rol?: string;
  nombre?: string;
}

export class OrderHeaderObservacion implements IOrderHeaderObservacion {
  id?: number;
  OBSERVACION?: string;
  order_header_in_id?: number;
}

export interface IOrderHeaderObservacion {
  id?: number;
  OBSERVACION?: string;
  order_header_in_id?: number;
}

export interface OrderItemsIn {
  id?: number;
  ABGRU?: string;
  BILL_BLOCK?: string;
  CUST_MAT35?: string;
  DLV_GROUP?: string;
  DLV_PRIO?: string;
  ITEM_CATEG?: string;
  ITM_NUMBER?: string;
  LOG_SYSTEM_OWN?: string;
  MATERIAL: string;
  ORDER_PROB?: string;
  PLANT: string;
  PO_ITM_NO?: string;
  REASON_REJ?: string;
  ROUTE?: string;
  SALESDOCUMENTIN?: string;
  SALES_UNIT?: string;
  SHIP_POINT?: string;
  STATUS?: string;
  STOR_LOC?: string;
  TARGET_QTY: number;
  TARGET_QU: string;
  cantPendiente?: number;
  cantidadBonificada: number;
  descuentoPorAcuerdos?: number;
  impNetoPendiente?: number;
  itemEntregaGratuita?: OrderItemsIn;
  importe?: number;
  mATERIAL?: MaterialBasico;
  orderConditionsIns?: OrderConditionsIn[];
  order_header_in_id?: number;
  retorno?: number;
  tARGETQU?: UnidadMedida;
  valorFacturado?: number;
  estadoLogistico: {
    estado: string;
  },
  estadoDespacho: null | {
    fecha: string;
    preparacion: number;
    distribucion: number;
    confirmado: number;
    pendiente: number;
  };
  bonifica_canal: boolean;
}

export interface ConditionType {
  COND_TYPE: string;
  VTEXT: string;
  KRECH: string;
}

export interface OrderConditionsIn {
  id?: number;
  order_items_in_id?: number;
  SALESDOCUMENTIN?: string;
  ITM_NUMBER?: string;
  COND_ST_NO?: string;
  COND_TYPE: string;
  COND_VALUE?: number;
  CURRENCY?: string;
  conditionType?: ConditionType;
}

export interface EstadoPedido {
  id: number;
  estado: string;
}
