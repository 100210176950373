import { HttpClient } from "@angular/common/http";
import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  OnInit,
} from "@angular/core";
import {
  AbstractControl,
  FormBuilder,
  ValidatorFn,
  Validators,
} from "@angular/forms";
import * as moment from "moment";
import {
  BehaviorSubject,
  merge,
  Observable,
  of,
  Subject,
  Subscription,
} from "rxjs";
import {
  distinctUntilChanged,
  filter,
  map,
  shareReplay,
  switchMap,
  tap,
  withLatestFrom,
} from "rxjs/operators";
import { CatalogoFactoryService } from "src/app/catalogos/factory.service";
import { CanalDistribucion } from "src/app/clientes/models/Cliente.model";
import { OrderHeaderIn } from "src/app/pedidos/models/Order.model";
// import { GenericApiClient } from "src/app/utils/api/generic-api-client.service";
import { Sort } from "src/app/utils/datasource/page.model";
import { NoRecordsError } from "src/app/utils/errors/NoRecordsError";
import { NotificationService } from "src/app/utils/notifications/notification.service";
import { ifNotNullOrUndefined } from "src/app/utils/operators";
import { ISort } from "src/app/utils/sortable/sortable-header.model";
import { ButtonGroupOptions } from "src/app/widgets/button-group-selector.component";
import { PaginationDataSource } from "../../utils/datasource/pagination-datasource.service";
import { ExcelService } from "../../utils/excel.service";
import {
  SolicitudesCounts,
  SolicitudesSecundariasService,
} from "./solicitudes-secundarias.service";

const validarDesdeHasta: ValidatorFn = (control: AbstractControl) => {
  const desdeControl = control.get("desde");
  const hastaControl = control.get("hasta");

  if (hastaControl.value && desdeControl.value) {
    if (hastaControl.value < desdeControl.value) {
      return { desdeHasta: "Desde no puede ser mayor que Hasta" };
    }
  }

  return null;
};

@Component({
  selector: "app-solicitudes-secundarias",
  templateUrl: "./solicitudes-secundarias.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  styles: [
    `
      th {
        font-size: 0.88rem;
        font-weight: 500;
      }
    `,
  ],
})
export class SolicitudesSecundariasComponent implements OnInit, OnDestroy {
  private subscriptions: Subscription[] = [];

  filters = this.fb.group(
    {
      desde: [moment().format("YYYYMM"), Validators.required],
      hasta: [moment().format("YYYYMM"), Validators.required],
      parent_DISTR_CHAN: [""],
    },
    { validators: [validarDesdeHasta] }
  );

  statusFilter = this.fb.control("");

  searchQuery = new BehaviorSubject(null);

  anioMin$: Observable<number> = this.secundariasService
    .fetchAnios()
    .pipe(map((x) => x.anioMin));
  canales$: Observable<CanalDistribucion[]>;

  counts: Subject<SolicitudesCounts> = new Subject();

  opcionesEstado$: Observable<ButtonGroupOptions> = merge(
    of([
      { label: `Pend. Aprob.`, value: "pend-aprob", type: "primary" },
      { label: `Pend. NC`, value: "pend-nc", type: "primary" },
      { label: `NC`, value: "pend-cierre", type: "primary" },
      { label: `TODOS`, value: "", type: "primary" },
    ] as ButtonGroupOptions),
    this.counts.pipe(
      map(
        (x) =>
          [
            {
              label: `Pend. Aprob. (${x.aprobacion})`,
              value: "pend-aprob",
              type: "primary",
            },
            {
              label: `Pend. NC (${x.pendientes})`,
              value: "pend-nc",
              type: "primary",
            },
            {
              label: `NC (${x.facturados})`,
              value: "pend-cierre",
              type: "primary",
            },
            { label: `TODOS (${x.todos})`, value: "", type: "primary" },
          ] as ButtonGroupOptions
      )
    )
  );

  sort: BehaviorSubject<ISort<OrderHeaderIn>> = new BehaviorSubject({
    property: "DOC_DATE",
    ascending: false,
  });

  solicitudesDatasource = new PaginationDataSource<any, any>(
    (request, q) => this.secundariasService.fetchPage(request, q),
    this.mapSort(this.sort.value),
    this.filters.value
  );

  constructor(
    private secundariasService: SolicitudesSecundariasService,
    private fb: FormBuilder,
    catalogoFactory: CatalogoFactoryService,
    private http: HttpClient,
    private excelService: ExcelService,
    // private apiClient: GenericApiClient<any>,
    private readonly notiService: NotificationService
  ) {
    // this.apiClient = new GenericApiClient<any>("/secundarias", this.http);
    this.canales$ = catalogoFactory
      .getCanalesDistribucion()
      .list()
      .pipe(
        map((canales) => [{ VTWEG: "", VTEXT: "TODOS" }, ...canales]),
        shareReplay()
      );
  }

  ngOnInit() {
    const searchSubscription = this.searchQuery
      .pipe(ifNotNullOrUndefined())
      .subscribe((x) => {
        this.secundariasService
          .fetchCounts(x)
          .subscribe((x) => this.counts.next(x));
        this.solicitudesDatasource.queryBy(x);
      });

    this.subscriptions.push(searchSubscription);

    const statusFilterSubscription = this.statusFilter.valueChanges
      .pipe(distinctUntilChanged(), withLatestFrom(this.searchQuery))
      .subscribe(([status, prev]) =>
        this.searchQuery.next({ ...prev, status })
      );

    this.subscriptions.push(statusFilterSubscription);

    const sortSubscription = this.sort
      .pipe(map((x) => this.mapSort(x)))
      .subscribe((x) => this.solicitudesDatasource.sortBy(x));
    this.subscriptions.push(sortSubscription);

    this.searchQuery.next({
      ...this.filters.value,
      status: this.statusFilter.value,
    });
  }

  ngOnDestroy() {
    this.subscriptions.forEach((x) => x.unsubscribe());
  }

  onSubmit() {
    if (this.filters.valid) {
      const prev = this.searchQuery.value;
      this.searchQuery.next({ ...prev, ...this.filters.value });
    }
  }

  onDownload() {
    if (this.filters.valid && this.statusFilter.valid) {
      const params = { ...this.filters.value, ...this.statusFilter.value };
      this.notiService.info("Preparando descarga...", "Descarga a Excel");

      this.secundariasService.getExcelDownloadUrl(params).subscribe({
        next: (url) => {
          this.excelService.downloadFile(url);
        },
        error: (err) => {
          if (err instanceof NoRecordsError) {
            this.notiService.info(err.message, "Descarga a Excel");
            return;
          }
          throw err;
        },
      });
    }
  }

  setSort(prop: keyof OrderHeaderIn) {
    let current = this.sort.value;
    let next =
      current.property == prop
        ? { ...current, ascending: !current.ascending }
        : { property: prop, ascending: true };

    this.sort.next(next);
  }

  private mapSort(value: ISort<any>): Sort<any> {
    return {
      property: value.property,
      order: value.ascending ? "asc" : "desc",
    };
  }
}
