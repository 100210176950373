<div class="d-flex" *ngIf="!currentUser">No current user</div>
<div class="d-flex" *ngIf="currentUser">
  <div class="header-btn-lg pr-0">
    <div class="widget-content p-0">
      <div class="widget-content-wrapper">
        <div class="widget-content-left">
          <div class="btn-group" ngbDropdown placement="bottom-right">
            <button type="button" class="btn btn-link p-0 mr-2" ngbDropdownToggle>
              <span>
                <div class="icon-wrapper icon-wrapper-alt rounded-circle">
                  <img width="42" [src]="currentUser.avatar || defaultAvatar" alt="" class="rounded-circle" />
                </div>
              </span>
            </button>
            <div class="dropdown-menu-lg user-box-dropdown" ngbDropdownMenu>
              <div class="dropdown-menu-header">
                <div class="dropdown-menu-header-inner bg-info">
                  <div class="menu-header-image opacity-2 dd-header-bg-6"></div>
                  <div class="menu-header-content text-left">
                    <div class="widget-content p-0">
                      <div class="widget-content-wrapper">
                        <div class="widget-content-left mr-3">
                          <img width="42" [src]="currentUser.avatar || defaultAvatar" alt="" class="rounded-circle" />
                        </div>
                        <div class="widget-content-left">
                          <div class="widget-heading">{{ currentUser.firstname }} {{ currentUser.lastname }}</div>
                          <div class="widget-subheading opacity-8">{{ roleLabel }}</div>
                        </div>
                        <div class="widget-content-right mr-2">
                          <button class="btn-pill btn-shadow btn-shine btn btn-focus" (click)="service.logout()">
                            Salir
                          </button>

                          <!-- <a [href]="LOGOUT_URL" class="btn-pill btn-shadow btn-shine btn btn-focus">Salir</a> -->
                        </div>
                        <app-autorizaciones *ngIf="tengoAutorizaciones"></app-autorizaciones>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div style="font-weight: bold; color: red" class="widget-content-right ml-3 header-user-info" *ngIf="estoyImpersonado">
          <div class="widget-heading">{{ nombre }} {{ apellido }}</div>
          <div class="widget-subheading">{{ roleLabel }}</div>
        </div>
        <div *ngIf="!estoyImpersonado" class="widget-content-right ml-3 header-user-info">
          <div class="widget-heading">{{ nombre }} {{ apellido }}</div>
          <div class="widget-subheading">{{ roleLabel }}</div>
        </div>
      </div>
    </div>
  </div>
</div>
