import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { AUTORIZAR_PEDIDOS_PERMISSION } from 'src/app/pedidos/pedidos.permissions';
import { PedidosService } from 'src/app/pedidos/pedidos.service';
import { URLHelper } from 'src/app/utils/url-helper.helper';
import { VendedoresService } from 'src/app/vendedores.service';
import { environment } from 'src/environments/environment';
import { ThemeOptions } from '../../../theme-options';


@Component({
    selector: 'app-sidebar',
    templateUrl: './sidebar.component.html',
    styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit, OnDestroy {
    private subscriptions: Subscription[] = [];

    public extraParameter: any;

    readonly vendedores$ = this.vendedoresService.getVendedores().pipe(take(1));

    readonly logisticaHref = URLHelper.concat(environment.LOGISTICA_HREF, 'consultas', 'despachos-realizados');
    readonly logopsHref = URLHelper.concat(environment.LOGOPS_HREF, 'despachos-realizados');

    readonly panels$ = this.createPanelsObservable();

    private innerWidth: number;
    
    activeId = 'clientesMenu';

    get mustCollapse() {
        return this.innerWidth < 1200;
    }

    constructor(
        public globals: ThemeOptions, 
        private activatedRoute: ActivatedRoute, 
        public pedidosService: PedidosService,
        private vendedoresService: VendedoresService,
        private router: Router,
    ) { }

    ngOnInit() {
        setTimeout(() => {
            this.innerWidth = window.innerWidth;

            if (this.mustCollapse) {
                this.globals.toggleSidebar = true;
            }
        });

        this.extraParameter = this.activatedRoute.snapshot.firstChild.data.extraParameter;

        const closeOnNavigation = this.router.events.subscribe(() => {
            if (!this.globals.toggleSidebar) this.globals.toggleSidebar = this.mustCollapse;
        });

        this.subscriptions.push(closeOnNavigation);
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach(x => x.unsubscribe());
    }

    toggleSidebar() {
        this.globals.toggleSidebar = !this.globals.toggleSidebar;
    }

    @HostListener('window:resize', ['$event'])
    onResize(event) {
        this.innerWidth = event.target.innerWidth;
        this.globals.toggleSidebar = this.mustCollapse;
    }

    openLogistica() {
        let url = URLHelper.concat(environment.LOGISTICA_HREF, 'consultas', 'despachos-realizados');
        window.open(url);
    }

    private createPanelsObservable() {
        return this.vendedores$.pipe(map(vendedores => [
            {
                id: 'ventas',
                title: 'Gestión de Ventas',
                items: [
                    {
                        title: 'Pedidos',
                        routing: {
                            routerLink: '/clientes',
                        }
                    },
                    {
                        title: 'Pedidos a Aprobar',
                        routing: {
                            routerLink: '/pedidos/aprobacion',
                            routerLinkActiveOptions: { exact: true },
                        },
                        permissions: {
                            only: [AUTORIZAR_PEDIDOS_PERMISSION],
                        }
                    },
                    {
                        title: 'Avance Cobranzas',
                    },
                    {
                        title: 'Estimado de Cierre',
                        routing: {
                            routerLink: '/estadisticas/estimaciones',
                        }
                    },
                    {
                        title: 'Acciones Secundarias',
                        routing: {
                            routerLink: '/consultas/acciones-secundarias'
                        }
                    }
                ]
            },
            {
                id: 'pedidosMenu',
                title: 'Consulta de Pedidos',
                items: vendedores.map(vendedor => ({
                    title: `${vendedor.firstname} ${vendedor.lastname}`,
                    routing: {
                        routerLink: [ '/pedidos', vendedor.PERNR ],
                    }
                }))
            },
            {
                id: 'consultas',
                title: 'Otras Consultas',
                items: [
                    {
                        title: 'Listas de Precios',
                        routing: {
                            routerLink: '/consultas/precios',
                            routerLinkActiveOptions: { exact: true },
                        }
                    },
                    {
                        title: 'Acciones Comerciales',
                        routing: {
                            routerLink: '/consultas/acciones-comerciales',
                        }
                    },
                    {
                        title: 'Catálogos Mkt',
                        routing: {
                            routerLink: '/consultas/catalogo-mkt',
                        }
                    },
                    {
                        title: 'Productos Etiquetados',
                        routing: {
                            routerLink: '/consultas/productos-etiquetados',
                        }
                    }
                ]
            },
            {
                id: 'estadisticas',
                title: 'Estadísticas',
                items: [
                    {
                        title: 'Bonif. y Descuentos',
                        routing: {
                            routerLink: '/estadisticas/aprobaciones',
                        },
                        permissions: {
                            only: ['GCIA_GRAL', 'GCIA_COM', 'DIRECTOR', 'ANALISTA_ADM_VTAS'],
                        }
                    },
                    {
                        title: 'Cumplimiento de Combos',
                        routing: {
                            routerLink: '/estadisticas/cumplimiento-combos',
                        },
                    },
                    {
                        title: 'Informe Avance',
                        routing: {
                            routerLink: '/estadisticas/avance'
                        }
                    },
                    {
                        title: 'Disponible Prod. Terminado',
                        routing: {
                            routerLink: '/estadisticas/disponibilidad',
                        },
                        permissions: {
                            only: ['GCIA_GRAL', 'GCIA_COM', 'DIRECTOR', 'Resp_Trade_Mkt', 'ANALISTA_ADM_VTAS'],
                        }
                    },
                    {
                        title: 'Historial',
                        routing: {
                            routerLink: '/estadisticas/historial',
                        }
                    },
                    {
                        title: 'Ventas por Canal/Cliente',
                        routing: {
                            routerLink: '/estadisticas/canales',
                        },
                        permissions: {
                            only: ['DIRECTOR', 'GCIA_GRAL', 'GCIA_COM', 'ANALISTA_ADM_VTAS'],
                        }
                    }
                ]
            },
            {
                id: 'tablero',
                title: 'Tablero de Gestión',
                items: [
                    { 
                        title: 'Historial de Ventas',
                        routing: {
                            routerLink: [ '/tablero', 'historial-de-ventas' ],
                        },
                        permissions: {
                            only: ['DIRECTOR', 'GCIA_GRAL', 'GCIA_COM', 'Resp_Trade_Mkt', 'ANALISTA_ADM_VTAS', 'JDV', 'SDV', 'VENDEDOR']
                        }
                    },
                    {  
                        title: 'Ventas - Mercado Local',
                        routing: {
                            routerLink: [ '/tablero', 'ventas-mercado-local' ],
                        },
                        permissions: {
                            only: ['DIRECTOR', 'GCIA_GRAL', 'GCIA_COM', 'Resp_Trade_Mkt', 'ANALISTA_ADM_VTAS', 'JDV', 'SDV', 'VENDEDOR']
                        }
                    },
                    {  
                        title: 'Ventas - Mercado Externo',
                        routing: {
                            routerLink: [ '/tablero', 'ventas-mercado-externo' ],
                        },
                        permissions: {
                            only: ['DIRECTOR', 'GCIA_GRAL', 'EQ_VTA_7', 'EQ_VTA_8']
                        }
                    },
                    { 
                        title: 'Comp. Interanual de Ventas',
                        routing: {
                            routerLink: [ '/tablero', 'comparativo-interanual-de-ventas' ],
                        },
                        permissions: {
                            only: ['DIRECTOR', 'GCIA_GRAL', 'GCIA_COM', 'Resp_Trade_Mkt', 'ANALISTA_ADM_VTAS', 'JDV', 'SDV', 'VENDEDOR']
                        }
                    },
                ],
                permissions: {
                    only: ['DIRECTOR', 'GCIA_GRAL', 'GCIA_COM', 'Resp_Trade_Mkt', 'ANALISTA_ADM_VTAS', 'JDV', 'SDV', 'VENDEDOR'],
                },
            }
        ]));
    }
}
