import { ChangeDetectionStrategy, Component, forwardRef, Input } from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";


export type ButtonGroupType = 'default'|'primary'|'secondary'|'success'|'warning'|'danger'|'info'|'dark'|'light';

export type ButtonGroupOption = {
    label: string;
    value: any;
    type?: ButtonGroupType;
}

export type ButtonGroupOptions = ButtonGroupOption[];


@Component({
    selector: 'app-button-group-selector',
    providers: [
        { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => ButtonGroupSelectorComponent), multi: true }
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    template: `
<div class="btn-group">
    <button *ngFor="let option of options" 
            type="button"
            class="btn btn-{{ option.value !== value ? 'outline-' : '' }}{{ option.type || 'default' }}"
            [ngClass]="{ 'active': option.value === value, 'btn-lg': beLarge }"
            [disabled]="isDisabled"
            (click)="onOptionClick(option.value)">
        {{ option.label }}
    </button>
</div>
`
})
export class ButtonGroupSelectorComponent implements ControlValueAccessor {

    isDisabled: boolean = false;

    onChange: Function = () => {};
    onTouched: Function = () => {};

    private _value: any;
    set value (value: any) {
        this._value = value;
        this.onChange(value);
    }

    get value () { return this._value; }

    @Input() options: ButtonGroupOptions = [];
    @Input() beLarge: boolean = false;

    onOptionClick(value: any) {
        if (this.isDisabled) {
            return;
        }
        this.value = value;
        this.onTouched();
    }

    writeValue(obj: any): void {
        this._value = obj;
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    setDisabledState?(isDisabled: boolean): void {
        this.isDisabled = isDisabled;
    }
}