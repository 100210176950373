import { Component, Input, OnInit } from '@angular/core';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { OrderHeaderObservacion } from 'src/app/pedidos/models/Order.model';
import { InformacionService } from './informacion.service';

@Component({
    selector: 'app-info-detail',
    templateUrl: './informacion.component.html',
    providers: [InformacionService],
})

export class InformacionComponent implements OnInit{
    heading = 'WYSIWYG Editors';
    subheading = 'Need to add formatting to your forms? Use these Vue2 widgets.';
    icon = 'pe-7s-like icon-gradient bg-love-kiss';
    @Input() editInfo: boolean;
    @Input() ultimaObservacion: OrderHeaderObservacion;
    @Input() htmlContent = '';


    config: AngularEditorConfig = {
        editable: true,
        spellcheck: true,
        height: '15rem',
        minHeight: '5rem',
        placeholder: 'Enter text here...',
        translate: 'no',
        customClasses: [
        {
            name: 'quote',
            class: 'quote',
        },
        {
            name: 'redText',
            class: 'redText'
        },
        {
            name: 'titleText',
            class: 'titleText',
            tag: 'h1',
        },
        ]
    };
    constructor(
    ){
        
    }

    ngOnInit(){
    }
    
    

}