<div *ngIf="solicitudesDatasource" class="main-card mb-3 card">
  <div class="card-header">
    <div class="card-header-title font-size-lg text-capitalize font-weight-normal">
      Acciones Secundarias
    </div>
  </div>
  <div class="card-body">
    <form [formGroup]="filters" (ngSubmit)="onSubmit()">
      <div class="form-row align-items-end">
        <div class="col-lg-2 col-md-6">
          <div class="form-group">
            <label>Desde</label>
            <app-aniomes-control [anioMin]="anioMin$|async" [showLabels]="false" formControlName="desde"></app-aniomes-control>
          </div>
        </div>
        <div class="col-lg-2 col-md-6">
          <div class="form-group">
            <label>Hasta</label>
            <app-aniomes-control [anioMin]="anioMin$|async" [showLabels]="false" formControlName="hasta"></app-aniomes-control>
          </div>
        </div>
        <div class="col-lg-2 col-md-10 col-sm-12">
          <div class="form-group">
            <label>Canal</label>
            <ng-select [items]="canales$|async" 
              bindValue="VTWEG" 
              bindLabel="VTEXT" 
              formControlName="parent_DISTR_CHAN"
              [clearable]="false">
            </ng-select>
          </div>
        </div>
        <div class="col-lg-2 col-md-2 col-sm-12">
          <div class="form-group">
            <button type="submit" class="btn btn-lg btn-primary mx-1 px-3" [disabled]="filters.invalid">
                Ir
            </button>
            <button type="button" class="btn btn-lg btn-success btn-icon mx-1 py-1 px-3"
                [disabled]="filters.invalid"
                (click)="onDownload()"
                title="Descarga a Excel">
                <img src="assets/images/excel.svg" style="height: 2.1em; width: 100%">
            </button>
          </div>
        </div>
        <div class="col-lg-4 col-md-12 text-center">
          <div class="form-group">
            <app-button-group-selector [options]="opcionesEstado$|async" [beLarge]="true" [formControl]="statusFilter"></app-button-group-selector>
          </div>
        </div>
      </div>
      <p class="text-danger" *ngIf="filters.errors?.desdeHasta">{{ filters.errors.desdeHasta }}</p>
    </form>
    <ng-container *ngIf="solicitudesDatasource.page$ | async as page; else noData">
      <ng-container *ngIf="page.content as solicitudes">
        <ng-container *ngIf="solicitudes.length > 0; else noResults">
            <div class="table-responsive">
            <table class="align-middle text-truncate table table-hover" style="max-width: 100%">
              <thead>
                <tr>
                  <th sortable class="text-left" property="DOC_DATE" [state]="sort|async" (sort)="setSort($event)">
                    Fecha Solic.
                  </th>
                  <th sortable class="text-center" property="nombreEstado" [state]="sort|async" (sort)="setSort($event)">
                    Estado
                  </th>
                  <th sortable class="text-left" property="canal" [state]="sort|async" (sort)="setSort($event)">
                    Canal
                  </th>
                  <th sortable class="text-left" property="kUNNR" [state]="sort|async" (sort)="setSort($event)">
                    Cliente
                  </th>
                  <!-- <th sortable class="text-left" property="pernrAndNombreVendedor" [state]="sort|async" (sort)="setSort($event)">
                    Vendedor
                  </th> -->
                  <th sortable class="text-left" property="accion" [state]="sort|async" (sort)="setSort($event)">
                    Tipo de Acción
                  </th>
                  <th class="text-right"><span class="text-primary">Valor Solicitado</span></th>
                  <!-- <th class="text-right">Pendiente</th> -->
                  <th class="text-right"><span class="text-primary">Valor Venta</span></th>
                  <th class="text-center"><span class="text-primary">Contenido</span></th>
                </tr>
              </thead>
              <tbody>
                <tr routerLink="./{{ item.id }}" *ngFor="let item of solicitudes">
                  <td class="text-left">
                    {{ item.DOC_DATE | date: "dd/MM/yyyy" }}
                  </td>
                  <td class="text-center">
                    <app-badge-estado [orderHeader]="item"></app-badge-estado>
                  </td>
                  <td class="text-left">
                    {{ item.dISTRCHAN?.VTEXT }}
                  </td>
                  <td class="text-left">
                    {{ item.kUNNR.KUNNR }} - {{ item.kUNNR.NAME }}
                    {{ item.kUNNR.NAME2 }}
                  </td>
                  <!-- <td class="text-left">
                    {{ item.vendedor.PERNR }} - {{ item.vendedor.lastname }},
                    {{ item.vendedor.firstname }}
                  </td> -->
                  
                  <td class="text-left">
                    {{ item.accionSecundariaAplicada?.accion?.descripcion }}
                  </td>
                  <td class="text-right">
                    {{ item.importe_total | currency: "ARS" }}
                  </td>
                  <!-- <td class="text-right">
                    {{ item.valorAcordado - item.valorFacturado * 1.21 | currency: "ARS" }}
                  </td> -->
                  <td class="text-right">
                    {{ item.parent.importe_total | currency: "ARS" }}
                  </td>
                  <td class="text-center">
                    <button
                      app-cc-as-modal-contenido
                      class="btn btn-secondary btn-sm"
                      [edit]="false"
                      [value]="item.observaciones"
                      [disabled]="!item.observaciones || item.observaciones.length == 0"
                      *ngIf="item.observaciones">
                      Contenido
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          </ng-container>
          <ng-template #noResults>
            <!-- <div class="card-body"> -->
              <p>No se encontraron resultados</p>
            <!-- </div> -->
          </ng-template>
          <div *ngIf="page" class="p-2">
            <ng-container *ngIf="page.content as solicitudes">
              <div *ngIf="solicitudes.length > 0" class="float-right">
                <ngb-pagination
                  [collectionSize]="page.totalElements"
                  [page]="page.number"
                  (pageChange)="solicitudesDatasource.fetch($event)"
                  [maxSize]="10"
                  [pageSize]="page.size"
                  [rotate]="true"
                  [ellipses]="false"
                  [boundaryLinks]="true"
                >
                </ngb-pagination>
              </div>
            </ng-container>
          </div>
        </ng-container>
    </ng-container>
    <ng-template #noData>
      <div class="card-body">
        <p>No hay datos para mostrar</p>
      </div>
    </ng-template>
  </div>
</div>
