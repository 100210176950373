import { Component, OnInit, OnDestroy } from "@angular/core";
import { OrderHeaderIn } from '../models/Order.model';
import { Router, ActivatedRoute } from '@angular/router';
import { PedidosService } from '../pedidos.service';
import { PedidosListService } from './pedidos-list.service';
import { pluck, map } from 'rxjs/operators';
import { Subscription, Observable } from 'rxjs';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { StringHelper } from 'src/app/utils/string.helper';
import { FormBuilder } from '@angular/forms';

@Component({
    selector: 'app-pedidos-aprobacion',
    templateUrl: './pedidos-aprobacion.component.html'
})
export class PedidosAprobacionComponent implements OnInit, OnDestroy {
    private subscriptions: Subscription[] = [];

    filtroEstado = this.fb.control(false);

    data$: Observable<OrderHeaderIn[]> = this.route.data
        .pipe(
            pluck('list'),
            map((list) => list.sort((a, b) => a.FECHA_MODIFICACION < b.FECHA_MODIFICACION ? -1 : 1))
        );

    constructor(
        private route: ActivatedRoute,
        public service: PedidosListService,
        private fb: FormBuilder,
    ) {}

    ngOnInit() {
        let fnPendientes = (i: OrderHeaderIn) => i.estado == 1;
        let fnTodos = () => true;

        this.subscriptions.push(
            this.filtroEstado.valueChanges.subscribe(pendientes => {
                this.service.setFilter(pendientes ? fnPendientes : fnTodos)
            })
        );

        this.subscriptions.push(
            this.data$.subscribe(data => this.service.pushData(data))
        );

        this.service.setSearchFunction((i, term) => {
            term = term.toUpperCase();
            return StringHelper.removeTildes(i.KUNNR.toUpperCase()).includes(term) ||
                StringHelper.removeTildes(i.kUNNR.NAME.toUpperCase()).includes(term) ||
                StringHelper.removeTildes(i.vendedor.firstname.toUpperCase()).includes(term) ||
                StringHelper.removeTildes(i.vendedor.lastname.toUpperCase()).includes(term) ||
                StringHelper.removeTildes(i.vendedor.PERNR.toUpperCase()).includes(term);
        });
    }

    ngOnDestroy() {
        this.subscriptions.forEach(s => s.unsubscribe());
        this.service.resetFilter();
    }
}