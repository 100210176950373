import { formatNumber } from "@angular/common";
import { Injector, LOCALE_ID, Pipe, PipeTransform } from "@angular/core";

@Pipe({
    name: 'kilos'
})
export class KilosPipe implements PipeTransform {
    constructor(private injector: Injector) {}

    transform(value: number, digitsInfo?: string) {
        if ([null, undefined].includes(value)) {
            return '-';
        }
        const locale = this.injector.get(LOCALE_ID);
        const formatted = formatNumber(value, locale, digitsInfo);
        return `${formatted} kg`;
    }
}