import { Component, Input, OnDestroy } from "@angular/core";
import { FormControl } from '@angular/forms';
import { Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { CatalogoFactoryService } from 'src/app/catalogos/factory.service';
import { ClienteDatoComercial } from 'src/app/clientes/models/Cliente.model';

@Component({
    selector: 'app-condiciones-pago',
    template: `
    <ng-select [id]="id" 
        name="formapago" 
        [items]="condicionesPago" 
        bindValue="VALOR" bindLabel="label" 
        [formControl]="control"
        [readonly]="!editable"
        [loading]="loading"
        loadingText="Cargando...">
    </ng-select>
    `
})
export class CondicionesPagoComponent implements OnDestroy
{
    private subcriptions: Subscription[] = [];

    @Input() id: string;
    @Input() editable: boolean;

    @Input() set datoComercial (value: ClienteDatoComercial) {
        if (value) {
            this.loadCondiciones(value).then(() => {
                if (!this.control.value && value.cond_pago) {
                    this.control.setValue(value.cond_pago);
                }
            });
        } else {
            this.condicionesPago = [];
        }
        this._datoComercial = value;
    }

    loading: boolean = false;

    condicionesPago = [];

    @Input() control: FormControl;

    private _datoComercial: ClienteDatoComercial;

    constructor(private catalogos: CatalogoFactoryService){}

    ngOnDestroy() {
        this.subcriptions.forEach(s => s.unsubscribe());
    }

    loadCondiciones(clteDatoCom: ClienteDatoComercial): Promise<any[]> {
        this.loading = true;
        return this.catalogos.getCondicionPago()
            .list({
                "CondPagoSearch[habilitado]": 1,
                "CondPagoSearch[KUNNR]": clteDatoCom.KUNNR,
                "CondPagoSearch[VTWEG]": clteDatoCom.VTWEG,
                "CondPagoSearch[VKORG]": clteDatoCom.VKORG,
                "CondPagoSearch[SPART]": clteDatoCom.SPART
            })
            .pipe(
                map(x => x.map(y => Object.assign(y, {
                    label: `${y.VALOR} - ${y.VTEXT} ${y.VALOR == clteDatoCom.cond_pago ? ' (por defecto)' : ''}`
                })))
            )
            .toPromise()
            .then(condicionesPago => this.condicionesPago = condicionesPago)
            .finally(() => { this.loading = false; });
    }
}