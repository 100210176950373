<div class="form-row justify-content-between p-2">
    <div class="form-group form-inline mb-2 justify-content-start">
        <button class="my-2 btn btn-default btn-hover"
            (click)="collapse = !collapse">{{ collapse ? '+ Expandir Todo' : '- Colapsar Todo' }}</button>
        <div class="ml-2">
            <input class="form-control ml-2" type="text" id="searchBox" name="searchBox" placeholder="Buscar..."
                [formControl]="searchBox" />
        </div>
    </div>
    <div *ngIf="paginate && (collectionSize$|async)">
        <page-selector [collectionSize]="collectionSize$|async"></page-selector>
    </div>
</div>
<ng-container *ngIf="groups$ | async as groups; else noData">
    <ng-container *ngIf="groups.length > 0; else noData">
        <table class="align-middle text-truncate mb-0 table table-hover">

            <ng-container *ngFor="let def of groupsDefs">
                <colgroup [attr.colspan]="def.colspan || '2'"></colgroup>
            </ng-container>
            <tr>
                <ng-container *ngFor="let def of groupsDefs">
                    <th [attr.colspan]="def.colspan || '2'" scope="colgroup" class="border text-center">{{ def.label }}
                    </th>
                </ng-container>
            </tr>
            <tr>
                <ng-container *ngFor="let def of columnDefs">
                    <th *ngIf="!(hiddenColumns$|async).includes(def.bindProperty)" class="border text-center">
                        {{ def.label || def.bindProperty }}</th>
                </ng-container>
            </tr>

            <ng-container *ngFor="let group of (paginate ? (groups | pagination): groups)">
                <tr class="table-sm st-group-header" *ngIf="group.field !== '*'" (click)="group.toggleCollapse()">
                    <th colspan="2" class="border">
                        <button type="button" class="btn btn-default">
                            <span class="vsm-title"><i
                                    [className]="group.collapsed ? 'vsm-arrow pe-7s-angle-down' : 'vsm-arrow pe-7s-angle-right'"
                                    style="font-weight: bold; font-size: large;"></i></span>
                        </button>
                        {{ group.label | titlecase }} ({{ group.length }})
                    </th>
                    <ng-container *ngFor="let def of columnDefs">
                        <ng-container *ngIf="def.groupdata === true">
                            <th class="border text-{{ def.textAlign || 'left' }}">{{ 
                            renderGroup(group, def)
                        }}</th>
                        </ng-container>
                    </ng-container>
                </tr>
                <ng-container *ngIf="group.collapsed">
                    <tr *ngFor="let item of group.data">
                        <ng-container *ngFor="let def of columnDefs">
                            <ng-container *ngIf="!(hiddenColumns$|async).includes(def.bindProperty)">
                                <th *ngIf="def.bold" class="border text-{{ def.textAlign || 'left' }}"
                                    [innerHTML]="renderCell(def, item)"></th>
                                <td *ngIf="!def.bold" class="border text-{{ def.textAlign || 'left' }}"
                                    [innerHTML]="renderCell(def, item)"></td>
                            </ng-container>
                        </ng-container>
                    </tr>
                </ng-container>
            </ng-container>
        </table>
    </ng-container>
</ng-container>
<ng-template #noData>
    <div class="card-body">
        <p class="card-text">No se encontraron resultados</p>
    </div>
</ng-template>