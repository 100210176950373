import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { BehaviorSubject, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, tap } from 'rxjs/operators';
import { ClientesService } from 'src/app/clientes/clientes.service';
import { Cliente, ClienteQuery } from 'src/app/clientes/models/Cliente.model';
import { PaginationDataSource } from 'src/app/utils/datasource/pagination-datasource.service';
import { Group } from 'src/app/utils/groups/groups.model';
import { OrderHeaderIn } from '../models/Order.model';
import { PedidosService } from '../pedidos.service';
import { PedidosListService } from './pedidos-list.service';


@Component({
    selector: 'app-pedidos-vendedor',
    templateUrl: './pedidos-vendedor.component.html',
    styleUrls: [ "../../utils/groups/groups-table.component.scss" ]
})
export class PedidosVendedorComponent implements OnInit, OnDestroy {
    private subscriptions: Subscription[] = [];

    collapsed: boolean = true;

    clientesDatasource: PaginationDataSource<Cliente, ClienteQuery>;

    groups$: BehaviorSubject<Group<OrderHeaderIn>[]> = new BehaviorSubject([]);

    filters = this.fb.group({
        searchTerm: [''],
        con_pedidos_activos: [false]
    })

    readonly LIMIT = 10;

    constructor(
        private route: ActivatedRoute,
        public service: PedidosListService,
        private clientesService: ClientesService,
        private pedidosService: PedidosService,
        private fb: FormBuilder,
    ) {}

    ngOnInit() {
        this.subscriptions.push(this.route.paramMap.pipe(map(x => x.get('pernr'))).subscribe(pernr => {
            this.clientesDatasource = new PaginationDataSource<Cliente, ClienteQuery>(
                (request, q) => this.clientesService.page(request, q, {fields: 'id,KUNNR,NAME,orderHeaderInsCount,activeOrderHeaderInsCount'}), 
                {property: 'NAME', order: 'asc'},
                {searchTerm: '', vendedor: pernr, con_pedidos: true, con_pedidos_activos: this.filters.value.con_pedidos_activos}
            );

            this.subscriptions.push(this.filters.valueChanges.pipe(
                    debounceTime(500), 
                    distinctUntilChanged((x, y) => x.searchTerm == y.searchTerm && x.con_pedidos_activos == y.con_pedidos_activos)
            ).subscribe(filters => {
                this.clientesDatasource.queryBy(filters);
            }));

            this.subscriptions.push(this.clientesDatasource.connect().pipe(
                map(clientes => clientes.map(x => {
                    let count = this.filters.value.con_pedidos_activos ? x.activeOrderHeaderInsCount : x.orderHeaderInsCount;
                    let group = new Group<OrderHeaderIn>('KUNNR', x.KUNNR, `${x.NAME}  (${ count })`, null);
                    group.userdata = {cliente_id: x.id};
                    return group;
                })),
                tap(() => this.collapsed = true),
                tap(grupos => grupos.forEach(grupo => {
                    // Configuro un callback para llenar el grupo la primera vez que se expande
                    let subscription = grupo.collapsedChange.subscribe(() => {
                        let activo = this.filters.value.con_pedidos_activos;
                        let params = (activo ? {'OrderHeaderInSearch[activo]': 1} : {});

                        params['OrderHeaderInSearch[ultimos]'] = this.LIMIT;

                        if (!grupo.collapsed && (!grupo.data || grupo.data.length == 0)) {
                            grupo.loading = true;
                            this.pedidosService.getPedidosByCliente(grupo.value as string, params)
                                .toPromise().then(pedidos => {
                                    grupo.data = pedidos;
                                    grupo.loading = false;
                                    subscription.unsubscribe();
                                })
                        }
                    })
                })),
            ).subscribe(this.groups$))
        }))
    }

    ngOnDestroy() {
        this.subscriptions.forEach(s => s.unsubscribe());
    }

    toggleCollapse() {
        this.collapsed = !this.collapsed;
        this.groups$.getValue().forEach(x => x.setCollapse(this.collapsed));
    }
}
