<div class="p-2">
  <div *ngIf="(loading$ | async)" class="p-3">
    <p>Cargando...</p>
  </div>
  <ng-container *ngIf="(historia$ | async) as data; else noData">
    <div class="d-flex justify-content-between align-items-center">
      <div class="form-group form-inline mb-2">
        <button class="my-2 btn btn-default btn-hover"
          (click)="toggleCollapse()">{{ !getCollapse() ? '- Colapsar Todo' : '+ Expandir Todo' }}</button>
        <div class="ml-2">
          <input class="form-control ml-2" type="text" id="searchBox" name="searchBox" placeholder="Buscar..."
            [formControl]="searchBox" />
        </div>
        <div class="ml-2 form-group form-inline" [formGroup]="filterForm">
          <label class="mx-2">Categoria</label>
          <div class="form-inline">
            <select id="ctlCategoria" class="custom-select custom-select-sm" formControlName="categoria">
              <option *ngFor="let categoria of (categorias$|async)" [value]="categoria.value">
                {{ categoria.label }}
              </option>
            </select>
          </div>
          <label class="mx-2">Desde</label>
          <div class="form-inline">
            <!-- <label for="ctlMesDesde">Mes</label> -->
            <select id="ctlMesDesde" class="custom-select custom-select-sm" formControlName="mesDesde">
              <option *ngFor="let i of (mesesDesde$|async)" [value]="i">
                {{ (i + 1) | number: '2.0-0'}}
              </option>
            </select>
            <!-- <label for="ctlAnioDesde">Año</label> -->
            <select id="ctlAnioDesde" class="custom-select custom-select-sm" formControlName="anioDesde">
              <option *ngFor="let anio of (anios$|async)" [value]="anio">{{ anio }}</option>
            </select>
          </div>
          <label class="mx-2">Hasta</label>
          <div class="form-inline">
            <!-- <label for="ctlMesHasta">Mes</label> -->
            <select id="ctlMesHasta" class="custom-select custom-select-sm" formControlName="mesHasta">
              <option *ngFor="let i of (mesesHasta$|async)" [value]="i">
                {{ (i + 1) | number: '2.0-0'}}
              </option>
            </select>
            <!-- <label for="ctlAnioHasta">Año</label> -->
            <select id="ctlAnioHasta" class="custom-select custom-select-sm" formControlName="anioHasta">
              <option *ngFor="let anio of (anios$|async)" [value]="anio">{{ anio }}</option>
            </select>
          </div>
        </div>
      </div>
      <ng-container *ngIf="groups$|async as groups">
        <div *ngIf="groups.length > 0">
          <page-selector [collectionSize]="groups.length"></page-selector>
        </div>
      </ng-container>
    </div>
    <div class="table-responsive p-2">
      <ng-container *ngIf="(groups$|async) as groups">
        <ng-container *ngIf="groups.length > 0; else noResults">
          <table class="align-middle text-truncate table table-hover">
            <thead>
              <tr>
                <th class="text-left" scope="col">Producto</th>
                <th class="text-right" scope="col">Mes/Año</th>
                <th class="text-right" scope="col">V. Pend Fact</th>
                <th class="text-right" scope="col">Cant Pend Fact</th>
                <th class="text-right" scope="col">V. Fact</th>
                <th class="text-right" scope="col">Cant Fact</th>
                <th class="text-right" scope="col">V. Devolución</th>
                <th class="text-right" scope="col">Cant. devolución</th>
                <th class="text-right" scope="col">V. N. Créd</th>
              </tr>
            </thead>
            <tbody *ngIf="groupSummarizer(groups) as item;">
              <tr class="st-group-header">
                <th colspan="2">
                  <button type="button" class="btn btn-default">
                    <span class="vsm-title"><i class="pe-7s-info" style="font-weight: bold; font-size: large;"></i></span>
                  </button>
                  TOTAL
                </th>
                <th class="text-right">{{ (item.valorPendFact || 0) | currency: 'ARS' }}</th>
                <th class="text-right">{{ (item.cantPendFact || 0) | number:'1.0-2' }}</th>
                <th class="text-right">{{ item.UMNETWR | currency: 'ARS' }}</th>
                <th class="text-right">{{ (item.FKIMG || 0) | number:'1.0-2' }}</th>
                <th class="text-right">{{ (item.REMENGE > 0 ? item.REKZWI2 : 0) | currency: 'ARS' }}</th>
                <th class="text-right">{{ (item.REMENGE || 0) | number:'1.0-2' }}</th>
                <th class="text-right">{{ (item.REMENGE > 0 ? item.GUNETWR : 0) | currency: 'ARS' }}</th>
              </tr>
          </tbody>
            <tbody *ngFor="let group of (groups | pagination)">
              <tr class="st-subgroup-header" *ngIf="group.field !== '*'" (click)="group.toggleCollapse()">
                <th colspan="2">
                  <button type="button" class="btn btn-default">
                    <span class="vsm-title"><i
                        [className]="group.collapsed ? 'vsm-arrow pe-7s-angle-right' : 'vsm-arrow pe-7s-angle-down'"
                        style="font-weight: bold; font-size: large;"></i></span>
                  </button>
                  {{ group.label | uppercase }} ({{ group.length }})
                </th>
                <ng-container *ngIf="summarizer(group.data) as item;">
                  <th class="text-right">{{ (item.valorPendFact || 0) | currency: 'ARS' }}</th>
                  <th class="text-right">{{ (item.cantPendFact || 0) | number:'1.0-2' }}</th>
                  <th class="text-right">{{ item.UMNETWR | currency: 'ARS' }}</th>
                  <th class="text-right">{{ (item.FKIMG || 0) | number:'1.0-2' }}</th>
                  <th class="text-right">{{ (item.REMENGE > 0 ? item.REKZWI2 : 0) | currency: 'ARS' }}</th>
                  <th class="text-right">{{ (item.REMENGE || 0) | number:'1.0-2' }}</th>
                  <th class="text-right">{{ (item.REMENGE > 0 ? item.GUNETWR : 0) | currency: 'ARS' }}</th>
                </ng-container>
              </tr>
              <ng-container *ngIf="!group.collapsed">
                <ng-container *ngIf="group as subgroup;">
                  <!-- <tr class="st-subgroup-header" *ngIf="subgroup.field !== '*'" (click)="subgroup.toggleCollapse()">
                    <th colspan="2">
                      <button type="button" class="btn btn-default">
                        <span class="vsm-title"><i
                            [className]="subgroup.collapsed ? 'vsm-arrow pe-7s-angle-right' : 'vsm-arrow pe-7s-angle-down'"
                            style="font-weight: bold; font-size: large;"></i></span>
                      </button>
                      {{ subgroup.label | uppercase }} ({{ subgroup.length }})
                    </th>
                    <ng-container *ngIf="summarizer(subgroup.data) as item;">
                      <th class="text-right">{{ (item.valorPendFact || 0) | currency: 'ARS' }}</th>
                      <th class="text-right">{{ (item.cantPendFact || 0) | number:'1.0-2' }}</th>
                      <th class="text-right">{{ item.UMNETWR | currency: 'ARS' }}</th>
                      <th class="text-right">{{ (item.FKIMG || 0) | number:'1.0-2' }}</th>
                      <th class="text-right">{{ (item.REMENGE > 0 ? item.REKZWI2 : 0) | currency: 'ARS' }}</th>
                      <th class="text-right">{{ (item.REMENGE || 0) | number:'1.0-2' }}</th>
                      <th class="text-right">{{ (item.REMENGE > 0 ? item.GUNETWR : 0) | currency: 'ARS' }}</th>
                    </ng-container>
                  </tr> -->
                  <ng-container *ngIf="!subgroup.collapsed">
                    <tr *ngFor="let item of subgroup.data">
                      <td *ngIf="item.MATNR" class="text-left">
                        {{ item.MATNR }}{{ item.nombreProducto ? " - " + (item.nombreProducto) : "" }}
                      </td>
                      <td *ngIf="!item.MATNR" class="text-left">(Producto Desconocido)</td>
                      <td class="text-right">{{ item.SPMON | date:'MM/yyyy' }}</td>
                      <td class="text-right">{{ (item.valorPendFact || 0) | currency: 'ARS' }}</td>
                      <td class="text-right">{{ (item.cantPendFact || 0) | number:'1.0-2' }}</td>
                      <td class="text-right">{{ item.UMNETWR | currency: 'ARS' }}</td>
                      <td class="text-right">{{ (item.FKIMG || 0) | number:'1.0-2' }}</td>
                      <td class="text-right">{{ (item.REMENGE > 0 ? item.REKZWI2 : 0) | currency: 'ARS' }}</td>
                      <td class="text-right">{{ (item.REMENGE || 0) | number:'1.0-2' }}</td>
                      <td class="text-right">{{ (item.REMENGE > 0 ? item.GUNETWR : 0) | currency: 'ARS' }}</td>
                    </tr>
                  </ng-container>
                </ng-container>
              </ng-container>
            </tbody>
          </table>
        </ng-container>
        <ng-template #noResults>
          <div class="p-3">
            <p>No se encontraron resultados</p>
          </div>
        </ng-template>
      </ng-container>
    </div>
  </ng-container>
  <ng-template #noData>
    <div class="p-3">
      <p>No hay datos para mostrar</p>
    </div>
  </ng-template>
</div>