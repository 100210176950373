import { NgModule } from "@angular/core";
import { AcuerdosModule } from '../acuerdos/acuerdos.module';
import { SharedModule } from '../shared.module';
import { ClienteCreditoComponent } from './credito/cliente-credito.component';
import { ClienteEmailsModalComponent } from './emails/cliente-emails-modal.component';
import { ClientesDetailComponent } from './clientes-detail.component';
import { ClientesListComponent } from './clientes-list.component';
import { EmailListElementComponent } from './emails/email-list-element.component';
import { ClientesHistoriaVentasComponent } from './historia-ventas/clientes-historia-ventas.component';
import { PedidosModule } from '../pedidos/pedidos.module';
import { ClientesDatosComponent } from './datos/clientes-datos.component';
import { ClientesVencimientosComponent } from './vencimientos/clientes-vencimientos.component';
import { ClientesDireccionesComponent } from './direcciones/clientes-direcciones.component';
import { ClientesVentasComponent } from './ventas/clientes-ventas.component';

@NgModule({
    declarations: [
        ClienteCreditoComponent, 
        ClienteEmailsModalComponent,
        ClientesDatosComponent,
        ClientesDetailComponent,
        ClientesDireccionesComponent,
        ClientesListComponent,
        ClientesVencimientosComponent,
        ClientesVentasComponent,
        EmailListElementComponent,
        ClientesHistoriaVentasComponent
    ],
    imports: [
        AcuerdosModule,
        SharedModule,
        PedidosModule,
    ],
    exports: [
        ClientesDetailComponent,
        ClientesListComponent
    ]
})
export class ClientesModule {}