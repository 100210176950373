<table class="table">
    <thead>
        <tr>
            <th scope="colgroup" colspan="6" class="text-center">
                <span class="card-title">Reglas de la Condición</span>
            </th>
        </tr>
        <tr>
            <th scope="col">Categoría</th>
            <th scope="col">Subcategoría</th>
            <th scope="col">Producto</th>
            <th scope="col" class="text-center">Monto</th>
            <th scope="col" class="text-center">Cant.</th>
            <th scope="col" class="text-center">Bulto</th>
        </tr>
    </thead>
    <tbody *ngFor="let g of grupos">
        <tr *ngIf="g.condicionVarianteComboGrupoReglas[0] as regla">
            <td [ngClass]="{'text-muted': !regla.SPART}">{{ regla.sPART?.VTEXT || 'Indistinto' }}</td>
            <td [ngClass]="{'text-muted': !regla.KONDM}">{{ regla.kONDM?.VTEXT || 'Indistinto' }}</td>
            <td [ngClass]="{'text-muted': !regla.MATNR}">{{ regla.mATNR?.MAKTX || 'Indistinto' }}</td>
            <td [attr.rowspan]="g.condicionVarianteComboGrupoReglas.length" class="text-center align-middle">
                <app-cc-metrica-regla [metrica]="g.metricaMonto"></app-cc-metrica-regla>
            </td>
            <td [attr.rowspan]="g.condicionVarianteComboGrupoReglas.length" class="text-center align-middle">
                <app-cc-metrica-regla [metrica]="g.metricaCantidad"></app-cc-metrica-regla>
            </td>
            <td [attr.rowspan]="g.condicionVarianteComboGrupoReglas.length" class="text-center align-middle">
                <app-cc-metrica-regla [metrica]="g.metricaBulto"></app-cc-metrica-regla>
            </td>
        </tr>
        <tr *ngFor="let regla of g.condicionVarianteComboGrupoReglas|slice:1">
            <td [ngClass]="{'text-muted': !regla.SPART}">{{ regla.sPART?.VTEXT || 'Indistinto' }}</td>
            <td [ngClass]="{'text-muted': !regla.KONDM}">{{ regla.kONDM?.VTEXT || 'Indistinto' }}</td>
            <td [ngClass]="{'text-muted': !regla.MATNR}">{{ regla.mATNR?.MAKTX || 'Indistinto' }}</td>
        </tr>
    </tbody>
</table>