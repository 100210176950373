import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "prop",
})
export class PropertyPipe implements PipeTransform {
  transform(obj: any, path: string, defaultValue: any = undefined) {
    if ([null, undefined].includes(obj)) {
      return obj;
    }

    let jsPath = path
      .split(".")
      .map((x) => `['${x}']`)
      .join("");

    try {
      return eval(`obj${jsPath}`);
    } catch (e) {
      if (e instanceof TypeError) {
        return defaultValue;
      }
      throw e;
    }
  }
}
