import { Pipe, PipeTransform, OnDestroy, OnInit } from '@angular/core';
import { Observable, BehaviorSubject, combineLatest, Subscription, of } from 'rxjs';
import { map, switchMap, distinctUntilChanged, startWith, filter } from 'rxjs/operators';
import { PaginationService } from './pagination.service';

@Pipe({
    name: 'pagination',
    pure: false
})
export class PaginationPipe implements PipeTransform, OnInit, OnDestroy {
    protected subscription: Subscription;
    protected page$: BehaviorSubject<any[]> = new BehaviorSubject([]);
    protected collection$: BehaviorSubject<any[] | Observable<any[]>> = new BehaviorSubject([]);
    
    protected transform$ = combineLatest([
        this.collection$.pipe(
            distinctUntilChanged(),
            filter((collection) => ![null, undefined].includes(collection)),
            switchMap((collection) => collection instanceof Observable ? collection : of(collection))
        ), 
        this.paginationService.page$.pipe(
            distinctUntilChanged()
        ), 
        this.paginationService.pageSize$.pipe(
            distinctUntilChanged()
        )
    ])
        .pipe(
            map(([collection, page, pageSize]) => this.paginate(collection, page, pageSize))
        );

    ngOnInit() {
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    transform (value: any[] | Observable<any[]>, page?: number, pageSize?: number) {
        this.collection$.next(value);

        if (page) {
            this.paginationService.page$.next(page);
        }
        if (pageSize) {
            this.paginationService.pageSize$.next(page);
        }

        return this.page$.getValue();
    }

    constructor(protected paginationService: PaginationService) {
        this.subscription = this.transform$.subscribe(this.page$);
    }

    protected paginate (value: any[], page: number, pageSize: number) {
        let currentPageFirstElement = (page - 1) * pageSize;
        let nextPageFirstElement = (page - 1) * pageSize + pageSize;

        return value.slice(currentPageFirstElement, nextPageFirstElement);
    }
}