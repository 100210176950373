import { Component, Input } from '@angular/core';
import { BehaviorSubject, combineLatest, ConnectableObservable, Observable, of, Subscription, zip } from 'rxjs';
import { catchError, distinctUntilChanged, map, publish, switchMap, tap } from 'rxjs/operators';
import { MaterialPrVtas } from '../models/ListaPrecios.model';
import { NotificationService } from 'src/app/utils/notifications/notification.service';
import { Centro } from './Stock.model';
import { StockService } from './stock.service';

@Component({
    selector: 'app-stock-total-centro',
    template: `
        <table class="table table-striped">
            <tbody>
                <tr>
                    <th scope="row">Existencias</th>
                    <td class="text-right">
                        <span [style.visibility]="(stockLoading$ | async)? 'hidden' : 'visible'">
                            {{ stock$ | async | number: '1.0-2' }}
                        </span>
                        <span *ngIf="(stockLoading$ | async)">
                            Cargando...
                        </span>
                    </td>
                </tr>
                <tr>
                    <th scope="row">Pedidos Pendientes</th>
                    <td class="text-right">
                        <span [style.visibility]="(pendientesLoading$ | async)? 'hidden' : 'visible'">
                            {{ pendientes$ | async | number: '1.0-2' }}
                        </span>
                        <span *ngIf="(pendientesLoading$ | async)">
                            Cargando...
                        </span>
                    </td>
                    
                </tr>
                <tr class="bg-warning">
                    <th scope="row">Ingresos Previstos</th>
                    <td class="text-right">
                        <span [style.visibility]="(previstosLoading$ | async)? 'hidden' : 'visible'">
                            {{ previstos$ | async | number: '1.0-2' }}
                        </span>
                        <span *ngIf="(previstosLoading$ | async)">
                            Cargando...
                        </span>
                    </td>
                </tr>
                <tr>
                    <th scope="row">SALDO</th>
                    <td class="text-right">
                        <span [style.visibility]="(saldoLoading$ | async)? 'hidden' : 'visible'">
                            {{ saldo$ | async | number: '1.0-2' }}
                        </span>
                        <span *ngIf="(saldoLoading$ | async)">
                            Cargando...
                        </span>
                    </td>
                </tr>
            </tbody>
        </table>
    `
})
export class StockTotalCentroComponent {
    private subscriptions: Subscription[] = [];

    private item$: BehaviorSubject<MaterialPrVtas> = new BehaviorSubject(null);
    @Input() set item(val: MaterialPrVtas) {
        this.item$.next(val);
    }
    get item(): MaterialPrVtas {
        return this.item$.getValue();
    }

    centro$: BehaviorSubject<Partial<Centro>> = new BehaviorSubject(null);
    @Input() set centro (val: Partial<Centro>) {
        this.centro$.next(val);
    }
    get centro(): Partial<Centro> {
        return this.centro$.getValue();
    }

    ajuste$: BehaviorSubject<number> = new BehaviorSubject(0);
    @Input() set ajuste(val: number) {
        this.ajuste$.next(val);
    }
    get ajuste() {
        return this.ajuste$.getValue();
    }

    pendientes$: BehaviorSubject<number> = new BehaviorSubject(0);
    pendientesLoading$: BehaviorSubject<boolean> = new BehaviorSubject(false);

    stock$: ConnectableObservable<number> = publish<number>()(
        this.item$
            .pipe(
                tap(() => this.stockLoading$.next(true)),
                switchMap((item) => {
                    let centro = this.centro && this.centro.WERKS;
                    return of(this.service.getStockByCentro(item, centro))
                        .pipe(
                            catchError(() => {this.notiService.error("Ha ocurrido un error al calcular stock.", "Error", true); return of(0)}),
                            tap(() => this.stockLoading$.next(false)),
                        )
                }),
            )
        );
    stockLoading$: BehaviorSubject<boolean> = new BehaviorSubject(false);


    previstos$: Observable<number> = this.stock$.pipe(
        tap(() => this.previstosLoading$.next(true)),
        switchMap(() => of(0)),
        catchError(() => {this.notiService.error("Ha ocurrido un error al calcular stock.", "Error", true); return of(0)}),
        tap(() => this.previstosLoading$.next(false)),
    );
    previstosLoading$: BehaviorSubject<boolean> = new BehaviorSubject(false);

    saldo$: Observable<number> = 
        combineLatest([this.stock$, this.pendientes$, this.previstos$])
            .pipe(
                map(([stock, pendientes, previstos]) => stock - pendientes + previstos),
                catchError(() => {this.notiService.error("Ha ocurrido un error al calcular stock.", "Error", true); return of(0)}),
            );

    saldoLoading$ = combineLatest([this.stockLoading$, this.pendientesLoading$, this.previstosLoading$])
        .pipe(
            map(([l1, l2, l3]) => l1 || l2 || l3)
        )

    constructor(private service: StockService, private notiService: NotificationService) { }

    ngOnInit() {
        this.subscriptions.push(
            combineLatest([this.ajuste$, this.item$])
                .pipe(
                    // debounceTime(600),
                    distinctUntilChanged(([prevAjuste, prevItem], [nextAjuste, nextItem]) => {
                        return prevAjuste !== nextAjuste &&
                        prevItem.CANTIDAD !== nextItem.CANTIDAD
                    }),
                    tap(() => this.pendientesLoading$.next(true)),
                    switchMap(([ajuste, item]) => zip(
                        this.service.getPedidosPendientes(item),
                        of(ajuste),
                        of(item)
                    )),
                    switchMap(([pendientes, ajuste, item]) => {
                        return this.service.convertirPedidosPendientes(item, pendientes, ajuste)
                            .pipe(
                                tap(() => this.stock$.connect())
                            )
                        }
                    ),
                    catchError(() => {this.notiService.error("Ha ocurrido un error al calcular stock.", "Error", true); return of(0)}),
                    tap(() => this.pendientesLoading$.next(false)),
                )
            .subscribe(this.pendientes$)
        );
    }

    ngOnDestroy() {
        this.subscriptions.forEach(s => s.unsubscribe());
    }
}