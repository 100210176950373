import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import * as moment from "moment";
import { CondicionVariante } from '../condiciones.models';

@Component({
    selector: 'app-cc-selector-variantes',
    changeDetection: ChangeDetectionStrategy.OnPush,
    template: `
        <ng-template #VarianteLabel let-v>
            {{ v.descripcion || '(sin nombre)' }}
            <span class="badge badge-warning small" *ngIf="!v.habilitado || !esVigente(v)">
                {{ !v.habilitado ? 'No Habilitada' : 'Sin Vigencia' }}
            </span>
        </ng-template>
        <ng-container *ngIf="variantesVisibles as variantes">
            <div class="btn-group w-100" *ngIf="variantes.length <= 3; else Dropdown">
                <button *ngFor="let v of variantes"
                    class="btn btn-sm"
                    [ngClass]="buttonNgClass(v)"
                    (click)="seleccionar(v)">
                        <ng-container
                            [ngTemplateOutlet]="VarianteLabel"
                            [ngTemplateOutletContext]="{ $implicit: v }">
                        </ng-container>
                </button>
            </div>
            <ng-template #Dropdown>
                <ng-select placeholder="Seleccione una variante..."
                    [clearable]="false"
                    [compareWith]="compareFn"
                    [trackByFn]="trackFn"
                    [ngModel]="seleccionada"
                    (change)="seleccionar($event)"
                    [readonly]="readonly">
                    <ng-option *ngFor="let v of variantes" [value]="v">
                        <ng-container
                            [ngTemplateOutlet]="VarianteLabel"
                            [ngTemplateOutletContext]="{ $implicit: v }">
                        </ng-container>
                    </ng-option>
                </ng-select>
            </ng-template>
        </ng-container>
    `
})
export class SelectorVariantesComponent {
    @Input() readonly: boolean = false;

    @Input() variantes: CondicionVariante[];
    @Input() seleccionada: CondicionVariante;
    @Output() seleccionadaChange: EventEmitter<CondicionVariante> = new EventEmitter();

    readonly compareFn = (a: CondicionVariante, b: Pick<typeof a, 'id'>) => {
        return a.id === b.id;
    }

    readonly trackFn = (a: CondicionVariante) => a.id;

    get variantesVisibles(): CondicionVariante[] {
        if (!this.variantes) {
            return [];
        }

        return this.variantes.filter(variante => {
            return (variante.habilitado && this.esVigente(variante))
                || variante === this.seleccionada;
        });
    };

    buttonNgClass(v: CondicionVariante) {
        return {
            'btn-primary': v === this.seleccionada, 
            'btn-outline-primary': v !== this.seleccionada,
            'disabled': this.readonly
        }
    }

    seleccionar(v: CondicionVariante) {
        if (!this.readonly) {
            this.seleccionadaChange.emit(v)
        }
    }

    esVigente(x: CondicionVariante) {
        const desde = moment(x.vigencia_desde).hour(0).minute(0).second(0).millisecond(0);
        const hasta = moment(x.vigencia_hasta).hour(0).minute(0).second(0).millisecond(0);

        return moment().isBetween(desde, hasta.add(1, 'days'), undefined, '[)');
    }
}