import { Component, EventEmitter, Output } from "@angular/core";

@Component({
    selector: 'app-button-back',
    styles: [`
        .app-button-back {
            font-size: 2rem;
            display: flex;
            align-items: center;
            align-content: center;
            text-align: center;
            padding: 0.8333333333rem;
            margin: 0 30px 0 0;
            /* background: #fff; */
            box-shadow: 0 0.46875rem 2.1875rem rgba(4, 9, 20, 0.03), 0 0.9375rem 1.40625rem rgba(4, 9, 20, 0.03), 0 0.25rem 0.53125rem rgba(4, 9, 20, 0.05), 0 0.125rem 0.1875rem rgba(4, 9, 20, 0.03);
            border-radius: 0.25rem;
            width: 60px;
            height: 60px;
        }
    `],
    template: `
    <button class="btn btn-default btn-hover-shine app-button-back" (click)="clicked()">
        <i aria-hidden="true" class="fa fa-fw" title="chevron-left"></i>
    </button>`
})
export class ButtonBackComponent {
    @Output() click: EventEmitter<any> = new EventEmitter();

    // constructor(private router: Router) {}

    clicked() {
        // this.click.emit($event);
        history.back();
    }
}