<ng-template #content let-modal>
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title">E-mails</h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <ng-container *ngIf="loading; else show"><p>Cargando...</p></ng-container>
      <ng-template #show>
        <div class="form-row">
          <div class="col-sm-12">
            <!--<label class="form-label" *ngFor="let cliente of cliente.emails">{{cliente.descripcion}}</label>-->
            <ul>
              <legend>Principal</legend>
              <li email-list-element *ngIf="default as email" [email]="email" (delete)="onDelete(email)"></li>
              <span *ngIf="!default">No hay datos</span>
              <br>
              <legend>Alternativos</legend>
              <li email-list-element *ngFor="let email of emails" [email]="email" (delete)="onDelete(email)"></li>
              <span *ngIf="!emails.length">No hay datos</span>
            </ul>
          </div>
        </div>
        <div class="form-row">
          <div class="col-sm-12">
            <ng-container *ngIf="creationMode">
              <hr />
              <legend>Nuevo e-mail</legend>
              <form [formGroup]="form" (ngSubmit)="onCreate()">
                <div class="input-group input-group-sm">
                  <input type="email" placeholder="Escriba la dirección aquí..." class="form-control" formControlName="descripcion" />
                  <div class="input-group-append">
                    <button type="submit" class="btn btn-success">Guardar</button>
                  </div>
                  <div class="input-group-append">
                    <button class="btn btn-secondary" (click)="creationMode = false">Cancelar</button>
                  </div>
                </div>
                <ng-container *ngIf="showErrors">
                  <div *ngIf="form.get('descripcion')?.errors as errors">
                    <p *ngIf="errors.required" class="text-danger">El campo no puede estar vacío</p>
                    <p *ngIf="errors.email" class="text-danger">No parece un e-mail válido</p>
                    <p *ngIf="errors.notUnique" class="text-danger">La dirección ya fue registrada para este cliente</p>
                  </div>
                </ng-container>
              </form>
            </ng-container>
          </div>
        </div>
      </ng-template>
    </div>
    <div class="modal-footer">
        <button *ngIf="!creationMode" class="btn btn-success" (click)="creationMode = true">Nuevo email</button>
        <button type="button" class="btn btn-outline-dark" (click)="modal.dismiss('close')">Cerrar</button>
    </div>
  </ng-template>
  <div class="input-group mb-3">
    <input name="email" type="text" [readonly]="true" [value]="default?.descripcion"
      class="form-control text-uppercase" placeholder="Sin datos" aria-label="Sin datos"
      aria-describedby="button-addon2">
    <div class="input-group-append">
      <button
        class="border-0 btn-transition btn btn-warning float-right btn btn-outline-secondary"
        (click)="open(content)" id="button-addon2">
        <i class="fas fa-info"></i>
      </button>
    </div>
  </div>