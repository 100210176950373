import { Resolve, ActivatedRouteSnapshot, Router } from '@angular/router';
import { OrderHeaderIn } from '../models/Order.model';
import { PedidosService } from '../pedidos.service';
import { take, mergeMap, catchError } from 'rxjs/operators';
import { of, EMPTY } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { NotificationService } from 'src/app/utils/notifications/notification.service';

@Injectable({
    providedIn: "root"
})
export class PedidosAprobacionResolverService implements Resolve<OrderHeaderIn[]> {
    
    constructor(private service: PedidosService, private router: Router, private notiService: NotificationService) {}

    resolve(route: ActivatedRouteSnapshot) {

        return this.service.getPedidosAprobacion()
            .pipe(
                take(1),
                mergeMap((pedidos) => {
                    if (pedidos) {
                        return of(pedidos);
                        
                    } else {
                        
                        this.router.navigate(['/']);
                        return EMPTY;
                    }
                }),
                catchError((e: HttpErrorResponse) => {
                    this.notiService.error(e.error.message, 'Error');
                    this.router.navigate(['/']);
                    return EMPTY;
                })
            );
    }
}