<ng-template #content let-modal>
    <ng-container *ngIf="item$|async as item">
        <div class="modal-header">
            <h4 class="modal-title" id="modal-basic-title">Disponibilidad de Producto</h4>
            <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <div class="form-row">
                <div class="form-group col-sm-4">
                    <input class="form-control" [readonly]="true" [value]="item.MATNR" />
                </div>
                <div class="form-group col-sm-8">
                    <input class="form-control" [readonly]="true" [value]="item.mATNR.MAKTX" />
                </div>
            </div>
            <div class="form-row">
                <div class="form-group col-sm-8">
                    <label class="form-label" for="centro">Centro</label>
                    <input id="centro" name="centro" class="form-control" [readonly]="true" [value]="centro.NAME" />
                </div>
                <div class="form-group col-sm-4">
                    <label class="form-label" for="unidad">En unidad de medida</label>
                    <ng-select id="unidad" name="unidad" [items]="unidadesMedida$|async" bindLabel="KMEIN"
                        bindValue="KMEIN" [(ngModel)]="item.UNIDAD" (change)="onChangeUnidad($event)">
                    </ng-select>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-12">
                    <ul ngbNav #nav="ngbNav" class="nav-tabs">
                        <li ngbNavItem="totalCentro">
                            <a ngbNavLink>Total Centro</a>
                            <ng-template ngbNavContent>
                                <app-stock-total-centro [item]="item" [centro]="centro" [ajuste]="ajuste">
                                </app-stock-total-centro>
                            </ng-template>
                        </li>
                        <li ngbNavItem="porAlmacen">
                            <a ngbNavLink>Existencias por Almacenes</a>
                            <ng-template ngbNavContent>
                                <app-stock-por-almacen [item]="item" [centro]="centro.WERKS"></app-stock-por-almacen>
                            </ng-template>
                        </li>
                    </ul>
                    <div [ngbNavOutlet]="nav"></div>
                </div>
            </div>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-outline-dark" (click)="modal.close()">Cerrar</button>
        </div>
    </ng-container>
</ng-template>

<button class="btn btn-icon btn-icon-only" style="padding: 0" (click)="open(content)">
    <i [class]="iconClass$ | async" style="font-size: 28px"></i>
</button>