import { Injectable, QueryList, VERSION, ViewChildren, Directive } from '@angular/core';
import cloneDeep from 'lodash.clonedeep';
import { GlobalConfig, IndividualConfig, ToastContainerDirective, ToastrService } from 'ngx-toastr';
import { ConfirmDialogComponent } from './confirm-dialog.component';

type ToastType = 'info'|'success'|'warning'|'error'

@Directive()
@Injectable({
    providedIn: 'root'
})
export class NotificationService {
    options: GlobalConfig;
    title = '';
    message = '';
    version = VERSION;
    enableBootstrap = false;
    private lastInserted: number[] = [];
    inline = false;
    inlinePositionIndex = 0;
    @ViewChildren(ToastContainerDirective) inlineContainers: QueryList<ToastContainerDirective>;

    constructor(public toastr: ToastrService) {
        this.options = this.toastr.toastrConfig;
        this.options.positionClass = 'toast-top-center';
    }

    success(message: string, title: string, fade: boolean = true) {
        this.openToast(message, title, 'success', fade);
    }

    info(message: string, title: string, fade: boolean = true) {
        this.openToast(message, title, 'info', fade);
    }

    error(message: string, title: string, fade: boolean = false) {
        this.openToast(message, title, 'error', fade);
    }

    warning(message: string, title: string, fade: boolean = false) {
        this.openToast(message, title, 'warning', fade);
    }

    confirm (message: string, title: string): Promise<boolean> {
        return new Promise((resolve, reject) => {
            let options: Partial<IndividualConfig> = Object.assign(cloneDeep(this.options), {
                toastComponent: ConfirmDialogComponent,
                tapToDismiss: false,
                disableTimeOut: true,
                autoDismiss: false,
            });
            let toast = this.toastr.show(message, title, options, "warning");
            let s = toast.onAction.subscribe(x => {
                resolve(x);
                toast.toastRef.close();
                s.unsubscribe();
            });
        });
    }

    private openToast(message: string, title: string, type: ToastType, temp = false, allowHtml = true) {
        // Clone current config so it doesn't change when ngModel updates
        const opt = cloneDeep(this.options);
        opt.enableHtml = allowHtml;
        if (temp === false) {
            opt.tapToDismiss = true;
            opt.closeButton = false;
            opt.newestOnTop = true;
            opt.disableTimeOut = true;
            opt.autoDismiss = false;
        }else{
            opt.tapToDismiss = false;
            opt.newestOnTop = true;
            opt.timeOut = 5000;
        }

        const inserted = this.toastr.show(
            message,
            title,
            opt,
            this.options.iconClasses[type],
        );
        if (inserted) {
            this.lastInserted.push(inserted.toastId);
        }
        return inserted;
    }
}