import { Component, Input, OnInit } from "@angular/core";
import { PaginationService } from './pagination.service';

@Component({
    selector: 'page-selector',
    template: `
    <div class="p-2 d-flex pull-right ">
        <div class="pr-5">
            <ngb-pagination [collectionSize]="collectionSize" [(page)]="page" [maxSize]="10" [pageSize]="pageSize" [rotate]="true" [ellipses]="false" [boundaryLinks]="true"></ngb-pagination>
            <!-- <ngb-pagination [collectionSize]="collectionSize" [(page)]="page" [pageSize]="pageSize"></ngb-pagination> -->
        </div>
        <!-- <select class="custom-select" style="width: auto" name="pageSize" [(ngModel)]="pageSize">
            <option [ngValue]="5">5 items por página</option>
            <option [ngValue]="10">10 items por página</option>
            <option [ngValue]="15">15 items por página</option>
        </select> -->
    </div>
    `
})
export class PageSelectorComponent implements OnInit {

    constructor(protected paginationService: PaginationService) {}

    @Input() collectionSize: number;

    @Input() set page (value: number) {
        this.paginationService.page$.next(value);
    }
    get page() {
        return this.paginationService.page$.getValue();
    }

    @Input() set pageSize (value: number) {
        // this.paginationService.pageSize$.next(value);
        console.warn(`PageSelectorComponent: tamaño de página es de solo lectura`);
    }

    get pageSize() {
        return this.paginationService.pageSize$.getValue();
    }

    @Input() initialPage: number = null;

    ngOnInit() {
        this.page = this.initialPage || 1;
    }
}