import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PaginationModule } from '../pagination/pagination.module';
import { GroupsTableComponent } from './groups-table.component';

@NgModule({
    declarations: [GroupsTableComponent],
    imports: [
        CommonModule,
        FormsModule, 
        ReactiveFormsModule,
        PaginationModule,
    ],
    exports: [GroupsTableComponent]
})
export class GroupsModule {}