import { Injectable } from "@angular/core";
import {
  CanActivate,
  ActivatedRoute,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from "@angular/router";
import { UserService } from "./user.service";
import { of, from, merge, EMPTY } from "rxjs";
import { tap, catchError, switchMap, reduce, map } from "rxjs/operators";
import { NgxPermissionsGuard, NgxPermissionsService } from "ngx-permissions";
import { ifNotNullOrUndefined } from "./utils/operators";
import { NotificationService } from "./utils/notifications/notification.service";

@Injectable({
  providedIn: "root",
})
export class AuthGuardService implements CanActivate {
  constructor(
    private us: UserService,
    private ps: NgxPermissionsService,
    private pguard: NgxPermissionsGuard,
    private notiService: NotificationService
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state) {
    return this.us.getUserData().pipe(
      // Cargo los permisos
      tap((user) => {
        let impersonate = JSON.parse(localStorage.getItem("impersonate"));
        let estoyImpersonado =
          impersonate !== null &&
          impersonate !== undefined &&
          impersonate !== "";

        if (estoyImpersonado) {
          let impersonadoComo = user.autorizaciones.filter((elem) => {
            return (
              JSON.parse(localStorage.getItem("impersonate")).auth_token ===
              elem.auth_token
            );
          });
          this.ps.loadPermissions([
            ...Object.keys(impersonadoComo[0].roles),
            ...Object.keys(impersonadoComo[0].permissions),
          ]);
        } else {
          this.ps.loadPermissions([
            ...Object.keys(user.roles),
            ...Object.keys(user.permissions),
          ]);
        }

        this.us.currentUser = user;
      }),
      // Obtengo todas las routes atravesadas
      switchMap(() => from(route.pathFromRoot)),
      // Como cada una tiene su propio set de permisos, necesito verificarlas individualmente
      map((route) => this.pguard.canActivate(route, state)),
      // Unifico Promise<boolean> | boolean => Observable<boolean>
      switchMap((canActivate) =>
        canActivate instanceof Promise ? from(canActivate) : of(canActivate)
      ),
      // Reduzco el flujo de Observables<boolean> a un solo Observable<boolean>
      reduce((canActivate, guard) => canActivate && guard, true),
      tap((canActivate) => {
        if (!canActivate) {
          this.notiService.error("No tiene permiso para ejecutar esta acción", "Error");
        }
      }),
      catchError((error) => {
        this.ps.flushPermissions();
        return of(false);
      })
    );
  }

  canActivateChild(route: ActivatedRouteSnapshot, state) {
    return this.canActivate(route, state);
  }
}
