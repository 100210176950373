<ng-template #content let-modal>
    <form [formGroup]="form" (ngSubmit)="onSubmit()">
        <input type="hidden" formControlName="id" />
        <div class="modal-header">
            <h4>{{ title }}</h4>
        </div>
        <div class="modal-body">
            <div class="form-group">
                <label>Acción</label>
                <ng-select [items]="tiposAccion$|async" bindLabel="nombre" formControlName="accion"></ng-select>
                <ng-container *ngIf="accion.invalid && (forceShowErrors || accion.dirty || accion.touched)">
                    <small class="text-danger" *ngIf="accion.errors.required">Debe seleccionar un tipo de acción</small>
                </ng-container>
            </div>
            <div class="form-group">
                <label>Valor $</label>
                <input class="form-control" type="number" step="0.01" formControlName="valor" />
                <ng-container *ngIf="valor.invalid && (forceShowErrors || valor.dirty || valor.touched)">
                    <small class="text-danger" *ngIf="valor.errors.min">Valor debe ser mayor o igual a 0</small>
                </ng-container>
            </div>
            <div class="form-group">
                <label>Contenido</label>
                <textarea class="form-control" formControlName="contenido"></textarea>
                <ng-container *ngIf="contenido.invalid && (forceShowErrors || contenido.dirty || contenido.touched)">
                    <small class="text-danger" *ngIf="contenido.errors.required">Debe ingresar un contenido para la acción</small>
                </ng-container>
            </div>
        </div>
        <div class="modal-footer">
            <button type="submit" class="btn btn-success">Guardar</button>
            <button type="button" class="btn btn-secondary" (click)="modal.close(false);">Cancelar</button>
        </div>
    </form>
</ng-template>
<ng-content></ng-content>