import { Component, forwardRef, HostListener, Input, ViewChild } from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";
import { NgbModal, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import { AccionSecundaria } from "../condiciones.models";


@Component({
    selector: '[app-cc-as-modal-contenido]',
    providers: [
        { 
            provide: NG_VALUE_ACCESSOR, 
            useExisting: forwardRef(() => AccionSecundariaContenidoModal),
            multi: true
        }
    ],
    template: `
    <ng-template #content let-modal>
        <div class="modal-header">
            <div>
                <h4 class="modal-title" id="modal-basic-title">
                    Contenido de la Acción {{ accion?.nombre }}
                </h4>
            </div>
            <button type="button" class="close" aria-label="Close" (click)="modal.dismiss()">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <textarea 
                [ngClass]="{'form-control-plaintext': readonly, 'form-control': !readonly}"
                [value]="value"
                [disabled]="disabled"
                [readOnly]="readonly"
                (input)="onInput($event.target.value)" 
                (blur)="onBlur()"></textarea>
        </div>
        <div class="modal-footer" *ngIf="!readonly">
            <button type="button" class="btn btn-success" (click)="modal.close()">Guardar</button>
            <button type="button" class="btn btn-outline-primary" (click)="modal.dismiss()">Cancelar</button>
        </div>
        <div class="modal-footer" *ngIf="readonly">
            <button type="button" class="btn btn-outline-primary" (click)="modal.dismiss()">Cerrar</button>
        </div>
    </ng-template>
    <ng-content></ng-content>
    `
})
export class AccionSecundariaContenidoModal implements ControlValueAccessor {
    private _value: string = '';
    private oldValue: string;

    @Input() accion: AccionSecundaria;
    @Input() edit: boolean = false;
    @Input() set value (value: any) { this._value = value || ''; }

    @ViewChild('content', {static: true}) modalRef: NgbModalRef;
    
    onChange: Function = () => {};
    onTouched: Function = () => {};
    disabled: boolean;
    
    get readonly() { return !this.edit || this.disabled; }
    get value () { return this._value; }
    
    constructor(protected modalService: NgbModal) {}

    @HostListener('click', ['$event'])
    onClick($event: MouseEvent) {
        $event.stopPropagation();

        this.checkpoint();

        let ref = this.modalService.open(this.modalRef, {size: 'lg'});
        ref.result
            .then(() => this.onChange(this.value))
            .catch(() => this.rollback());

        return false;
    }

    onInput(value: any) {
        this.value = value;
        // this.onChange(value);
    }

    onBlur() {
        this.onTouched();
    }

    writeValue(obj: any): void {
        this.value = obj;
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    setDisabledState?(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }

    private rollback() {
        this.value = this.oldValue;
    }

    private checkpoint() {
        this.oldValue = this.value;
    }
}