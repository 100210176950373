<div class="widget-content p-0 file-hover">
    <div class="widget-content-wrapper row">
        <div class="widget-content-left center-elem mr-2"><i class="pe-7s-file text-muted fsize-2"></i></div>
        <div class="widget-content-left col-sm-8">
            <div class="widget-heading">{{ file.name | formatText }}</div>
            <div class="widget-subheading">{{ formatDate(file.mtime) }}</div>
        </div>
        <div class="widget-content-right col-">
            <button class="btn-icon btn-pill btn btn-outline-primary" [disabled]="!file.url" 
            (click)="downloadFile(file)" style="float: right">
                Descargar
            </button>
        </div>
    </div>
</div>