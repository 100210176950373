import { Cliente, Interlocutor, DireccionEntrega, ClienteCredito, HistoriaVentas, CondicionAcuerdo, Texto, ClienteQuery, ClienteDatoComercial } from './models/Cliente.model';

import { Injectable, Inject } from '@angular/core';
import { GenericApiClient } from 'src/app/utils/api/generic-api-client.service';
import { ClientesApiClient } from './clientes.apiclient.service';
import { map } from 'rxjs/operators';
import { PageRequest } from '../utils/datasource/page.model';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

type ClienteOrClientes = Cliente | Cliente[];

@Injectable({ providedIn: 'root' })
export class ClientesService {

    constructor(@Inject(ClientesApiClient) private api: GenericApiClient<Cliente>, private http: HttpClient) {

    }

    private getNavigationProperties() {
        return [
            'clteDatosComs',
            'clteDatosComs.bZIRK',
            'clteDatosComs.sPART',
            'clteDatosComs.vKORG',
            'clteDatosComs.vSBED',
            'clteDatosComs.vTWEG',
            'clteDatosComs.orgCentros',
            'clteDatosComs.orgCentros.wERKS',
            'clteDatosComs.listaPrecios.PTEXT',
            // 'orderHeaderIns',
            // 'orderHeaderIns.pRICELIST',
            // 'orderHeaderIns.estadoDesc',
            'interlocutores.kUNN2',
            'rEGION',
            'clienteCreditos',
            // 'clienteEmails',
            'acuerdos',
        ].join(',');
    }

    getClientes() {
        return this.api.list({expand: 'pernrVendedor'});
    }

    page(page: PageRequest<Cliente>, q: ClienteQuery, params: any = {}) {
        if (q.searchTerm) {
            params['ClienteSearch[term]'] = q.searchTerm;
        }
        if (q.vendedor) {
            params['ClienteSearch[PERNR]'] = q.vendedor;
        }
        if (q.con_pedidos) {
            params['ClienteSearch[con_pedidos]'] = 1;
        }
        if (q.con_pedidos_activos) {
            params['ClienteSearch[con_pedidos_activos]'] = 1;
        }
        return this.api.page(page, params);
    }

    getCliente(id) {
        return this.api.retrieve(id, {
            expand: this.getNavigationProperties()
        }).pipe(
            map(c => this.filtrarAreasVentas(c)),
        );
    }

    getClienteName(id): Observable<string> {
        return this.api.retrieve(id, {
            fields: 'NAME,NAME2'
        }).pipe(map(x => `${x.NAME} ${x.NAME2}`))
    }

    getHistoriaVentas(kunnr: string) {
        return this.api.of(kunnr)
            .navigate<HistoriaVentas>('historia-ventas')
            .list({expand: ['cONDICION', 'cATEGORIA', 'mATERIAL', 'cANAL'].join(',')});
    }

    getAcuerdos(kunnr: string) {
        return this.api.of(kunnr)
            .navigate<CondicionAcuerdo>('acuerdos')
            .list();
    }

    getAreasVentas(kunnr: string, pernr?: string): Observable<ClienteDatoComercial[]> {
        const params = { 
            PERNR: pernr, 
            expand: [
                'bZIRK',
                'sPART',
                'vKORG',
                'vSBED',
                'vTWEG',
                'orgCentros',
                'orgCentros.wERKS',
                'listaPrecios.PTEXT'
            ].join(',')
        };

        return this.api.of(kunnr)
            .navigate<ClienteDatoComercial>('areas-ventas')
            .list(params);
    }


    /* getPedidosOf(KUNNR) {
        return this.api.of(KUNNR).navigate<OrderHeaderIn>('pedidos').list({
            expand: PEDIDOS_EXPAND_PARAM
        });
    } */

    resolverDireccionEntrega(cliente: Cliente): {STRAS: string, CITY: string, POSTL_COD1: string} {
        const ilocutor = cliente && cliente.interlocutores ? 
            cliente.interlocutores.find(i => i.PARVW === "WE") : null;
        return ilocutor && ilocutor.kUNN2 ? {
            STRAS: ilocutor.kUNN2.STRAS, 
            CITY: ilocutor.kUNN2.CITY,
            POSTL_COD1: ilocutor.kUNN2.POSTL_COD1
        } : null;
    }

    getInterlocutorEntregaDefault(cliente: Cliente): Interlocutor | null {
        return this.getInterlocutoresEntrega(cliente).find(i => i.DEFPA);
    }

    getInterlocutoresEntrega(cliente: Cliente): Interlocutor[] {
        if (!cliente.interlocutores) {
            return [];
        }

        // Los datos comerciales con LOEVM == 'X' se eliminan al traer el cliente
        const tieneDatoComercialAsociado = x => cliente.clteDatosComs.some(y => 
           (x.VTWEG == y.VTWEG) && (x.VKORG == y.VKORG) && (x.SPART == y.SPART)
        );

        return cliente.interlocutores
            .filter(x => x.PARVW === "WE" && tieneDatoComercialAsociado(x));
    }

    getDireccionesEntrega(cliente: Cliente): DireccionEntrega[] {
        return this.getInterlocutoresEntrega(cliente).map(i => i.kUNN2);
    }

    getDireccionEntregaDefault(cliente: Cliente): DireccionEntrega {
        let DEFAULT = this.getInterlocutorEntregaDefault(cliente);
        return DEFAULT && DEFAULT.kUNN2;
    }

    getInfoCrediticia(cliente: Cliente): ClienteCredito[] {
        return cliente.clienteCreditos;
    }

    getEmails(KUNNR: string) {
        return this.api.of(KUNNR).navigate<Texto>('emails').list({expand: 'solicitud'});
    }

    createEmail(KUNNR: string, email: Partial<Texto>) {
        return this.api.of(KUNNR).navigate<Texto>('emails').create(email, {expand: 'solicitud'});
    }

    deleteEmail(KUNNR: string, id: number) {
        return this.api.of(KUNNR).navigate<Texto>('emails').delete(id);
    }
//********* */

    private filtrarAreasVentas (cliente: Cliente) {
        cliente = Object.assign({}, cliente);
        cliente.clteDatosComs = cliente.clteDatosComs.filter(dato => dato.habilitado && !dato.LOEVM && !dato.AUFSD);
        return cliente;
    };
}