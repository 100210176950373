<div class="table-responsive p-2">
    <ng-container *ngIf="interlocutores$|async as interlocutores; else noData">
        <table *ngIf="interlocutores.length; else noData" class="align-middle text-truncate mb-2 table table-borderless table-hover">
            <thead>
                <tr class="text-left">
                <th>ID</th>
                <th>Denominación</th>
                <th>Dirección</th>
                <th>Ciudad</th>
                <th>Provincia</th>
                <th>Teléfono</th>
                </tr>
            </thead>
            <tbody *ngFor="let i of interlocutores">
                <td>{{ i.KUNN2}}</td>
                <td>{{ i.kUNN2.NAME + i.kUNN2.NAME2}}</td>
                <td>{{ i.kUNN2.STRAS }}</td>
                <td>{{ i.kUNN2.CITY }}</td>
                <td>{{ i.kUNN2.PROVINCIA }}</td>
                <td>{{ i.kUNN2.TEL1_NUMBR }}</td>
            </tbody>
        </table>
    </ng-container>
    <ng-template #noData>
        <div class="p-3">
            <p>No hay direcciones para mostrar</p>
        </div>
    </ng-template>
</div>