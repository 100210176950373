import { Component, Input } from '@angular/core';
import { ComboGrupoAplicado, CondicionVarianteCombo, CondicionVarianteComboGrupo } from '../condiciones.models';

@Component({
    selector: 'app-cc-reglas-descuento',
    templateUrl: './cc-reglas-descuento.component.html'
})
export class CondicionComercialReglasDescuentoComponent {
    @Input() combo: CondicionVarianteCombo;

    @Input() set aplicados (value: ComboGrupoAplicado[]) {
        if (value) {
            value.forEach(x => 
                this.combo.condicionVarianteComboGrupos.forEach(y => {
                    if (x.condicion_variante_combo_grupo_id == y.id) {
                        y.registrarAplicacion(x);
                    }
                })
            );
        }
    }

    get grupos(): CondicionVarianteComboGrupo[] {
        if (!this.combo) {
            return null;
        }
        return this.combo.condicionVarianteComboGrupos;
    }
}