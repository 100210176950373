import { Injectable } from "@angular/core";
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class PaginationService {
    page$: BehaviorSubject<number> = new BehaviorSubject(1);
    pageSize$: BehaviorSubject<number> = new BehaviorSubject(20);

}