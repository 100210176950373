import { formatCurrency } from '@angular/common';
import { Component, Input } from "@angular/core";
import { BehaviorSubject, combineLatest, Observable, Subject } from 'rxjs';
import { catchError, distinctUntilChanged, map, startWith, tap } from 'rxjs/operators';
import { ColumnDefinition, GroupDefinition, GroupOrdering } from '../utils/groups/groups-table.model';
import { StringHelper } from '../utils/string.helper';
import { SituacionItem } from './models/situacion.model';


const GRUPO_OTROS = 20;


@Component({
    selector: 'app-situacion-table',
    templateUrl: './situacion-table.component.html',
    styleUrls: ['situacion-table.component.scss']
})
export class SituacionTableComponent {

    groupsDefs: GroupDefinition[] = [
        { colspan: 2, label: "" },
        { colspan: 2, label: "Objetivo" },
        { colspan: 2, label: "Venta" },
        { colspan: 2, label: "Pend.a fact" },
        { colspan: 2, label: "Saldo" },
    ]

    columnDefs: ColumnDefinition[] = [
        {
            label: '#',
            bindProperty: 'MATNR',
            textAlign:'right'
        },
        {
            label: 'Descripción',
            bindProperty: 'MAKTX',
            textAlign:'left'
        },
        {
            label: 'Objetivo',
            bindProperty: 'objetivo',
            bold: true,
            hidden: true
        },
        {
            label: 'CJ',
            bindProperty: 'objetivo_cj',
            textAlign:'right',
            groupdata: true,
            transform: function(value) {
                return Math.floor((value === undefined) ? 0: parseFloat(value));
            }
        },
        {
            label: '$',
            bindProperty: 'objetivo_ps',
            textAlign:'right',
            groupdata: true,
            transform: function(value) {
                return (value === undefined) ? 0 : formatCurrency((Math.round((parseFloat(value) + Number.EPSILON) * 100) / 100), 'es-AR', '$')
            }
        },
        {
            label: 'CJ',
            bindProperty: 'c_total_cj',
            textAlign:'right',
            groupdata: true,
            transform: function(value) {
                return Math.floor((value === undefined) ? 0: parseFloat(value));
            }
        },
        {
            label: '$',
            bindProperty: 'c_monto',
            textAlign:'right',
            groupdata: true,
            transform: function(value) {
                return (value === undefined) ? 0 : formatCurrency((Math.round((parseFloat(value) + Number.EPSILON) * 100) / 100), 'es-AR', '$')
            }
        },
        {
            label: 'CJ',
            bindProperty: 'a_total_cj',
            textAlign:'right',
            groupdata: true,
            transform: function(value) {
                return Math.floor((value === undefined) ? 0: parseFloat(value));
            }
        },
        {
            label: '$',
            bindProperty: 'a_monto',
            textAlign:'right',
            groupdata: true,
            transform: function(value) {
                return (value === undefined) ? 0 : formatCurrency((Math.round((parseFloat(value) + Number.EPSILON) * 100) / 100), 'es-AR', '$')
            }
        },
        {
            label: 'CJ',
            bindProperty: 'saldo_cj',
            textAlign:'right',
            groupdata: true,
            transform: function(value) {
                return Math.floor((value === undefined) ? 0: parseFloat(value));
            }
        },
        {
            label: '$',
            bindProperty: 'saldo_ps',
            textAlign:'right',
            groupdata: true,
            transform: function(value) {
                return (value === undefined) ? 0 : formatCurrency((Math.round((parseFloat(value) + Number.EPSILON) * 100) / 100), 'es-AR', '$')
            }
        }
    ];

    groupOrdering: GroupOrdering[] = [
        {
            group: GRUPO_OTROS,
            order: 'last'
        }
    ]

    private data$: BehaviorSubject<SituacionItem[]> = new BehaviorSubject([]);

    @Input() set data (value: SituacionItem[]) {
        this.data$.next(value);
    }

    get data(): SituacionItem[] {
        return this.data$.getValue();
    }

    @Input() groupBy: string = '';
    collapse: boolean = true;

    // searchBox = new FormControl('');
    search$ = new Subject<string>();
    searchTerm$: Observable<string> = this.search$// this.searchBox.valueChanges
        .pipe(
            startWith(''), 
            /* debounceTime(500),
            distinctUntilChanged(), */
            // tap((term) => console.log(`Preparando ${term} para buscar...`)),
            map((term) => term.trim()),
            map((term) => term.toUpperCase()),
            map((term) => StringHelper.removeTildes(term)),
            tap((term) => { if (term && this.collapse) this.collapse = false })
        );

    items$: Observable<SituacionItem[]> = combineLatest([this.data$, this.searchTerm$])
        .pipe(
            // tap(([data, term]) => console.log(`Buscando ${term}...`)),
            map(([data, term]) => data.filter(i => {
                return StringHelper.removeTildes(i.MAKTX.toUpperCase()).includes(term) ||
                    StringHelper.removeTildes(i.MATNR.toUpperCase()).includes(term);
            })),
            // tap((items) => console.log(`Encontrados ${items.length} elementos`)),
            catchError(() => [])
        );

    collectionSize$ = this.items$.pipe(
        distinctUntilChanged(),
        map((items) => items.length),
        distinctUntilChanged(),
    );
}