import { Component, Input } from '@angular/core';
import { CondicionVarianteEntrega, CondicionVarianteEntregaGrupo, EntregaGrupoAplicado } from '../condiciones.models';

@Component({
    selector: 'app-cc-reglas-entrega',
    templateUrl: './cc-reglas-entrega.component.html'
})
export class CondicionComercialReglasEntregaComponent {
    @Input() entrega: CondicionVarianteEntrega;

    @Input() set aplicados (value: EntregaGrupoAplicado[]) {
        if (value) {
            value.forEach(x => 
                this.entrega.condicionVarianteEntregaGrupos.forEach(y => {
                    if (x.condicion_variante_entrega_grupo_id == y.id) {
                        y.registrarAplicacion(x);
                    }
                })
            );
        }
    }

    get grupos(): CondicionVarianteEntregaGrupo[] {
        if (!this.entrega) {
            return null;
        }
        return this.entrega.condicionVarianteEntregaGrupos;
    }
}