import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { environment } from 'src/environments/environment';
import { File } from "../widgets/files/models/File.model";
import { URLHelper } from "./url-helper.helper";

@Injectable({providedIn: 'root'})
export class DocumentsService {
    constructor(private http: HttpClient) {}

    fetch(id: string|number): Observable<File[]> {
        let url = URLHelper.concat(environment.API_BASE_URL, `api/pedidocs?id=${id}`);

        return this.http.get(url).pipe(map(data => {
            return Object.keys(data)
                .filter(key => data[key]["200"] !== undefined)
                .filter(key => data[key]["200"]["{DAV:}resourcetype"] === null || data[key]["200"]["{DAV:}resourcetype"] === undefined)
                .map((key, i) => {
                    let f_url = environment.API_BASE_URL + 'api/pedidocsdl?id=' + key;
                    let f_nombre = f_url.split(/[\/ ]+/).pop();
                    return {
                        name: f_nombre,
                        mtime: new Date(),
                        url: f_url,
                        mimetype: data[key]["200"]["{DAV:}getcontenttype"]
                    };
                });
        }));
    }
}