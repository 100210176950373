import { formatDate } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { ChangeDetectionStrategy, Component, Inject, Input, LOCALE_ID, OnInit } from "@angular/core";
import { saveAs } from 'file-saver';
import * as moment from 'moment';
import { Moment } from 'moment';
import { NotificationService } from 'src/app/utils/notifications/notification.service';
import { File } from '../models/File.model';

@Component({
    selector: 'app-file',
    templateUrl: './file.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class FileComponent implements OnInit {
    @Input() file: File;
    @Input() dateFormat: string = "dd/MM/yyyy";

    constructor(
        public httpClient: HttpClient,
        private notiService: NotificationService,
        @Inject(LOCALE_ID) private readonly LOCALE_ID: string
    ){

    }

    ngOnInit() {}

    downloadFile(file: File) {
        //UTILIZO HTTPCLIENT CON UN HANDLER NATIVO PARA EVITAR LOS INTERCEPTORS PORQUE 
        //EN DESARROLLO NO TENGO EL SAML2 Y NECESITO PASAR EL BASIC AUTH.
        const request$ = this.httpClient.request('GET', file.url, { 
            responseType: 'blob' 
        });

        return request$.subscribe(data => {
            if (data instanceof Array) {
                this.notiService.error("Ocurrió un problema al intentar descargar el archivo" + "(" + data["statusCode"] + ")", 'Error', true);
                return;
            }
    
            if ( ! (data instanceof Blob)) {
                this.notiService.error("No se pudo obtener el archivo.", 'Error');
                return;
            }
    
            let blob = new Blob([data], { type: file.mimetype });
            saveAs(blob, decodeURI(file.name));
        });
    }

    formatDate(date: string | Date | Moment) {
        const f = (date: Date | string) => 
            formatDate(date, this.dateFormat, this.LOCALE_ID);
        
        if (moment.isMoment(date)) {
            return f(date.toDate());
        }

        return f(date);
    }

}