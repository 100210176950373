import { Pipe, PipeTransform } from "@angular/core";

@Pipe({ name: 'ltrim' })
export class LtrimPipe implements PipeTransform {
    transform(value: any, char: string = ' ') {
        if (typeof value !== 'string') {
            return value;
        }

        let index = 0;

        while (value[index] === char) {
            index++;
        }

        return value.slice(index);
    }
}