import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { take, mergeMap, catchError } from 'rxjs/operators';
import { of } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { VendedoresService } from 'src/app/vendedores.service';

@Injectable({
    providedIn: "root"
})
export class VendedorBreadcrumbResolverService implements Resolve<string> {
    
    constructor(private service: VendedoresService) {}

    resolve(route: ActivatedRouteSnapshot) {
        return this.service.getVendedorName(route.paramMap.get('pernr'))
            .pipe(
                take(1),
                mergeMap((name) => of(name)),
                catchError((e: HttpErrorResponse) => of(''))
            );
    }
}