import { Component, Input } from '@angular/core';
import { NotificationService } from 'src/app/utils/notifications/notification.service';
import { AccionSecundaria, Condicion, CondicionVariante, CondicionVarianteComboAccion } from '../condiciones.models';
import { CondicionesService } from '../condiciones.service';

@Component({
    selector: 'app-cc-disponible',
    templateUrl: './condicion-comercial-disponible.component.html'
})
export class CondicionComercialDisponibleComponent {
    private _variante: CondicionVariante;

    condicionLineas = [];

    get condicion(): Condicion {
        return this._condicion;
    }

    get secundarias(): AccionSecundaria[] {
        if (!this.variante || !this.variante.condicionVarianteAccionsecs) {
            return [];
        }
        return this.variante.condicionVarianteAccionsecs.filter(x => x.habilitado);
    }

    botonAgregarHabilitado: boolean = true;

    @Input() set condicion(data: Condicion) {
        this._condicion = data;
        this._condicion.variante_id = data.variante_id ? data.variante_id : data.condicionVariantes[0].id;
        this.variante = data.condicionVariantes.find(x => x.id == data.variante_id) || data.condicionVariantes[0];
        
        for (let variante of this._condicion.condicionVariantes) {
            let combo = variante.condicionVarianteCombos[0];

            if (combo == undefined) {
                return;
            }

            for (let accion of combo.condicionVarianteComboAccions) {
                let linea: CondicionVarianteComboAccion;

                for (let x of this.condicionLineas) {
                    if (x.SPART == accion.SPART &&  x.KONDM == accion.KONDM &&  x.MATNR == accion.MATNR) {
                        linea = x;
                        break;
                    }
                }

                if (linea === null || linea === undefined) {
                    linea = Object.assign({}, accion);
                    delete linea['valor'];
                    linea.descuentos = [];
                    this.condicionLineas.push(linea);
                }
                
                linea['dto_' + variante.id ] = accion.valor;
                linea.descuentos.push(accion.valor);
            }
        };
    }

    @Input() edit: boolean = false;

    // @Output() select: EventEmitter<boolean> = new EventEmitter();

    private _condicion: Condicion;

    constructor(private condicionesService: CondicionesService, private notiService: NotificationService) {}

    onSelect() {
        if (this.edit) {
            this.botonAgregarHabilitado = false;
            this.notiService.confirm(`¿Agregar la condición '${this.condicion.nombre}'?`, 'Confirmar')
                .then(confirma => {
                    if (confirma) {
                        this.condicionesService.select(this.condicion);
                    }
                })
                .finally(() => this.botonAgregarHabilitado = true);
        }
    }

    set variante(v: CondicionVariante) {
        this.condicion.variante_id = v.id;
        this._variante = v;
    }

    get variante(): CondicionVariante {
        return this._variante;
    }
}