import { Component, Input } from "@angular/core";
import { OrderHeaderIn, OrderItemsIn } from "../models/Order.model";

@Component({
    selector: 'app-p-entrega-gratuita',
    styles: ['td, th { padding: 0.35rem 0.2rem; }'],
    templateUrl: './entrega-gratuita.component.html',
})
export class EntregaGratuitaComponent {
    @Input() entrega: OrderHeaderIn;
}