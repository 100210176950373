import { Component, EventEmitter, Input, Output } from "@angular/core";
import { Observable, of } from 'rxjs';
import { ISort } from './sortable-header.model';

@Component({
    selector: '[sortable]',
    template: `
    <span role="button" class="btn btn-link" (click)="sort.emit(property)">
        <ng-container *ngIf="state$|async as state">
            <span class="icon" [ngClass]="{
                'sort-asc': state.property == property && state.ascending, 
                'sort-desc': state.property == property && !state.ascending
            }">
            </span>
        </ng-container>
        <ng-content></ng-content>
    </span>
    `,
    styleUrls: ['./sortable-header.component.scss']
})
export class SortableHeaderComponent {
    state$: Observable<ISort<any>>;

    @Input() set state (value: ISort<any>|Observable<ISort<any>>) {
        if (value instanceof Observable) {
            this.state$ = value;
        } else {
            this.state$ = of(value);
        }
    }

    @Input() property: string;

    @Output() sort: EventEmitter<string> = new EventEmitter();

}