<ng-template #content let-modal>
    <form (ngSubmit)="onSubmit()" [formGroup]="form">
        <div class="modal-header">
            <h4 class="modal-title" id="modal-basic-title">Condiciones de Entrega</h4>
            <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <div>
                <legend>Condición de Entrega</legend>
                <div class="form-group">
                    <label class="form-label" for="condicion">Cond. Expedición</label>
                    <input type="text" id="condicion" name="condicion" class="form-control" [readonly]="true"
                        [value]="datoComercial.vSBED.VTEXT || '(desconocido)'" />
                </div>
                <div class="form-group">
                    <label class="form-label" for="terminos">Incoterm</label>
                    <ng-select id="terminos" bindValue="INCO1" bindLabel="BEZEI" formControlName="incoterms" [readonly]="!editable">
                        <ng-option *ngFor="let x of incoterms" [value]="x.INCO1">
                            {{ x.INCO1 }} - {{ x.BEZEI }}
                        </ng-option>
                    </ng-select>
                    <div class="help-block text-danger small" *ngIf="form.errors?.incoterms as required">
                        'Retira de fábrica' requiere incoterms = {{ required }}. Verifique.
                    </div>
                </div>
            </div>
            <div>
                <legend>Dirección de Entrega</legend>
                <ng-container *ngIf="interlocutores.length > 0; else unknownDir">
                    <ng-container *ngIf="editable; else readonly">
                        <ng-select id="direccion" name="direccion" formControlName="direccion" [readonly]="!editable">
                            <ng-option *ngFor="let x of interlocutores" [value]="x.KUNN2">
                                {{ formatearDireccion(x.kUNN2) }}
                            </ng-option>
                        </ng-select>
                    </ng-container>
                    <ng-template #readonly>
                        <input id="calle" name="calle" type="text" class="form-control" [readonly]="true"
                            [value]="formatearDireccion(interlocutor?.kUNN2)"  />
                    </ng-template>
                </ng-container>
                <ng-template #unknownDir>
                    <p>No hay direcciones para seleccionar</p>
                </ng-template>
            </div>
        </div>
        <div class="modal-footer">
            <ng-container *ngIf="editable; else view">
                <button type="submit" class="btn btn-success" [disabled]="!form.valid">Aceptar</button>
                <button type="button" class="btn btn-outline-dark" (click)="modal.dismiss('cancel')">Cancelar</button>
            </ng-container>
            <ng-template #view>
                <button type="button" class="btn btn-outline-dark" (click)="modal.dismiss('close')">Cerrar</button>
            </ng-template>
        </div>
    </form>
</ng-template>

<button class="btn btn-warning text-nowrap" [disabled]="!datoComercial" (click)="open(content)">Condiciones de Entrega</button>