import { Inject, Injectable } from "@angular/core";
import { BehaviorSubject } from 'rxjs';
import { GenericApiClient } from '../utils/api/generic-api-client.service';
import { CondicionesApiClient } from './condiciones.apiclient.service';
import { Condicion } from './condiciones.models';

type SelectableCondicion = Condicion & {selected: boolean};
export const EXPAND_PARAM = [
].join(',');

@Injectable({
    providedIn: 'root'
})
export class CondicionesService {
    private condiciones: {[key: number]: SelectableCondicion} = {};

    selectedCondiciones$: BehaviorSubject<Condicion[]> = new BehaviorSubject([]);
    notSelectedCondiciones$: BehaviorSubject<Condicion[]> = new BehaviorSubject([]);

    private getNavigationProperties() {
        return EXPAND_PARAM;
    }

    constructor (
        @Inject(CondicionesApiClient) private api: GenericApiClient<Condicion>,
    ) {}

    getCondicion(id: number) {
        return this.api.retrieve(id, { 
            // expand: this.getNavigationProperties()
        }); //.pipe();
    }

    setCondiciones(condiciones: Condicion[]) {
        this.condiciones = {};
        for (let _condicion of condiciones) {
            var condicion: SelectableCondicion = _condicion as SelectableCondicion;
            condicion.selected = false;
            this.condiciones[condicion.id] = condicion;
        }
    }

    getCondiciones() {
        return Object.values(this.condiciones);
    }

    select(...condiciones: any[]) {
        for (let c of condiciones) {
            let id = this.getId(c);
            if (this.condiciones[id]) {
                this.condiciones[id].selected = true;
                this.condiciones[id].variante_id = c.variante_id;
                // this.condiciones[id].accion_id = c.accion_id;
                // this.condiciones[id].valor = c.valor;
                this.condiciones[id].acciones = c.acciones;
            }
        }
        this.castSelection();
    }

    unselect(...condiciones: any[]) {
        for (let c of condiciones) {
            let id = this.getId(c);
            if (this.condiciones[id]) {
                this.condiciones[id].selected = false;
            }
        }
        this.castSelection();
    }

    reset(condiciones?: any[]) {
        this.getCondiciones().forEach(condicion => condicion.selected = false);
        if (condiciones) {
            this.select(...condiciones);
        }
    }

    isSelected(condicion: any) {
        let id = this.getId(condicion);
        return !!this.condiciones[id] && this.condiciones[id].selected;
    }

    cleanUp() {
        this.condiciones = {};
        this.selectedCondiciones$.next([]);
    }

    protected castSelection () {
        this.selectedCondiciones$.next(
            Object.values(this.condiciones).filter(c => c.selected === true)
        );
        this.notSelectedCondiciones$.next(
            Object.values(this.condiciones).filter(c => !c.selected)
        );
    }

    protected getId (condicion: any) {
        return (typeof condicion == 'number' ? condicion : condicion.id);
    }
}