import { NgModule } from '@angular/core';
import { SharedModule } from 'src/app/shared.module';
import { SemaforoStockComponent } from './semaforo-stock.component';
import { StockPorAlmacenComponent } from './stock-por-almacen.component';
import { StockTotalCentroComponent } from './stock-total-centro.component';

@NgModule({
    declarations: [
        SemaforoStockComponent, 
        StockPorAlmacenComponent, 
        StockTotalCentroComponent
    ],
    imports: [
        SharedModule
    ],
    exports: [
        SemaforoStockComponent
    ]
})
export class StockModule {}