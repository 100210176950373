<ng-container *ngIf="situacion as s">
  <div class="row">
    <div class="col-lg-3">
      <app-progress-box title="Facturado / Objetivo" subtitle="en cajas" [value]="s.facturado_s_objetivo_cj"></app-progress-box>
      <app-progress-box title="Vendido / Objetivo" subtitle="en cajas" [value]="s.vendido_s_objetivo_cj"></app-progress-box>
    </div>
    <div class="col-lg-3">
      <app-progress-box title="Facturado / Objetivo" subtitle="en $" [value]="s.facturado_s_objetivo_ps"></app-progress-box>
      <app-progress-box title="Vendido / Objetivo" subtitle="en cajas" [value]="s.vendido_s_objetivo_cj"></app-progress-box>
    </div>
    <div class="col-lg-3">
      <app-number-box title="Total Facturado">$ {{ s.facturado_ps | number:'1.2-2' }}</app-number-box>
      <app-number-box title="Total Vendido">$ {{ s.vendido_ps | number: '1.2-2' }}</app-number-box>
    </div>
    <div class="col-lg-3">
          <!-- <div class="card main-card mb-3">
            <div class="card-body">
              <mwl-gauge
                class="two"
                [max]="100"
                [dialStartAngle]="180"
                [dialEndAngle]="0"
                [value]="gaugeValues[2]"
                [animated]="true"
                [animationDuration]="2">
              </mwl-gauge>
            </div>
          </div> -->
    </div>
  </div>
  <div class="main-card mb-3 card">
    <div class="card-body">
      <ng-container *ngIf="s?.children && s?.children?.length > 0; else noData">
        <div class="table-responsive">
          <app-situacion-table [data]="s.children" groupBy="grupo_mat"></app-situacion-table>
        </div>
      </ng-container>
      <ng-template #noData>
        <div class="card-body">
          <p>No hay datos para mostrar</p>
        </div>
      </ng-template>
    </div>
  </div>
</ng-container>
