import { Inject, Injectable } from '@angular/core';
import { VendedoresApiClient } from './vendedores.apiclient';
import { GenericApiClient } from './utils/api/generic-api-client.service';
import { User } from './models/User.model';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class VendedoresService {

    constructor(@Inject(VendedoresApiClient) private vendedoresApi: GenericApiClient<User>) {}

    getVendedores() {
        return this.vendedoresApi.list();
    }

    getVendedorName(pernr: string) {
        return this.vendedoresApi.retrieve(pernr, {fields: 'firstname,lastname'})
            .pipe(map(x => `${x.lastname}, ${x.firstname}`));
    }
}