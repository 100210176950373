import { formatDate } from "@angular/common";
import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { from, Observable, pipe, throwError } from "rxjs";
import { map } from "rxjs/operators";
import { GenericApiClient } from "src/app/utils/api/generic-api-client.service";
import { environment } from "src/environments/environment";
import { ClientesApiClient } from "../clientes/clientes.apiclient.service";
import { ClientesService } from "../clientes/clientes.service";
import { Cliente } from "../clientes/models/Cliente.model";
import { ListaPreciosService } from "../lista-precios/lista-precios.service";
import { MaterialPrVtas } from "../lista-precios/models/ListaPrecios.model";
import { UserService } from "../user.service";
import { Group } from "../utils/groups/groups.model";
import { NotificationService } from "../utils/notifications/notification.service";
import { URLHelper } from "../utils/url-helper.helper";
import { Descuento, OrderConditionsIn, OrderHeaderIn, OrderItemsIn, Pedido, PedidoItem } from "./models/Order.model";
import { PedidosApiClient } from "./pedidos.apiclient.service";
import { AUTORIZAR_PEDIDOS_PERMISSION, CREAR_PEDIDOS_PERMISSION, MODIFICAR_PEDIDOS_PERMISSION, VER_PEDIDOS_PERMISSION } from "./pedidos.permissions";
import { PageRequest } from "../utils/datasource/page.model";
import * as moment from "moment";


type OrderHeaderInOrOrderHeaderIns = OrderHeaderIn | OrderHeaderIn[];
type OrderHeaderIns = OrderHeaderIn[];

export const PEDIDOS_DETAIL_EXPAND_PARAM = [
    //'orderHeaderInCombos',
    //'orderHeaderInCombos.combo',
    "condicionVarianteOrderHeaderIns.condicionVariante.condicion",
    "condicionVarianteOrderHeaderIns.condicionVariante.condicionVarianteCombos",
    "condicionVarianteOrderHeaderIns.comboGrupoAplicados",
    "condicionVarianteOrderHeaderIns.condicionVariante.condicionVarianteEntregas",
    "condicionVarianteOrderHeaderIns.entregaGrupoAplicados",
    "condicionVarianteOrderHeaderIns.accionesSecundariasAplicadas.solicitudSecundaria",
    "entregaGratuita.orderItemsIns",
    "entregaGratuita.orderItemsIns.mATERIAL",
    "orderItems.tARGETQU",
    "orderItems.orderConditionsIns",
    "orderItems.orderConditionsIns.conditionType",
    "orderItems.mATERIAL",
    "orderItems.mATERIAL.materialUmconvs",
    "orderItems.valorFacturado",
    "orderItems.impNetoPendiente",
    "orderItems.cantPendiente",
    "orderItems.importe",
    "orderItems.itemEntregaGratuita",
    /*  'orderItemsIns.descuentoPorAcuerdos',
      'orderItemsIns.retorno', */
    "direccion_entrega",
    "datoComercial",
    "datoComercial.vSBED",
    'datoComercial.listaPrecios.PTEXT',
    "datoComercial.orgCentros",
    "datoComercial.orgCentros.wERKS",
    "datoComercial.vTWEG",
    "datoComercial.bZIRK",
    "datoComercial.sPART",
    "datoComercial.vKORG",
    "datoComercial.iNCO1",
    "kUNNR",
    "kUNNR.interlocutores.kUNN2",
    "kUNNR.clteDatosComs",
    "kUNNR.clteDatosComs.bZIRK",
    "kUNNR.clteDatosComs.sPART",
    "kUNNR.clteDatosComs.vKORG",
    "kUNNR.clteDatosComs.vSBED",
    "kUNNR.clteDatosComs.vTWEG",
    "kUNNR.clteDatosComs.iNCO1",
    "kUNNR.clteDatosComs.orgCentros",
    "kUNNR.clteDatosComs.orgCentros.wERKS",
    'kUNNR.clteDatosComs.listaPrecios.PTEXT',
    "kUNNR.acuerdos",
    "pLANT",
    "pRICELIST",
    "estadoDesc",
    "kUNNR",
    "peso_total",
    "apr_cred",
    "importe_total",
    "estadoColor",
    "impNetoPendiente",
    "diasAtraso",
    "ultimaObservacion",
    "pernrAndNombreVendedor",
    "accionesSecundariasManuales.accion",
    "accionesSecundariasManuales.solicitudSecundaria",
    "advertencias"
].join(",");

export const PEDIDOS_LIST_EXPAND_PARAM = [
    "kUNNR",
    "peso_total",
    "apr_cred",
    "importe_total",
    "impNetoPendiente",
    "diasAtraso",
    "vendedor",
    "ultimaObservacion",
    "pRICELIST",
    "estadoDesc",
];

@Injectable({
    providedIn: "root",
})
export class PedidosService {
    userCanView$ = from(this.userService.currentUserCan(VER_PEDIDOS_PERMISSION));
    userCanEdit$ = from(
        this.userService.currentUserCan(MODIFICAR_PEDIDOS_PERMISSION)
    );
    userCanAuthorize$ = from(
        this.userService.currentUserCan(AUTORIZAR_PEDIDOS_PERMISSION)
    );
    userCanCreate$ = from(
        this.userService.currentUserCan(CREAR_PEDIDOS_PERMISSION)
    );

    private getNavigationProperties() {
        return PEDIDOS_DETAIL_EXPAND_PARAM;
    }

    private ordernarPorFecha<OrderHeaderIns>(ascendente: boolean = true) {
        let sortAscendente = (i, j) => (i.PURCH_DATE < j.PURCH_DATE ? -1 : 1);
        let sortDescendente = (i, j) => (i.PURCH_DATE > j.PURCH_DATE ? -1 : 1);

        return pipe(
            map((pedidos: OrderHeaderIn[]) =>
                pedidos.sort(ascendente ? sortAscendente : sortDescendente)
            )
        );
    }

    constructor(
        @Inject(PedidosApiClient) private api: GenericApiClient<OrderHeaderIn>,
        @Inject(ClientesApiClient) private clientesApi: GenericApiClient<Cliente>,
        private lpService: ListaPreciosService,
        private userService: UserService,
        private clientesService: ClientesService,
        private notiService: NotificationService,
        private http: HttpClient
    ) { }

    getPedidos(pernr?: any) {
        var params: { [key: string]: any } = {
            // expand: this.getNavigationProperties()
            expand: PEDIDOS_LIST_EXPAND_PARAM.join(","),
        };
        if (pernr) {
            // params['pernr'] = pernr;
            return this.getPedidosByVendedor(pernr); //.pipe(this.ordernarPorFecha(false));
        }
        return this.api.list(params).pipe(this.ordernarPorFecha(false));
    }

    getPedido(id: number) {
        return this.api.retrieve(id, { expand: this.getNavigationProperties() }).pipe(
            map(pedido => {
                return {
                    ...pedido, 
                    orderItems: pedido.orderItems.map(item => {
                        let estadoDespacho = item.estadoDespacho ?? crearEstadoDespachoDefault(item);

                        if (item.itemEntregaGratuita && !item.itemEntregaGratuita.ABGRU) {
                            estadoDespacho = resumirEstadoDespacho(
                                estadoDespacho,
                                item.itemEntregaGratuita.estadoDespacho ?? crearEstadoDespachoDefault({
                                    ...item.itemEntregaGratuita,
                                    TARGET_QTY: item.bonifica_canal
                                        ? item.itemEntregaGratuita.TARGET_QTY - item.TARGET_QTY
                                        : item.itemEntregaGratuita.TARGET_QTY
                                }),
                            );
                        }

                        return { ...item, estadoDespacho };
                    }),
                };
            })
        );

        function resumirEstadoDespacho(...estados: OrderItemsIn['estadoDespacho'][]) {
            return estados.reduce((resumen, estado) => {
                return { 
                    confirmado: resumen.confirmado + estado.confirmado,
                    distribucion: resumen.distribucion + estado.distribucion,
                    pendiente: resumen.pendiente + estado.pendiente,
                    preparacion: resumen.preparacion + estado.preparacion,
                    fecha: estado.fecha > resumen.fecha ? estado.fecha : resumen.fecha,
                }
            });
        };

        function crearEstadoDespachoDefault(item: OrderItemsIn) {
            return {
                confirmado: 0,
                distribucion: 0,
                preparacion: 0,
                pendiente: item.TARGET_QTY,
                fecha: moment().format('YYYY-MM-DD'),
            };
        };
    }

    /**
     *
     * @param KUNNR El KUNNR del cliente (NO EL ID!!)
     */
    getPedidosByCliente(KUNNR: string, params: any = {}) {
        params = Object.assign({}, params, {
            // sort: 'DOC_DATE',
            expand: ["ultimaObservacion"].join(","),
            fields: [
                "id",
                "SALESDOCUMENTIN",
                "DOC_DATE",
                "REQ_DATE_H",
                "estadoDesc",
                "apr_cred",
                "importe_total",
                "impNetoPendiente",
                "diasAtraso",
                "ultimaObservacion",
                "KUNNR",
                "estado",
                "wfl_status",
                "estadoColor",
                "activo",
            ].join(","),
        });
        return this.clientesApi
            .of(KUNNR)
            .navigate<OrderHeaderIn>("pedidos")
            .list(params);
    }

    pagePedidosByCliente(KUNNR: string, pageRequest: PageRequest<OrderHeaderIn>, q: {activo: boolean}, params: any = {}) {
        if (q.activo) {
            params['OrderHeaderInSearch[activo]'] = 1;
        }

        if (pageRequest.size) {
            params['OrderHeaderInSearch[ultimos]'] = pageRequest.size;
        }

        params = Object.assign({}, params, {
            // sort: 'DOC_DATE',
            expand: ["ultimaObservacion"].join(","),
            fields: [
                "id",
                "SALESDOCUMENTIN",
                "DOC_DATE",
                "REQ_DATE_H",
                "estadoDesc",
                "apr_cred",
                "importe_total",
                "impNetoPendiente",
                "diasAtraso",
                "ultimaObservacion",
                "KUNNR",
                "estado",
                "wfl_status",
                "estadoColor",
                "activo",
            ].join(","),
        });

        return this.clientesApi.of(KUNNR).navigate<OrderHeaderIn>('pedidos').page(pageRequest, params);
    }

    getPedidosByVendedor(PERNR: string): Observable<Group<OrderHeaderIns>> {
        let url = URLHelper.concat(
            environment.API_BASE_URL,
            "vendedores",
            PERNR,
            "pedidos"
        );
        // let expand = PEDIDOS_LIST_EXPAND_PARAM.filter(x => !['pRICELIST'].includes(x)).join(',');
        let expand = [
            ...PEDIDOS_LIST_EXPAND_PARAM.filter((x) => x !== "pRICELIST").map(
                (x) => `orderHeaderIns.${x}`
            ),
        ].join(",");
        return this.http
            .get<Partial<Cliente>>(url, { params: { expand } })
            .pipe(map((x) => new Group("KUNNR", x.KUNNR, x.NAME, x.orderHeaderIns)));
    }

    getPedidosAprobacion() {
        return this.api.navigate<OrderHeaderIn>("/aprobacion").list({
            expand: PEDIDOS_LIST_EXPAND_PARAM.join(","),
        });
    }

    createPedido(pedido: OrderHeaderIn): Observable<OrderHeaderIn> {
        return this.api.create(pedido, { expand: PEDIDOS_DETAIL_EXPAND_PARAM });
    }

    updatePedido(pedido: OrderHeaderIn): Observable<OrderHeaderIn> {
        if (!pedido.id) {
            return throwError(new Error("No se puede actualizar un pedido sin id"));
        }

        return this.api.update(pedido.id, pedido, { expand: PEDIDOS_DETAIL_EXPAND_PARAM });
    }

    resolverAutorizacionPedido(id: number | string, resolucion: { result: boolean; comment: string }) {
        return this.api
            .of(id)
            .navigate<OrderHeaderIn>("autorizar")
            .create(resolucion, { expand: PEDIDOS_DETAIL_EXPAND_PARAM });
    }

    /**
     * @deprecated utilice el componente app-badge-estado en su lugar
     */
    getEstadoClass(p: OrderHeaderIn) {
        const LEVELS = {
            "0": { "wflpedido/creado": "secondary", "wflpedido/rechazado": "danger" },
            "1": { "*": "warning" },
            "2": { "wflpedido/factparcial": "info", "*": "success" },
            "21": { "*": "success" },
            "90": { "*": "danger" },
        };

        return LEVELS[p.estado][p.wfl_status] || LEVELS[p.estado]["*"] || "default";
    }

    /**
     * @deprecated utilice el componente app-badge-estado en su lugar
     */
    estadoToString(p: Pedido): string {
        return p.estadoDesc || "Desconocido";
    }

    resolverCentroSuministro(pedido: OrderHeaderIn) {
        if (pedido.orderItems && pedido.orderItems.length) {
            // como es el mismo para todos los items, agarro el del primero:
            return pedido.orderItems[0].PLANT;
        }
        // sino no devuelvo nada (undefined)
    }

    anularPedido(id: number, motivo: string) {
        return this.api
            .of(id)
            .navigate("/anular")
            .create({ motivo }, { expand: this.getNavigationProperties() });
    }

    orderItemsInToMaterialPrVtas(orderItemsIn: OrderItemsIn): Partial<MaterialPrVtas> {
        let conditions = orderItemsIn.orderConditionsIns;
        /* let condition = orderItemsIn.orderConditionsIns
                .filter(x => !['PLIS', 'ZACC', 'Z001', 'Z002'].includes(x.COND_TYPE))[0]; */
        let condition = conditions.find((x) => x.COND_TYPE == "K007");
        let combo = conditions.find((x) => x.COND_TYPE == "COMB");

        let plist = conditions && conditions.find((x) => x.COND_TYPE == "PLIS");

        var item: Partial<MaterialPrVtas> = {
            MATNR: orderItemsIn.MATERIAL,
            mATNR: orderItemsIn.mATERIAL,
            CANTIDAD: orderItemsIn.TARGET_QTY || 0,
            UNIDAD: orderItemsIn.TARGET_QU, // item.mATNR.MEINS;
            unidad_label: orderItemsIn.tARGETQU.descripcion,
            KBETR: plist ? plist.COND_VALUE : undefined,
            CONDITION: condition ? condition.conditionType : null,
            COND_TYPE: condition ? condition.COND_TYPE : "",
            DESCUENTO: condition ? condition.COND_VALUE : 0,
            DESCUENTO_COMBO: combo ? combo.COND_VALUE : 0,
            retorno: orderItemsIn.retorno,
            descuentoPorAcuerdos: orderItemsIn.descuentoPorAcuerdos,
            cantidadBonificada: orderItemsIn.cantidadBonificada,
            bonificacionAnulada: orderItemsIn.itemEntregaGratuita && !!orderItemsIn.itemEntregaGratuita.ABGRU,
            STATUS: orderItemsIn.STATUS,
            ABGRU: orderItemsIn.ABGRU,
            estadoDespacho: orderItemsIn.estadoDespacho,
            estadoLogistico: orderItemsIn.estadoLogistico,
            bonifica_canal: Boolean(orderItemsIn.bonifica_canal),
        };
        return item;
    }

    tieneCombosNoVerificados(pedido: OrderHeaderIn) {
        return pedido.condicionVarianteOrderHeaderIns.some((i) => {
            return i.valida === 0;
        });
    }

    tieneCombosTodosVerificados(pedido: OrderHeaderIn) {
        return pedido.condicionVarianteOrderHeaderIns.some((i) => {
            return i.valida === 0;
        });
    }

    async prepararPedido(data: any, pedido?: OrderHeaderIn) {
        const warning = (mensaje: string) => this.notiService.warning(mensaje, "Pedido", true);

        if (!data.condicionPago) {
            warning("Debe seleccionar una forma de pago");
            return;
        }

        if (!data.direccion_entrega || !data.direccion_entrega.PARTN_NUMB) {
            warning("No se ha seleccionado una dirección de entrega");
            return;
        }

        if (!data.fechaRequerida.value) {
            warning("Por favor, seleccione una fecha requerida de entrega");
            return;
        }

        const cumpleFechaMinima = !data.fechaRequerida.errors ||
            !data.fechaRequerida.errors.minDate;

        if (!cumpleFechaMinima) {
            warning(data.fechaRequerida.errors.minDate.value);
            return;
        }

        if (!pedido) {
            if (!data.cliente) {
                warning("No se ha seleccionado cliente para el pedido");
                return;
            }

            if (!data.selDatoCom) {
                warning("No se ha seleccionado un Área de Ventas");
                return;
            }

            pedido = <OrderHeaderIn>{
                DOC_TYPE: "TA",
                KUNNR: data.cliente.KUNNR,
                CURRENCY: data.currency || "ARS",
                DISTR_CHAN: data.selDatoCom.VTWEG,
                DIVISION: data.selDatoCom.SPART,
                SALES_ORG: data.selDatoCom.VKORG,
                ultimaObservacion: !!data.ultimaObservacion?.OBSERVACION 
                    ? data.ultimaObservacion
                    : undefined,
            }
        }

        pedido = Object.assign({}, pedido, {
            PMNTTRMS: data.condicionPago,
            direccion_entrega: data.direccion_entrega,
            REQ_DATE_H: formatDate(data.fechaRequerida.value, 'yyyy-MM-dd', 'es-AR'),
            PURCH_DATE: pedido.PURCH_DATE ?? formatDate(new Date(), 'yyyy-MM-dd', 'es-AR'),
            PURCH_NO_C: data.ordencompra ?? undefined,
            INCOTERMS1: data.INCOTERMS1,
            entregaGratuita: data.entregaGratuita ?? undefined,
            accionesSecundariasManuales: data.accionesSecundariasManuales ?? undefined,
            confirmado: data.confirmado,
        });

        const createLabel = (m: MaterialPrVtas) => `${m.MATNR}-${m.mATNR.MAKTX}`;

        const items = data.listaMateriales.filter((item: MaterialPrVtas) => item.CANTIDAD > 0)

        for (let item of items) {
            if (!item.UNIDAD) {
                const msg = `No se ha seleccionado unidad de medida para el material ${createLabel(item)}`;
                this.notiService.error(msg, "Error");
                return;

            }

            if (!this.lpService.cantidadEsValida(item, item.UNIDAD, item.CANTIDAD)) {
                const msg = `La cantidad seleccionada para el material ${createLabel(item)} es inválida`;
                this.notiService.error(msg, "Error");
                return;
            }
        }

        const itemsPedido = new Map<string, OrderItemsIn>(pedido.orderItems
            ?.map(item => [ item.MATERIAL, item ])
        );

        pedido.orderItems = items.map((m: MaterialPrVtas) => {
            var item = itemsPedido.get(m.MATNR) 
                ?? new PedidoItem({ MATERIAL: m.MATNR }); 

            item.PLANT = m.mATNR.DWERK;
            item.TARGET_QU = m.UNIDAD;
            item.TARGET_QTY = m.CANTIDAD;
            item.bonifica_canal = m.bonifica_canal

            var descuento = item.orderConditionsIns?.[0];

            if (!m.CONDITION || m.DESCUENTO <= 0) {
                item.orderConditionsIns = [];
                return item;
            }
            
            if (!descuento) {
                descuento = new Descuento();
                item.orderConditionsIns.push(descuento);
            }

            descuento.COND_TYPE = m.CONDITION.COND_TYPE;
            descuento.COND_VALUE = m.DESCUENTO;
            
            return item;
        });

        if (pedido.orderItems.length < 1) {
            warning("No hay items seleccionados para el pedido");
            return;
        }

        if (data.confirmado) {
            const esDescuentoAdicional = (x: OrderConditionsIn) =>
                !['PLIS', 'COMB'].includes(x.COND_TYPE);

            const tieneDescuentoAdicional = (x: OrderItemsIn) =>
                x.orderConditionsIns.some(y => esDescuentoAdicional(y));


            if (pedido.orderItems.some(x => tieneDescuentoAdicional(x))) {
                let continuar = await this.notiService.confirm(
                    "Los descuentos adicionales requieren Aprobación Comercial ¿Desea continuar?",
                    "Pedido"
                );
                if (!continuar) {
                    return;
                }
            }

            const { PESO_MINIMO } = data.selDatoCom.vTWEG;
            const pesoPedido = this.lpService.getPesoTotal(data.listaMateriales);

            const cumplePesoMinimo = !PESO_MINIMO || pesoPedido >= PESO_MINIMO;

            if (!cumplePesoMinimo) {
                let continuar = await this.notiService.confirm(
                    `El pedido no alcanza el peso mínimo ${PESO_MINIMO} kg y deberá pasar a Aprobación Comercial. ¿Desea continuar?`,
                    "Pedido"
                );
                if (!continuar) {
                    return;
                }
            }
        }

        return pedido;
    }
}
