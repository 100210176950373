import { NgModule } from '@angular/core';
import { SharedModule } from 'src/app/shared.module';
import { CondicionComercialReglasDescuentoComponent } from './widgets/cc-reglas-descuento.component';
import { CondicionComercialAplicadaComponent } from './aplicadas/condicion-comercial-aplicada.component';
import { CondicionesComercialesAplicadasComponent } from './aplicadas/condiciones-comerciales-aplicadas.component';
import { CondicionComercialDisponibleComponent } from './disponibles/condicion-comercial-disponible.component';
import { WidgetsModule } from 'src/app/widgets/widgets.module';
import { CondicionesComercialesDisponiblesComponent } from './disponibles//condiciones-comerciales-disponibles.component';
import { SelectorVariantesComponent } from './widgets/cc-selector-variantes.component';
import { AccionSecundariaComponent } from './widgets/cc-accion-secundaria.component';
import { MetricaReglaComponent } from './widgets/cc-regla-metrica.component';
import { AccionSecundariaContenidoModal } from './widgets/cc-as-modal-contenido';
import { AccionesManualesComponent } from './acciones-manuales/acciones-manuales.component';
import { EditorAccionManualComponent } from './acciones-manuales/editor-accion-manual.component';
import { CondicionComercialReglasEntregaComponent } from './widgets/cc-reglas-entrega.component';
import { CondicionComercialAccionesComboComponent } from './widgets/cc-acciones-combo.component';
import { CondicionComercialAccionesEntregaComponent } from './widgets/cc-acciones-entrega.component';
import { CondicionComercialModalAccionesComponent } from './widgets/cc-modal-acciones.component';

@NgModule({
    declarations: [
        AccionesManualesComponent,
        AccionSecundariaContenidoModal,
        AccionSecundariaComponent,
        CondicionComercialReglasEntregaComponent,
        CondicionComercialReglasDescuentoComponent,
        CondicionComercialAplicadaComponent,
        CondicionesComercialesAplicadasComponent,
        CondicionComercialAccionesComboComponent,
        CondicionComercialAccionesEntregaComponent,
        CondicionComercialDisponibleComponent,
        CondicionesComercialesDisponiblesComponent,
        EditorAccionManualComponent,
        SelectorVariantesComponent,
        CondicionComercialModalAccionesComponent,
        MetricaReglaComponent
    ],
    imports: [
        SharedModule,
        WidgetsModule,
    ],
    exports: [
        AccionesManualesComponent,
        AccionSecundariaContenidoModal,
        CondicionesComercialesAplicadasComponent,
        CondicionesComercialesDisponiblesComponent
    ]
})
export class CondicionesComercialesModule {}