import { AbstractControl, ValidatorFn } from '@angular/forms';

export class Restrictors {
    /**
     * Restringe el valor de un FormControl.
     * 
     * @param maxValue Valor máximo del control
     */
    static max (maxValue: number): ValidatorFn {
        return (control: AbstractControl) => {
            let maxIsInteger = (maxValue === Math.floor(maxValue));
            if (control.value && (control.value > maxValue 
                || (!maxIsInteger && control.value == `${maxValue}.`))) 
            {
                control.setValue(maxValue);
            }
            return null;
        }
    }

    /**
     * Restringe la cantidad de decimales a la cantidad máxima especificada.
     * 
     * @param places Cantidad máxima de decimales del input
     */
    static decimal(places: number): ValidatorFn {
        return (control: AbstractControl) => {
            if (control.value && control.value !== Math.floor(control.value)) {
                let [iPart, dPart] = control.value.toString().split('.');
                if (dPart && dPart.length > places) {
                    control.setValue(control.value.toFixed(places));
                }
            }
            return null;
        }
    }

    static integer(control: AbstractControl): null {
        const value = parseFloat(control.value);
        if (!isNaN(value) && Math.floor(value) !== value) {
            control.setValue(Math.floor(value));
        }
        return null;
    }
    
}