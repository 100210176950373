<div class="main-card mb-3 card">
    <div class="card-body">
        <div class="row">
            <div class="col">
                <div class="widget-content p-0">
                    <div class="widget-content-wrapper pl-1">
                        <div class="widget-content-left mr-2">
                            <div class="widget-heading">
                                <ng-container *ngIf="condicion.nombre">
                                    {{ condicion.nombre }}
                                </ng-container>
                                <ng-template #sinNombre>
                                    <i class="text-muted">(sin nombre)</i>
                                </ng-template>
                            </div>
                            <div class="widget-subheading">
                                {{ condicion.descripcion }}
                            </div>
                        </div>
                        <div class="widget-content-center flex-grow-1 px-2">
                            <app-cc-selector-variantes 
                                [variantes]="condicion.condicionVariantes" 
                                [(seleccionada)]="variante"
                                [readonly]="false">
                            </app-cc-selector-variantes>
                        </div>
                        <div class="widget-content-right">
                            <button class="btn btn-success" [disabled]="!botonAgregarHabilitado"
                                (click)="onSelect()">
                                Agregar
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row py-2" *ngIf="variante">
            <div class="col justify-content-center">
                <app-cc-accion-secundaria 
                    [acciones]="accionesSecu"
                    [ocultarAcordado]="true"
                    *ngIf="secundarias as accionesSecu">
                </app-cc-accion-secundaria>
                <ng-container *ngIf="variante.condicionVarianteCombos[0] as combo">
                    <app-cc-acciones-combo
                        titulo="Descuentos"
                        [acciones]="acciones"
                        *ngIf="combo.condicionVarianteComboAccions as acciones">
                    </app-cc-acciones-combo>
                </ng-container>
            </div>
        </div>
    </div>
</div>