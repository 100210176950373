import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { of } from "rxjs";
import { catchError, map, tap } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { TimelineEventLevel } from "../widgets/timeline/models/TimelineEventLevel.enum";
import { URLHelper } from "./url-helper.helper";

@Injectable({providedIn: 'root'})
export class EventsService {
    constructor(private http: HttpClient) {}

    fetch(id: string|number) {
        let url = URLHelper.concat(environment.API_BASE_URL, `/api/pedidoevs?id=${id}`);

        let levels = [
            TimelineEventLevel.INFO,
            TimelineEventLevel.SUCCESS,
            TimelineEventLevel.WARNING,
            TimelineEventLevel.DANGER
        ];
        
        return this.http.get<any[]>(url).pipe(
            tap(data => {
                if (!Array.isArray(data)) {
                    throw new Error('No se pudo cargar la bitácora del pedido');
                }
            }),
            map(data => data.map((d) => ({
                title: (d.es_workflow ? `WORKFLOW: ${d.descripcion}` : d.order_estado),
                description: (d.es_workflow ? `${d.labelEstadoInicial || ''} → ${d.labelEstadoFinal || ''}` : d.descripcion),
                level: (levels[d.nivel] || TimelineEventLevel.INFO),
                timestamp: (d.FECHA_CREACION * 1000).toString(),
                observaciones: d.observaciones
            }))),
            catchError((err) => of([
                {
                    title: 'Error',
                    description: err.message || 'No se pudo cargar la bitácora del pedido',
                    level: TimelineEventLevel.DANGER,
                    timestamp: new Date(),
                    observaciones: ''
                }
            ]))
        );
    }
}