import { Component } from '@angular/core';
import { UserService } from 'src/app/user.service';
import { ThemeOptions } from '../../theme-options';

@Component({
  selector: 'app-base-layout',
  templateUrl: './base-layout.component.html',
  animations: [

    /* trigger('architectUIAnimation', [
      transition('* <=> *', [
        query(':enter, :leave', [
          style({
            opacity: 0,
            display: 'flex',
            flex: '1',
            transform: 'translateY(-20px)',
            flexDirection: 'column'

          }),
        ]),
        query(':enter', [
          animate('600ms ease', style({opacity: 1, transform: 'translateY(0)'})),
        ]),

        query(':leave', [
          animate('600ms ease', style({opacity: 0, transform: 'translateY(-20px)'})),
         ], { optional: true })
      ]),
    ]) */
  ]
})

export class BaseLayoutComponent {

  // @select('config')

  get currentUser () {
    return this.userService.currentUser;
  }

  get containerNgClass() {
    return {
      'closed-sidebar' : this.globals.toggleSidebar,
      'closed-sidebar-md' : this.globals.toggleSidebarMobile,
      'settings-open' : this.globals.toggleThemeOptions,
      'closed-sidebar-open': this.globals.sidebarHover || this.globals.toggleSidebarMobile,
      'header-menu-open' : this.globals.toggleHeaderMobile,
      'drawer-open' : this.globals.toggleDrawer,
      'fixed-footer' : this.globals.toggleFixedFooter
      }
  }

  constructor(public globals: ThemeOptions, private userService: UserService) {
  }

  toggleSidebarMobile() {
    this.globals.toggleSidebarMobile = !this.globals.toggleSidebarMobile;
  }

  
}



