<form [formGroup]="form">
  <div class="position-relative row form-group">
    <div class="col-md-6 col-sm-12">
      <label for="seldatocom">Area de Ventas</label>
      <ng-select
        *ngIf="edit && esNuevo; else datoComView"
        id="seldatocom"
        [items]="datosComerciales"
        bindLabel="AREA_VENTAS_FULLNAME"
        placeholder="Seleccione un Area de Venta..."
        formControlName="selDatoCom"
      >
      </ng-select>
      <ng-template #datoComView>
        <input
          type="text"
          class="form-control"
          [readonly]="true"
          [value]="selDatoCom?.value?.AREA_VENTAS_FULLNAME"
        />
      </ng-template>
    </div>
    <div class="col-md-6 col-sm-12">
      <label for="listaprecios">Lista de Precios</label>
      <input
        [readonly]="true"
        name="listaprecios"
        type="text"
        class="form-control"
        [value]="selDatoCom?.value?.listaPrecios?.PTEXT"
      />
    </div>
  </div>
  <div class="position-relative row form-group">
    <div *ngIf="vendedor" class="col-md-3 col-sm-12">
      <label for="ordencompra">Orden de Compra</label>
      <input
        type="text"
        class="form-control"
        id="ordencompra"
        formControlName="ordencompra"
        maxlength="35"
        [readonly]="!edit"
      />
    </div>
    <div *ngIf="!vendedor" class="col-md-6 col-sm-12">
      <label for="ordencompra">Orden de Compra</label>
      <input
        type="text"
        class="form-control"
        id="ordencompra"
        formControlName="ordencompra"
        maxlength="35"
        [readonly]="!edit"
      />
    </div>
    <div *ngIf="vendedor" class="col-md-3 col-sm-12">
      <label for="vendedor">Vendedor</label>
      <input
        [readonly]="true"
        name="vendedor"
        type="text"
        class="form-control"
        [value]="vendedor"
      />
    </div>
    <div class="col-md-4 col-sm-12">
      <label for="formapago">Forma de Pago</label>
      <app-condiciones-pago
        id="formapago"
        [editable]="edit"
        [datoComercial]="selDatoCom.value"
        [control]="condicionPago"
      >
      </app-condiciones-pago>
    </div>
    <div class="col-md-2 col-sm-12">
      <label for="fechaRequerida">Fecha de Entrega</label>
      <input
        name="fechaRequerida"
        [readonly]="!edit"
        formControlName="fechaRequerida"
        [min]="TODAY"
        type="date"
        class="form-control"
      />
    </div>
  </div>
</form>
