import { Component, Input } from "@angular/core";
import { IMetrica } from '../condiciones.models';

@Component({
    selector: 'app-cc-metrica-regla',
    template: `
    <span [ngClass]="{'text-danger': !m.valida_aplicacion}" *ngIf="metrica as m">
        {{  m.valor_aplicado | numberOrUndefined:'1.2-2':'' }} {{ m.op_aritmetico }} {{ m.valor | numberOrUndefined:'1.2-2' }}
    </span>
    `
})
export class MetricaReglaComponent {
    @Input() metrica: IMetrica;
}