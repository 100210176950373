<div class="card">
    <div class="card-header">Datos</div>
    <div class="card-body">
        <ng-container *ngIf="cliente; else noData">
            <form>
                <div class="form-row">
                    <div class="col-md-8">
                        <div class="position-relative form-group">
                            <label for="address">Dirección</label>
                            <input name="address" type="text" class="form-control text-uppercase" [readonly]="true"
                                [value]="cliente.STRAS">
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="position-relative form-group"><label for="tel">Código Postal</label>
                            <input name="tel" [readonly]="true" [value]="cliente.POSTL_COD1" class="form-control">
                        </div>
                    </div>
                </div>
                <!-- <div class="position-relative form-group"><label for="exampleAddress2">Dirección 2</label><input
                name="address2" id="exampleAddress2" type="text" [readonly]="true" [value]="cliente.STRAS"
                class="form-control"></div> -->
                <div class="form-row">
                    <div class="col-md-4">
                        <div class="position-relative form-group"><label for="state">Ciudad</label><input name="city"
                                [readonly]="true" [value]="cliente.CITY" type="text" class="form-control text-uppercase">
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="position-relative form-group"><label for="prov">Provincia</label><input name="prov"
                                [readonly]="true" [value]="cliente.PROVINCIA" type="text"
                                class="form-control text-uppercase">
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="position-relative form-group"><label for="state">País</label><input name="state"
                                [readonly]="true" [value]="cliente.COUNTRY" type="text" class="form-control text-uppercase">
                        </div>
                    </div>
                </div>
                <div class="form-row">
                    <div class="col-md-4">
                        <div class="position-relative form-group"><label for="tel">Teléfono</label><input name="tel"
                                [readonly]="true" [value]="cliente.TEL1_NUMBR" class="form-control text-uppercase">
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="position-relative form-group">
                            <label for="email">E-Mail</label>
                            <app-cliente-emails-modal (create)="createEmail.emit($event)" [cliente]="cliente"></app-cliente-emails-modal>
                        </div>
                    </div>

                    <div class="col-md-4">
                        <div class="position-relative form-group"><label for="cuit">CUIT</label><input name="cuit"
                                [readonly]="true" [value]="cliente.STCD1" class="form-control text-uppercase">
                        </div>
                    </div>
                </div>
            </form>
        </ng-container>
        <ng-template #noData>
            <p>Sin datos disponibles</p>
        </ng-template>
    </div>
</div>