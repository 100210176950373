import { Component, Input } from "@angular/core";

import { NgbModal, ModalDismissReasons } from "@ng-bootstrap/ng-bootstrap";
import { Tag } from "../lista-precios/models/MaterialBasico.model";

@Component({
  selector: "app-tags-modal",
  templateUrl: "./tags-modal.component.html",
})
export class TagsModalComponent {
  closeResult = "";

  @Input() tags: Array<Tag>;
  constructor(private modalService: NgbModal) {}

  open(content) {
    this.modalService.open(content, { size: "sm" }).result.then(
      (result) => {
        this.closeResult = `Closed with: ${result}`;
      },
      (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      }
    );
  }

  colores() {
    let col = this.tags.map((tag) => {
      return tag.color;
    });
    let colores = col.join(",");
    return colores;
    // colores = colores.replace(/,\s*$/, "");
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return "by pressing ESC";
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return "by clicking on a backdrop";
    } else {
      return `with: ${reason}`;
    }
  }
}
