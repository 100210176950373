import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { EMPTY, Observable, throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import { UserService } from "../user.service";
import { NotificationService } from "../utils/notifications/notification.service";

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(
    private router: Router,
    private notiService: NotificationService,
    private userService: UserService
  ) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    // Clone the request to add the new header.
    //const authReq = req.clone({headers: req.headers.set(Cookie.tokenKey, Cookie.getToken())});
    let authReq = req.clone();
    let itoken = JSON.parse(localStorage.getItem("impersonate"));
    if (itoken !== null) {
      itoken = JSON.parse(localStorage.getItem("impersonate")).auth_token;
    }
    let myToken = localStorage.getItem("tbearer");
    let tengoToken =
      myToken !== null && myToken !== undefined && myToken !== "";
    let estoyImpersonado =
      itoken !== null && itoken !== undefined && itoken !== "";
    if (tengoToken) {
      if (estoyImpersonado) {
        authReq = req.clone({
          setHeaders: {
            Authorization: "Bearer " + itoken,
          },
        });
      } else {
        authReq = req.clone({
          setHeaders: {
            Authorization: "Bearer " + myToken,
          },
        });
      }
    }
    // else {
    //   this.userService.currentUser = null;
    //   this.userService.goToLoginPage();
    // }

    // catch the error, make specific functions for catching specific errors and you can chain through them with more catch operators
    return next
      .handle(authReq)
      .pipe(catchError((x) => this.handleAuthError(x))); //here use an arrow function, otherwise you may get "Cannot read property 'navigate' of undefined" on angular 4.4.2/net core 2/webpack 2.70
  }

  private handleAuthError(err: HttpErrorResponse): Observable<any> {
    //handle your auth error or rethrow
    if (err.status === 401) {
      //UNAUTHORIZED
      //navigate /delete cookies or whatever
      this.userService.currentUser = null;
      this.userService.goToLoginPage();
      // if you've caught / handled the error, you don't want to rethrow it unless you also want downstream consumers to have to handle it as well.
      // return of(err.message); // or EMPTY may be appropriate here
      return EMPTY;
    }
    if (err.status === 403) {
      //FORBIDDEN
      if (err.error) {
        this.notiService.error(
          err.error.message || "No tiene permiso para ejecutar esta acción",
          "Error"
        );
      }
      // return from(this.router.navigate(["/"])).pipe(
      //     mapTo(err.message)
      // );
    }
    return throwError(err);
  }
}
