import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { URLHelper } from './../utils/url-helper.helper';
import { Situacion } from './models/situacion.model';


@Injectable({
    providedIn: 'root'
})
export class SituacionService {

    constructor(private http: HttpClient) {
    }

    getSituaciones(): Observable<Situacion[]> {
        return this.http.get<Situacion[]>(
            URLHelper.concat(environment.API_BASE_URL, 'api', 'situacion')
        );
    }

    getSituacion(pernr: string): Observable<Situacion> {
        let url = URLHelper.concat(environment.API_BASE_URL, 'vendedores', pernr, 'situacion');
        return this.http.get<Situacion>(url);
    }
    
}
