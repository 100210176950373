import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BehaviorSubject, Subscription } from 'rxjs';
import { ClientesService } from '../../clientes/clientes.service';
import { Interlocutor } from '../../clientes/models/Cliente.model';
import { UserService } from '../../user.service';
import { CondicionesEntrega, Pedido } from '../models/Order.model';
import { PedidosService } from '../pedidos.service';

@Component({
    selector: 'app-pedidos-footer',
    templateUrl: './pedidos-footer.component.html',
})
export class PedidosFooterComponent implements OnInit {
    private subscriptions: Subscription[] = [];

    @Input() pedido: Pedido;
    @Input() onEdit: boolean;
    @Input() puedeConfirmar: boolean;

    @Input() cliente: any;

    @Input() datoComercial: any;
    @Input() progressGuardar: boolean = false;
    @Input() progressConfirmar: boolean = false;
    @Input() interlocutor: Interlocutor;

    @Output() onAutorizarPedido: EventEmitter<any> = new EventEmitter();
    @Output() onGuardarPedido: EventEmitter<any> = new EventEmitter();
    @Output() onConfirmarPedido: EventEmitter<any> = new EventEmitter();
    @Output() onAnularPedido: EventEmitter<any> = new EventEmitter();
    @Output() closeCombos: EventEmitter<any> = new EventEmitter();

    @Input() condicionPago:string;
    @Output() condicionPagoChange: EventEmitter<string> = new EventEmitter();

    get userCanEdit$() { return this.service.userCanEdit$; }
    get userCanAuthorize$() { return this.service.userCanAuthorize$; }

    get acuerdos()
    {
        if (!this.cliente || !this.cliente.acuerdos) {
            return null
        }
        if (this.datoComercial) {
            return this.cliente.acuerdos.filter(x =>
                [null, this.datoComercial.VKORG].includes(x.VKORG)
                && [null, this.datoComercial.VTWEG].includes(x.VTWEG)
            );
        }
        return this.cliente.acuerdos;
    }

    @Output() condicionesEntregaChange = new EventEmitter<CondicionesEntrega>();

    constructor(private service: PedidosService, private userService: UserService, private clientesService: ClientesService) { }

    ngOnInit() {
    }

    ngOnDestroy() {
        this.subscriptions.forEach(s => s.unsubscribe());
    }

    fnOnGuardarPedido($event): void {
        this.onGuardarPedido.emit([$event]);
    }

    fnOnConfirmarPedido($event): void {
        this.onConfirmarPedido.emit([$event]);
    }

    fnOnAnularPedido($event): void {
        this.onAnularPedido.emit([$event]);
    }

    fnOnAutorizarPedido($event): void {
        this.onAutorizarPedido.emit($event);
    }

    onCloseCombos($event) {
        this.closeCombos.emit($event);
    }

    onSetCondicionesEntrega($event) {
        this.condicionesEntregaChange.emit($event);
    }
}
