import { EventEmitter } from '@angular/core';

export class Group<T> {
    private _collapsed: boolean = true;

    get collapsed() { 
        return this._collapsed; 
    }
        
    set collapsed(value: boolean) { 
        this._collapsed = value; 
        this.collapsedChange.emit(this.collapsed);
    }

    collapsedChange: EventEmitter<boolean> = new EventEmitter();

    data: (T | Group<T>)[];

    loading: boolean;

    userdata: any;

    constructor(public field: string, public value: string | number, public label: string = "", data?: T[] | Group<T>[]) {
        this.data = data || [];
    }

    get length(): number {
        return this.data.length;
    }

    push(...values: T[])
    push(...values: Group<T>[])
    push(...values: (T | Group<T>)[]) {
        this.data.push(...values);
    }

    setCollapse(value: boolean) {
        this.collapsed = value;
    }

    toggleCollapse() {
        this.collapsed = !this.collapsed;
    }


}