<!-- <div class="fixed-footer"> -->
  <div class="app-footer">
    <div class="app-footer__inner">
      <!-- <div class="app-footer-left">
    </div> -->
      <div class="app-footer-right">
        <div class="row">
          <div *ngIf="!onEdit && pedido?.estado == 1 && (userCanAuthorize$|async)" class="col">
            <button app-autorizacion-modal title="Resolver Autorización de Pedido" class="btn btn-primary btn-hover-shine text-nowrap" (resolve)="fnOnAutorizarPedido($event)">
              Resolver Autorización
            </button>
          </div>
          <div class="col">
            <!-- <app-condiciones [edit]="onEdit" (onClose)="onCloseCombos($event)"></app-condiciones> -->
            <app-cc-disponibles [edit]="onEdit && pedido?.estado < 1" (close)="onCloseCombos($event)"></app-cc-disponibles>
          </div>
          <div class="col">
            <app-condiciones-entrega
              [interlocutor]="interlocutor"
              [incoterm]="pedido.INCOTERMS1"
              [cliente]="cliente"
              [datoComercial]="datoComercial"
              [editable]="onEdit && pedido?.estado < 1"
              (save)="onSetCondicionesEntrega($event)">
            </app-condiciones-entrega>
          </div>
          <div class="col">
            <button *ngIf="acuerdos" app-acuerdos-modal [data]="acuerdos" class="btn btn-hover-shine btn-primary text-nowrap">Acuerdos</button>
          </div>
          <div class="col" *ngIf="!onEdit && (pedido?.estado === undefined || pedido?.estado <= 2)">
            <button class="btn-hover-shine btn btn-danger" (click)="fnOnAnularPedido($event)">
              Anular
            </button>
          </div>
          <div *ngIf="(onEdit && ((pedido?.estado === undefined) || (pedido?.estado !== 90)))" class="col">
            <button class="btn btn-success" [disabled]="!onEdit || !(userCanEdit$|async) || progressGuardar" (click)="fnOnGuardarPedido($event)">
              Guardar<span [class.spinner]="progressGuardar"></span>
            </button>
          </div>
          <div *ngIf="puedeConfirmar" class="col">
            <button class="btn btn-primary" [disabled]="onEdit || !(userCanEdit$|async) || progressConfirmar" (click)="fnOnConfirmarPedido($event)">
              Confirmar<span [class.spinner]="progressConfirmar"></span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>

<!-- </div> -->
