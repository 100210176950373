// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.


export const environment = {
    production: false,
    API_BASE_URL: 'https://lhtrfront.dev.corsisa.com.ar/',
    DEFAULT_HTTP_OPTIONS: {
        withCredentials: true,
        setParams: {
            "XDEBUG_SESSION_START": "netbeans-xdebug"
        }
    },
     HOME_URL:'https://portal-lhtr.dev.corsisa.com.ar',
    NXT_BASE_URL: 'https://nxt.corsisa.com.ar',
    NXT_BASE_DAV_PEDIDOS: '/remote.php/webdav/corsisa_dev/Lheritier/test_pedidos_portal',
    LOGISTICA_HREF: 'https://lhtr2.dev.corsisa.com.ar',
    LOGOPS_HREF: 'https://logops.test.corsisa.com.ar',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
