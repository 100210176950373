<ng-container *ngIf="selectedSituacion$ | async as s; else notFound">
  <div class="app-page-title">
    <div class="page-title-wrapper">
      <div class="page-title-heading">
        <div>
          Gestión Operativa
          <div class="page-title-subheading">
            Vendedor: {{ s.sn + ' ' + s.cn}}
          </div>
        </div>
      </div>
    </div>
  </div>
  <app-situacion-vendedor [situacion]="s"></app-situacion-vendedor>
</ng-container>
<ng-template #notFound>
  <div class="app-page-title">
    <div class="page-title-wrapper">
      <div class="page-title-heading">
        <div>
          Gestión Operativa
        </div>
      </div>
    </div>
  </div>
  <div class="main-card mb-3 card">
    <div class="card-body">
      <p>No se encontró el dato solicitado</p>
    </div>
  </div>
</ng-template>