<div class="card">
    <div class="card-header">
        Crédito
    </div>
    <div class="card-body">
        <ng-container *ngIf="data; else noCreditData">
            <div class="widget-content">
                <div class="widget-content-outer">
                    <div class="widget-content-wrapper">
                        <div class="widget-content-left">
                            <div class="widget-heading">Disponible</div>
                            <!-- <div class="widget-subheading">%</div> -->
                        </div>
                        <div class="widget-content-right">
                            <div class="widget-numbers text-{{ disponibleClass }}">{{ disponible | currency:'ARS' }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="widget-content">
                <div class="widget-content-outer">
                    <div class="widget-content-wrapper">
                        <div class="widget-content-left">
                            <div class="widget-heading">Pedidos Pendientes</div>
                            <!-- <div class="widget-subheading">%</div> -->
                        </div>
                        <div class="widget-content-right">
                            <div class="widget-numbers text-{{ pendientesClass }}">{{ pendientes | currency:'ARS' }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="widget-content">
                <div class="widget-content-outer">
                    <div class="widget-content-wrapper">
                        <div class="widget-content-left">
                            <div class="widget-heading">Saldo Disponible</div>
                            <!-- <div class="widget-subheading">%</div> -->
                        </div>
                        <div class="widget-content-right">
                            <div class="widget-numbers text-{{ saldoClass }}">{{ saldo | currency:'ARS' }}</div>
                        </div>
                    </div>
                </div>
            </div>
            <hr />
            <div class="widget-content">
                <div class="widget-content-outer">
                    <div class="widget-content-wrapper">
                        <div class="widget-content-left">
                            <div class="widget-heading">Grado de Agotamiento</div>
                            <!-- <div class="widget-subheading">%</div> -->
                        </div>
                        <div class="widget-content-right">
                            <div class="widget-numbers text-{{ agotamientoClass }}">
                                {{ gradoAgotamiento | percent: '1.0-2'}}</div>
                        </div>
                    </div>
                </div>
            </div>
        </ng-container>
        <ng-template #noCreditData>
            <p>Sin datos disponibles para el cliente</p>
        </ng-template>
    </div>
</div>