import { AbstractControl } from '@angular/forms';
import { formatDate } from '@angular/common';

export function minDate(date: string | number | Date, inclusive: boolean = true) {
    date = formatDate(date, 'yyyy-MM-dd', 'es-AR');
    return (control: AbstractControl): {[key: string]: any} | null => {
        if (!control.value) {
            return null;
        }
        const value = formatDate(control.value, 'yyyy-MM-dd', 'es-AR');
        var isValid = value > date;
        if (inclusive) {
            isValid = isValid || value == date
        }
        return !isValid ? {'minDate' : {value: `La fecha debe ser ${inclusive ? 'igual o' : ''} posterior a ${formatDate(date, 'dd/MM/yyyy', 'es-AR')}`}} : null;
    }
}