<ng-container *ngIf="items$ | async as items;">
    <div class="form-row row">
        <div class="col-4 p-2">
                <div class="form-group form-inline mb-2">
                    Buscar:
                    <input class="form-control ml-2" type="text" name="searchTerm" [formControl]="searchBox" />
                </div>

            </div>
        <div class="col-8 pull-right">
            <page-selector [collectionSize]="items.length"></page-selector>
        </div>
    </div>
    <div class="table-responsive">
        <ng-container *ngIf="items.length > 0; else noData">
            <table class="align-middle text-truncate mb-2 table table-borderless table-hover table-sm" style="overflow: visible;">
                <thead>
                <tr>
                    <th scope="col" class="text-right">Código</th>
                    <th scope="col" class="text-left">Descripción</th>
                    <th scope="col" class="text-right">Cantidad</th>
                    <th scope="col" class="text-right">UN</th>
                    <th scope="col" class="text-right">En Prep.</th>
                    <th scope="col" class="text-right">En Distr.</th>
                    <th scope="col" class="text-right">Entregado</th>
                    <th scope="col" class="text-right">Pendiente</th>
                    <th scope="col" class="text-right">UN</th>
                    <th scope="col" class="text-right">Importe Neto</th>
                    <th scope="col" class="text-right">Imp. Neto Pendiente</th>
                </tr>
                </thead>
                <tbody>
                <tr *ngFor="let item of (items | pagination)">
                    <td class="text-right">{{ item.MATERIAL | ltrim:'0' }}</td>
                    <td class="text-left">{{ item.mATERIAL.MAKTX }}</td>
                    <td class="text-right">{{ item.TARGET_QTY | number:'1.0-2' }}
                        <span *ngIf="item.cantidadBonificada > 0" [ngClass]="{ 'text-decoration-line-through': esBonificacionAnulada(item) }">
                            (+ {{ item.cantidadBonificada }})
                        </span>
                    </td>
                    <td class="text-right">{{ item.TARGET_QU }}</td>
                    <td class="text-right">{{ (item.estadoDespacho?.preparacion ?? 0) | number:'1.0-2' }}</td>
                    <td class="text-right">{{ (item.estadoDespacho?.distribucion ?? 0) | number:'1.0-2' }}</td>
                    <td class="text-right">{{ (item.estadoDespacho?.confirmado ?? 0) | number:'1.0-2' }}</td>
                    <td class="text-right">{{ (item.estadoDespacho?.pendiente ?? 0) | number:'1.0-2' }}</td>
                    <td class="text-right">CJ</td>
                    <td class="text-right">{{ item.importe | currency:'ARS' }}</td>
                    <td class="text-right">{{ item.impNetoPendiente | currency:'ARS' }}</td>
                </tr>
                </tbody>
            </table>
        </ng-container>
        <ng-template #noData>
            <div class="card-body">
                <p>No se encontraron resultados</p>
            </div>
        </ng-template>
    </div>
</ng-container>