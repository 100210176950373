import { pipe, UnaryFunction, Observable } from 'rxjs';
import { distinctUntilChanged, filter } from 'rxjs/operators';

export function ifNull<T>(): UnaryFunction<Observable<T>, Observable<T>> {
    return pipe(
        filter((value) => value === null)
    );
}

export function ifUndefined<T>(): UnaryFunction<Observable<T>, Observable<T>> {
    return pipe(
        filter((value) => value === undefined)
    );
}

export function ifNullOrUndefined<T>(): UnaryFunction<Observable<T>, Observable<T>> {
    return pipe(
        filter((value) => [null, undefined].includes(value))
    );
}

export function ifNotNull<T>(): UnaryFunction<Observable<T>, Observable<T>> {
    return pipe(
        filter((value) => value !== null)
    );
}

export function ifNotUndefined<T>(): UnaryFunction<Observable<T>, Observable<T>> {
    return pipe(
        filter((value) => value !== undefined)
    );
}

export function ifNotNullOrUndefined<T>(): UnaryFunction<Observable<T>, Observable<T>> {
    return pipe(
        ifNotNull(),
        ifNotUndefined()
    );
}

export function distinctUntilAnyKeyChanged<T>() {
    return pipe(
        distinctUntilChanged<T>((x, y) => {
            return Object.keys(x).length === Object.keys(y).length
                && Object.entries(x).every(([key, value]) => value === y[key])
        }),
    )
}