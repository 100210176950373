import { Component, Input } from '@angular/core';
import { IAccion } from '../condiciones.models';

@Component({
    selector: 'app-cc-acciones-combo',
    template: `
    <table class="table">
        <thead>
            <tr *ngIf="titulo">
                <th class="text-center" scope="colgroup" colspan="4">
                    <span class="card-title">{{ titulo }}</span>
                </th>
            </tr>
            <tr>
                <th scope="col">Categoría</th>
                <th scope="col">Subcategoría</th>
                <th scope="col">Producto</th>
                <th class="text-right" scope="col">Descuento</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let a of acciones">
                <td [ngClass]="{'text-muted': !a.SPART}">{{ a.sPART?.VTEXT || 'Indistinto' }}</td>
                <td [ngClass]="{'text-muted': !a.KONDM}">{{ a.kONDM?.VTEXT || 'Indistinto' }}</td>
                <td [ngClass]="{'text-muted': !a.MATNR}">{{ a.mATNR?.MAKTX || 'Indistinto' }}</td>
                <td class="text-right">{{ a.valor }} %</td>
            </tr>
        </tbody>
    </table>
    `
})
export class CondicionComercialAccionesComboComponent {
    @Input() titulo: string;
    @Input() acciones: IAccion[];
}