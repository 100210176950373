import {
    animate,
    state,
    style,
    transition,
    trigger
} from '@angular/animations';
import { Component, HostBinding } from '@angular/core';
import { Toast, ToastPackage, ToastrService } from 'ngx-toastr';


@Component({
    selector: '[confirm-dialog-component]',
    // styles: [`
    //   :host {
    //     background-color: orange;
    //     position: relative;
    //     overflow: hidden;
    //     margin: 0 0 6px;
    //     padding: 10px 10px 10px 10px;
    //     width: 300px;
    //     border-radius: 3px 3px 3px 3px;
    //     color: #FFFFFF;
    //     pointer-events: all;
    //     cursor: pointer;
    //   }
    //   .btn-pink {
    //     -webkit-backface-visibility: hidden;
    //     -webkit-transform: translateZ(0);
    //   }
    // `],
    template: `
    <button *ngIf="options.closeButton" (click)="remove()" class="toast-close-button" aria-label="Close">
        <span aria-hidden="true">&times;</span>
    </button>
    <div *ngIf="title" [class]="options.titleClass" [attr.aria-label]="title">
        {{ title }} <ng-container *ngIf="duplicatesCount">[{{ duplicatesCount + 1 }}]</ng-container>
    </div>
    <div *ngIf="message && options.enableHtml" role="alertdialog" aria-live="polite"
        [class]="options.messageClass" [innerHTML]="message">
    </div>
    <div *ngIf="message && !options.enableHtml" role="alertdialog" aria-live="polite"
        [class]="options.messageClass" [attr.aria-label]="message">
        {{ message }}
    </div>
    <div class="mt-2 clearfix">
      <button class="btn btn-warning pull-left" (click)="aceptar($event)">Aceptar</button>
      <button class="btn btn-default pull-right" (click)="cancelar($event)">Cancelar</button>
    </div>
  `,
    animations: [
        trigger('flyInOut', [
            state('inactive', style({ opacity: 0 })),
            state('active', style({ opacity: 1 })),
            state('removed', style({ opacity: 0 })),
            transition(
                'inactive => active',
                animate('{{ easeTime }}ms {{ easing }}')
            ),
            transition(
                'active => removed',
                animate('{{ easeTime }}ms {{ easing }}')
            )
        ])
    ],
    preserveWhitespaces: false
})
export class ConfirmDialogComponent extends Toast {

    @HostBinding('class') toastClasses = 'ngx-toastr toast-warning';

    // constructor is only necessary when not using AoT
    constructor(
        protected toastrService: ToastrService,
        public toastPackage: ToastPackage,
    ) {
        super(toastrService, toastPackage);
    }

    aceptar(event: Event) {
        event.stopPropagation();
        this.toastPackage.triggerAction(true);
        return false;
    }

    cancelar(event: Event) {
        event.stopPropagation();
        this.toastPackage.triggerAction(false);
        return false;
    }
}