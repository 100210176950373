export class URLHelper {
    static concat(...components: string[]): string {
        return components.map(component => {
            component = component.toString();
            while (component.startsWith("/")) {
                component = component.slice(1);
            }
            while (component.endsWith("/")) {
                component = component.slice(0, component.length - 1);
            }
            return component;
        }).join("/");
    }
}