import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GenericApiClient } from 'src/app/utils/api/generic-api-client.service';
import { URLHelper } from '../utils/url-helper.helper';

@Injectable({
    providedIn: 'root'
})
export class CatalogoFactoryService {
    private PREFIX = "catalog";
    private getUrl(uri) {
        return URLHelper.concat(this.PREFIX, uri);
    }
    private createGenericClient(uri: string) {
        return new GenericApiClient<any>(this.getUrl(uri), this.http);
    }

    constructor(private http: HttpClient) {}

    getCanalesDistribucion() {
        return this.createGenericClient("canal-distribucion");
    }

    getCondicionEntrega() {
        return this.createGenericClient("condicion-entrega");
    }

    getCondicionPago() {
        return this.createGenericClient("condicion-pago");
    }

    getConditionType() {
        return this.createGenericClient("condition-type");
    }

    getCurrency() {
        return this.createGenericClient("currency");
    }

    getDivision() {
        return this.createGenericClient("division");
    }

    getInco1() {
        return this.createGenericClient("inco1");
    }

    getListaPrecios() {
        return this.createGenericClient("lista-precios");
    }

    getMetodoPedido() {
        return this.createGenericClient("metodo-pedido");
    }

    getMotivoPedido() {
        return this.createGenericClient("motivo-pedido");
    }

    getOrganizacionVentas() {
        return this.createGenericClient("organizacion-ventas");
    }

    getUnidadMedida() {
        return this.createGenericClient("unidad-medida");
    }

    getZonaVentas() {
        return this.createGenericClient("zona-ventas");
    }
}