import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { DecimalPlacesDirective } from './directives/decimalplaces.directive';
import { TableFixedHeaderDirective } from './directives/table-fixed-header.directive';
import { GroupsModule } from './groups/groups.module';
import { NotificationsModule } from './notifications/notifications.module';
import { PaginationModule } from './pagination/pagination.module';
import { ARSPipe } from './pipes/ars.pipe';
import { DynamicPipe } from './pipes/dynamic.pipe';
import { FormatTextPipe } from './pipes/formatText.pipe';
import { EscapeHtmlPipe } from './pipes/keep-html.pipe';
import { KilosPipe } from './pipes/kilos.pipe';
import { LtrimPipe } from './pipes/ltrim.pipe';
import { NumberOrUndefinedPipe } from './pipes/number-or-undefined.pipe';
import { PercentOrUndefinedPipe } from './pipes/percent-or-undefined.pipe';
import { PluralizeIfPipe } from './pipes/pluralizeIf.pipe';
import { PropertyPipe } from './pipes/property.pipe';
import { SortableHeaderComponent } from './sortable/sortable-header.component';

@NgModule({
    declarations: [
        FormatTextPipe,
        PluralizeIfPipe,
        EscapeHtmlPipe,
        DecimalPlacesDirective,
        NumberOrUndefinedPipe,
        PercentOrUndefinedPipe,
        DynamicPipe,
        LtrimPipe,
        TableFixedHeaderDirective,
        PropertyPipe,
        KilosPipe,
        ARSPipe,
        SortableHeaderComponent,
    ],
    imports: [
        CommonModule,
        NotificationsModule,
        PaginationModule,
        GroupsModule,
    ],
    exports: [
        NotificationsModule,
        PaginationModule,
        GroupsModule,
        FormatTextPipe,
        PluralizeIfPipe,
        EscapeHtmlPipe,
        DecimalPlacesDirective,
        NumberOrUndefinedPipe,
        PercentOrUndefinedPipe,
        DynamicPipe,
        LtrimPipe,
        TableFixedHeaderDirective,
        PropertyPipe,
        KilosPipe,
        ARSPipe,
        SortableHeaderComponent
    ]
})
export class UtilsModule {}