import { NgModule } from '@angular/core';
import { SharedModule } from '../shared.module';
import { AcuerdosModalComponent } from './acuerdos-modal.component';
import { AcuerdosComponent } from './acuerdos.component';

@NgModule({
    declarations: [AcuerdosModalComponent, AcuerdosComponent],
    imports: [SharedModule],
    exports: [AcuerdosModalComponent, AcuerdosComponent]
})
export class AcuerdosModule {}