import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { retry, catchError, tap } from "rxjs/operators";

import { environment } from "../environments/environment";

import { User } from "src/app/models/User.model";
import { URLHelper } from "./utils/url-helper.helper";
import { of } from "rxjs";
import { NgxPermissionsService } from "ngx-permissions";

@Injectable({
  providedIn: "root",
})
export class UserService {
  constructor(
    private http: HttpClient,
    private permissionsService: NgxPermissionsService
  ) {}

  get currentUser(): User {
    if (this.estoyImpersonado()) {
      let ob = JSON.parse(localStorage.getItem("currentUser"));
      ob.PERNR = JSON.parse(localStorage.getItem("impersonate")).PERNR;
      ob.user_id = JSON.parse(localStorage.getItem("impersonate")).user_id;
      ob.firstname = JSON.parse(localStorage.getItem("impersonate")).firstname;
      ob.lastname = JSON.parse(localStorage.getItem("impersonate")).lastname;
      ob.equipo_id = JSON.parse(localStorage.getItem("impersonate")).equipo_id;
      ob.permissions = JSON.parse(
        localStorage.getItem("impersonate")
      ).permissions;
      ob.roles = JSON.parse(localStorage.getItem("impersonate")).roles;
      return ob;
    }

    return JSON.parse(localStorage.getItem("currentUser"));
  }

  set currentUser(data: User) {
    if (!data) {
      localStorage.removeItem("currentUser");
    } else {
      localStorage.setItem("currentUser", JSON.stringify(data));
    }
  }

  estoyImpersonado() {
    let itoken = JSON.parse(localStorage.getItem("impersonate"));
    if (itoken !== null) {
      itoken = JSON.parse(localStorage.getItem("impersonate")).auth_token;
    }
    let myToken = localStorage.getItem("tbearer");
    let tengoToken =
      myToken !== null && myToken !== undefined && myToken !== "";
    let estoyImpersonado =
      itoken !== null && itoken !== undefined && itoken !== "";
    if (tengoToken) {
      if (estoyImpersonado) {
        return true;
      }
    }
    return false;
  }

  setImpersonate(autor: any) {
    localStorage.setItem("impersonate", JSON.stringify(autor));
  }

  getImpersonate(): any {
    return JSON.parse(localStorage.getItem("impersonate"));
  }

  goToLoginPage() {
    window.location.replace(this.getLoginURL());
  }

  getUserData() {
    return this.http
      .get<User>(
        URLHelper.concat(environment.API_BASE_URL, "api", "userdata"),
        {
          params: {
            expand: [
              "roles",
              "permissions",
              "autorizaciones",
              "user.auth_key",
            ].join(","),
          },
          withCredentials: true,
        }
      )
      .pipe(
        tap((data) => {
          localStorage.setItem(
            "tbearer",
            JSON.stringify(data.user.access_token)
          );
        }),
        // retry(3),
        catchError(() => of(null))
      );
  }

  logout() {
    localStorage.removeItem("impersonate");
    localStorage.removeItem("tbearer");
    window.location.replace(
      URLHelper.concat(
        environment.API_BASE_URL,
        "user",
        `logout?appUrl=${environment.HOME_URL}`
      )
    );
  }

  private getLoginURL() {
    var url = URLHelper.concat(environment.API_BASE_URL, "user", "login");
    return `${url}?appUrl=${window.location.href}`;
  }

  currentUserCan(permissionName: string | string[]): Promise<boolean> {
    return this.permissionsService.hasPermission(permissionName);
  }
}
