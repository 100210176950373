import { ChangeDetectionStrategy, Component, EventEmitter, HostListener, Input, OnInit, Output, ViewChild } from "@angular/core";
import { FormBuilder, Validators } from "@angular/forms";
import { NgbModal, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import { IAccionSecundariaAplicada } from "../condiciones.models";
import { AccionesManualesService } from "./acciones-manuales.service";

@Component({
    selector: '[app-cc-editor-accion-manual]',
    templateUrl: './editor-accion-manual.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class EditorAccionManualComponent implements OnInit {
    readonly tiposAccion$ = this.service.tiposAcciones$;
    readonly tiposDescuento$ = this.service.tiposDescuentos$;

    readonly form = this.fb.group({
        id: [null],
        accion: [ null, Validators.required ],
        tipo: [ 'F', [Validators.required] ],
        valor: [ 0, Validators.min(0) ],
        contenido: [ '', Validators.required ]
    }, {updateOn: 'submit'});

    get accion () { return this.form.get('accion'); }
    get tipo () { return this.form.get('tipo'); }
    get valor () { return this.form.get('valor'); }
    get contenido () { return this.form.get('contenido'); }

    forceShowErrors: boolean = false;

    
    @Input() title: string = '';

    @Input() data?: IAccionSecundariaAplicada;

    @Output() save: EventEmitter<IAccionSecundariaAplicada> = new EventEmitter();
    
    @ViewChild('content', {static: true}) modalRef: NgbModalRef;
    private tempModalRef: NgbModalRef


    constructor (
        private readonly service: AccionesManualesService,
        private readonly fb: FormBuilder,
        private readonly modal: NgbModal
    ) 
    {}

    ngOnInit() {}

    onSubmit() {
        this.forceShowErrors = this.form.valid;

        if (this.form.valid) {
            const { value } = this.form;
            
            this.tempModalRef.close(true);
            this.save.emit({ ...value, accion_id: value.accion.id });
        }
    }

    @HostListener('click', ['$event'])
    open($event: MouseEvent) {
        $event.stopPropagation();

        this.initializeForm();

        this.tempModalRef = this.modal.open(this.modalRef, {size: 'lg'});
        this.tempModalRef.result.then((save) => {
            if (!save) {
                this.initializeForm();
            }
        });

        return false;
    }

    private initializeForm() {
        let defaults = { id: null, accion: null, tipo: 'F', valor: 0, contenido: '' };

        if (this.data !== undefined) {
            const { id, accion, tipo, valor, contenido } = this.data;
            defaults = { id, accion, tipo, valor, contenido };
        }

        this.form.reset(defaults, { emitEvent: false });
        this.form.markAsPristine();
    }
}