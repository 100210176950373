import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { FRONTEND_USER_ROLE } from "./app.permissions";
import { AuthGuardService } from "./auth-guard.service";
import { ChatComponent } from "./chat/chat.component";
import { ClientesDetailResolverService } from "./clientes/clientes-detail-resolver.service";
import { ClientesDetailComponent } from "./clientes/clientes-detail.component";
import { ClientesListComponent } from "./clientes/clientes-list.component";
import { SolicitudesSecundariasComponent } from "./consultas/solicitudes-secundarias/solicitudes-secundarias.component";
import { BaseLayoutComponent } from "./Layout/base-layout/base-layout.component";
import { ClienteBreadcrumbResolverService } from "./Layout/Components/breadcrumb/cliente-breadcrumb-resolver";
import { VendedorBreadcrumbResolverService } from "./Layout/Components/breadcrumb/vendedor-breadcrumb-resolver";
import { PedidosAprobacionResolverService } from "./pedidos/list/pedidos-aprobacion-resolver.service";
import { PedidosAprobacionComponent } from "./pedidos/list/pedidos-aprobacion.component";
import { PedidosVendedorComponent } from "./pedidos/list/pedidos-vendedor.component";
import { PedidosDetailComponent } from "./pedidos/pedidos-detail.component";
import { PedidosNewComponent } from "./pedidos/pedidos-new.component";
import {
  AUTORIZAR_PEDIDOS_PERMISSION,
  CREAR_PEDIDOS_PERMISSION,
  VER_PEDIDOS_PERMISSION
} from "./pedidos/pedidos.permissions";

const routes: Routes = [
  {
    path: "",
    component: BaseLayoutComponent,
    canActivate: [AuthGuardService],
    canActivateChild: [AuthGuardService],
    data: {
      permissions: {
        only: [FRONTEND_USER_ROLE],
        redirectTo: "/error/denied",
      },
    },
    children: [
      {
        path: "",
        redirectTo: "clientes",
        pathMatch: "full",
        data: {
          extraParameter: "ventas",
          permissions: {},
        },
      },
      {
        path: "clientes",
        data: {
          extraParameter: "ventas",
          breadcrumb: "Gestión de Ventas",
          permissions: {},
        },
        children: [
          {
            path: "",
            component: ClientesListComponent,
            data: {
              breadcrumb: "Pedidos",
              permissions: {},
            },
          },
          {
            path: ":cliente_id",
            resolve: {
              breadcrumb: ClienteBreadcrumbResolverService,
              cliente: ClientesDetailResolverService,
            },
            children: [
              {
                path: "",
                component: ClientesDetailComponent,
                // breadcrumb = null para resolver el problema del doble breadcrumb
                // esto es porque las rutas sin path o componente pasan para abajo el valor de data resuelto
                data: {
                  breadcrumb: null,
                  permissions: {},
                },
              },
              {
                path: "nuevo-pedido",
                component: PedidosNewComponent,
                data: {
                  extraParameter: "clientesMenu",
                  nuevoPedido: true,
                  permissions: {
                    only: [CREAR_PEDIDOS_PERMISSION],
                  },
                  breadcrumb: "Nuevo Pedido",
                },
              },
              {
                path: "pedidos/:id",
                component: PedidosDetailComponent,
                data: {
                  extraParameter: "clientesMenu",
                  nuevoPedido: false,
                  permissions: {
                    only: [VER_PEDIDOS_PERMISSION],
                  },
                  breadcrumb: ":id",
                },
              },
            ],
          },
        ],
      },
      {
        path: "chat",
        component: ChatComponent,
        data: {
          extraParameter: "chatMenu",
        },
      },
      {
        path: "pedidos/aprobacion",
        // component: PedidosAprobacionComponent,
        // resolve: {
        //   list: PedidosAprobacionResolverService,
        // },
        data: {
          permissions: {
            only: [AUTORIZAR_PEDIDOS_PERMISSION],
          },
          breadcrumb: "Gestión de Ventas",
          extraParameter: "ventas",
        },
        children: [
          {
            path: "",
            component: PedidosAprobacionComponent,
            resolve: {
              list: PedidosAprobacionResolverService,
            },
            data: {
              permissions: {
                only: [AUTORIZAR_PEDIDOS_PERMISSION],
              },
              breadcrumb: "Pedidos a Aprobar",
              extraParameter: "ventas",
            },
          },
        ],
      },
      {
        path: "pedidos",
        data: {
          extraParameter: "pedidosMenu",
          permissions: {
            only: [VER_PEDIDOS_PERMISSION],
          },
          breadcrumb: "Consulta de Pedidos",
        },
        children: [
          {
            path: "",
            component: PedidosVendedorComponent,
          },
          {
            path: ":pernr",
            component: PedidosVendedorComponent,
            resolve: {
              breadcrumb: VendedorBreadcrumbResolverService,
            },
          },
        ],
      },
      {
        path: "consultas/acciones-secundarias",
        data: {
          extraParameter: "consultas",
          breadcrumb: "Gestión de Ventas",
        },
        children: [
          {
            path: "",
            component: SolicitudesSecundariasComponent,
            data: { breadcrumb: "Acciones Secundarias" },
          },
        ],
      },
      {
        path: "consultas",
        loadChildren: () => import("./consultas/consultas.module").then(m => m.ConsultasModule),
        data: {
          extraParameter: "consultas",
          breadcrumb: "Otras Consultas",
        },
      },
      {
        path: "estadisticas",
        loadChildren: () => import("./gestion-operativa/gestion-operativa.module").then(m => m.GestionOperativaModule),
        data: {
          breadcrumb: null,
          extraParameter: 'estadisticas',
          permissions: {
            only: ["DIRECTOR", "GCIA_GRAL", "GCIA_COM", "Resp_Trade_Mkt", "JDV", "VENDEDOR", "ANALISTA_ADM_VTAS"],
            redirectTo: "/",
          },
        },
      },
      {
        path: "tablero",
        loadChildren: () => import('./tablero-gestion/tablero-gestion.module').then(x => x.TableroGestionModule),
        data: {
          extraParameter: 'tablero',
          breadcrumb: 'Tablero de Gestión',
          permissions: {
            only: ['DIRECTOR', 'GCIA_GRAL', 'GCIA_COM', 'ANALISTA_ADM_VTAS', 'Resp_Trade_Mkt', 'JDV', 'SDV', 'VENDEDOR']
          }
        }
      },
      {
        path: "comercial",
        redirectTo: "acciones/comerciales",
      },
      {
        path: "gestion-operativa",
        redirectTo: "estadisticas",
      },
      {
        path: "listasprecios",
        redirectTo: "consultas/precios",
      },
      {
        path: "acciones",
        redirectTo: "consultas/acciones",
      },
    ],
  },
  {
    path: "error",
    // Carga asíncrona de módulo:
    loadChildren: () =>
      import("./error-pages/error-pages.module").then(
        (m) => m.ErrorPagesModule
      ),
  },
  {
    path: "**",
    redirectTo: "error/404",
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
