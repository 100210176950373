import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { ThemeOptions } from '../theme-options';

@Component({
    selector: 'app-drawer',
    template: `
    <button class="btn btn-info text-nowrap" (click)="toggleOptionsDrawer()">
        <ng-content select="[drawerButton]"></ng-content>
    </button>
    <div class="ui-theme-settings">
        <div class="settings-nav-btn">
            <button type="button" class="hamburger hamburger--elastic"
                [ngClass]="{'is-active' : globals.toggleThemeOptions}" (click)="toggleOptionsDrawer()">
                <span class="hamburger-box"><span class="hamburger-inner"></span></span>
            </button>
        </div>
        <div class="theme-settings__inner">
            <perfect-scrollbar>
                <ng-content select="[drawerContent]"></ng-content>
            </perfect-scrollbar>
        </div>
    </div>
`
})
export class DrawerComponent implements OnInit, OnDestroy {
    @Output() close: EventEmitter<any> = new EventEmitter();
    
    constructor(public globals: ThemeOptions) {}

    ngOnInit() {
        this.hideDrawer();
    }

    ngOnDestroy() {
        this.hideDrawer();
    }

    toggleOptionsDrawer() {
        if (this.isDrawerShown()) {
            this.close.emit(null);
        }
        this.globals.toggleThemeOptions = !this.globals.toggleThemeOptions;
    }

    hideDrawer() {
        if (this.isDrawerShown()) {
            this.close.emit(null);
            this.globals.toggleThemeOptions = false;
        }
    }

    isDrawerShown() {
        return this.globals.toggleThemeOptions;
    }
}