import { Component, Input, OnInit } from "@angular/core";
import { BehaviorSubject, Observable } from 'rxjs';
import { map, withLatestFrom } from 'rxjs/operators';
import { MaterialPrVtas } from '../models/ListaPrecios.model';
import { StockService } from './stock.service';

@Component({
    selector: 'app-stock-por-almacen',
    template: `
    <ng-container *ngIf="data$|async as data; else noData">
        <table class="table table-striped">
            <thead>
                <th scope="col">Almacén</th>
                <th scope="col">Cantidad</th>
            </thead>
            <tbody>
                <tr *ngFor="let s of data">
                    <th scope="row">{{ s.LGORT }} - {{ s.NAME }}</th>
                    <td class="text-right">{{ s.STOCK | number: '1.0-2' }}</td>
                </tr>
            </tbody>
        </table>
    </ng-container>
    <ng-template #noData>
        <p>No hay datos disponibles</p>
    </ng-template>
    `
})
export class StockPorAlmacenComponent implements OnInit {

    private item$: BehaviorSubject<MaterialPrVtas> = new BehaviorSubject(null);
    @Input() set item (val: MaterialPrVtas) {
        this.item$.next(val);
    }
    get item(): MaterialPrVtas {
        return this.item$.getValue();
    }

    private centro$: BehaviorSubject<string> = new BehaviorSubject(null);
    @Input() set centro (value: string) {
        this.centro$.next(value);
    }


    data$: Observable<{LGORT: string; NAME: string; STOCK: number}[]> = this.item$.pipe(
        withLatestFrom(this.centro$),
        // distinctUntilChanged(([item1, y1], [item2, y2]) => item1.UNIDAD !== item2.UNIDAD),
        map(([item, centro]) => this.service.getStockByCentroGroupByAlmacen(item, centro))
    );



    /* data$: Observable<{LGORT: string; NAME: string; STOCK: number}[]> = this.centro$.pipe(
        withLatestFrom(this.item$),
        distinctUntilChanged(([x1, y1], [x2, y2]) => y1.UNIDAD !== y2.UNIDAD),
        map(([centro, item]) => this.service.getStockByCentroGroupByAlmacen(item, centro))
    );
 */
    constructor(private service: StockService) {}

    ngOnInit() {

    }
}