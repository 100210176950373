<div class="card">
    <div class="card-header">
        <div class="col" style="padding: 0">Acciones Secundarias Especiales</div>
        <div class="col-auto ml-auto">
            <ng-container *ngIf="editing$|async">
                <button app-cc-editor-accion-manual class="btn btn-primary" title="Nueva Acción" (save)="addAccion($event)">
                    Nueva Acción
                </button>
            </ng-container>
        </div>
    </div>
    <div class="card-body">
        <ng-template #noData><p>No hay acciones manuales aplicadas</p></ng-template>
        <ng-container *ngIf="acciones$|async as acciones; else noData">
            <table class="table" *ngIf="acciones.length > 0; else noData">
                <thead>
                    <tr>
                        <th scope="col" style="width: 20%;">Nombre Acción</th>
                        <th scope="col" style="width: 15%;">Acordado</th>
                        <th scope="col">Contenido</th>
                        <th scope="col" class="text-center" style="width: 8%;">Estado</th>
                        <th scope="col" class="text-center" style="width: 15%;" *ngIf="editing$|async">
                            Acciones
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let accion of acciones; let i = index">
                        <td>{{ accion.accion.nombre }}</td>
                        <td>
                            <div class="input-group flex-nowrap">
                                <div class="input-group-prepend">
                                    <span class="input-group-text">{{ accion.tipo == 'F' ? '$' : '%' }}</span>
                                </div>
                                <input 
                                    type="number" 
                                    class="form-control text-right"
                                    style="width: 8em; float: right; clear: both"
                                    [(ngModel)]="accion.valor"
                                    min="0"
                                    step="0.01"
                                    [readonly]="true" />
                            </div>
                        </td>
                        <td>{{ accion.contenido }}</td>
                        <td class="text-center">
                            <app-badge-estado [orderHeader]="accion.solicitudSecundaria" *ngIf="accion.solicitudSecundaria"></app-badge-estado>
                        </td>
                        <td class="text-center" *ngIf="editing$|async">
                            <ng-container *ngIf="!accion.solicitudSecundaria; else noAcciones">
                                <button app-cc-editor-accion-manual 
                                    class="btn btn-link" 
                                    [data]="accion" 
                                    title="Editar Acción" 
                                    (save)="updateAccion(i, $event)">
                                    Editar
                                </button>
                                <button class="btn btn-danger" (click)="removeAccion(i)">Quitar</button>
                            </ng-container>
                            <ng-template #noAcciones>
                                <small>No hay acciones disponibles</small>
                            </ng-template>
                        </td>
                    </tr>
                </tbody>
            </table>
        </ng-container>
    </div>
</div>