import { Injectable } from "@angular/core";
import { MaterialBasico, MaterialUmconv, MaterialPrVtas } from './lista-precios/models/ListaPrecios.model';
import { environment } from 'src/environments/environment';

export type UnidadMedida = 'UN' | 'CJ' | 'KG' | 'PAL';

@Injectable({
    providedIn: 'root'
})
export class MaterialesService {

    calcularDisplay(material: MaterialBasico): number {
        try {
            let display = material.DISPLAY || (
                this.calcularPesoPorCaja(material) / this.calcularPesoPorUnidad(material)
            );
            return Math.round(display);
        } catch (e) {
            if (!environment.production) {
                console.warn(`CUIDADO: '${e.name}: ${ e.message }'`);
            }
            return 1;
        }
    }

    calcularPallet(material: MaterialBasico): number {
        try {
            let pallet = this.calcularPesoPorPallet(material) / this.calcularPesoPorCaja(material);
            return Math.round(pallet)
        } catch (e) {
            if (!environment.production) {
                console.error(e);
            }
            return 0;
        }
    }

    registraCajasXPallet(material: MaterialBasico): boolean {
        return !!this.findMaterialUmconv(material, 'PAL') && !!this.findMaterialUmconv(material, 'CJ');
    }

    calcularPesoPorUnidad(material: MaterialBasico): number {
        let un = this.findMaterialUmconv(material, 'UN');
        return un.UMREZ / un.UMREN;
    }

    calcularPesoPorCaja(material: MaterialBasico): number {
        let cj = this.findMaterialUmconv(material, 'CJ');
        return cj.UMREZ / cj.UMREN;
    }

    calcularPesoPorPallet(material: MaterialBasico): number {
        let pal = this.findMaterialUmconv(material, 'PAL');
        return pal.UMREZ / pal.UMREN;
    }

    getCantidadEnUnidades(item: MaterialPrVtas, cantidad?: number): number {
        var unidades: number;
        var c = cantidad === undefined ? item.CANTIDAD : cantidad;
        if (item.UNIDAD === item.KMEIN) {
            unidades = c;
        } else if (item.UNIDAD === 'KG') {
            unidades = c / this.calcularPesoPorUnidad(item.mATNR);
        } else if (item.UNIDAD === 'CJ') {
            unidades = c * this.calcularDisplay(item.mATNR);
        }
        return unidades;
    }

    puedeCalcularDisplay(material: MaterialBasico): boolean {
        const exists = (unidad: UnidadMedida) => !!this.findMaterialUmconv(material, unidad);

        return exists('UN') && exists('CJ');
    }

    private findMaterialUmconv (material: MaterialBasico, type: UnidadMedida): MaterialUmconv | null {
        return material.materialUmconvs ? material.materialUmconvs.find(uc => uc.KMEIN === type) : null;
    }
}