import { Component, EventEmitter, Input, OnDestroy, OnInit } from "@angular/core";
import { AbstractControl } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject, combineLatest, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, first, map, pluck, switchMap } from 'rxjs/operators';
import { MaterialPrVtas, MaterialUmconv } from '../models/ListaPrecios.model';
import { MaterialesService } from 'src/app/materiales.service';
import { ifNotNullOrUndefined } from 'src/app/utils/operators';
import { Centro } from './Stock.model';
import { StockService } from './stock.service';

@Component({
    selector: '[app-semaforo-stock]',
    templateUrl: "./semaforo-stock.component.html",
})
export class SemaforoStockComponent implements OnInit, OnDestroy {
    private subscriptions: Subscription[] = [];

    item$: BehaviorSubject<MaterialPrVtas> = new BehaviorSubject(null);
    @Input() set item(value: MaterialPrVtas) {
        this.item$.next(Object.assign({}, value));
    }

    get item(): MaterialPrVtas {
        return this.item$.getValue();
    }

    /***/
    @Input() itemForm: AbstractControl;
    /***/
    
    unidadChange: EventEmitter<string> = new EventEmitter();

    private centro$: BehaviorSubject<{WERKS: string, NAME: string}> = new BehaviorSubject(null);
/*     set centro(val: any) {
        this.centro$.next(val);
    } */
    get centro(): Partial<Centro> {
        return this.centro$.getValue();
    }

    status$: BehaviorSubject<string> = new BehaviorSubject('');

    /**
     * Ajuste de saldo segun cantidad original en el item.
     * Al calcular se convierte a la unidad seleccionada.
     */
    ajuste$: BehaviorSubject<number> = new BehaviorSubject(0);
    set ajuste(val: number) {
        this.ajuste$.next(val);
    }
    get ajuste() {
        return this.ajuste$.getValue();
    }

    unidadesMedida$ = this.item$.pipe(
        ifNotNullOrUndefined(),
        first(),
        pluck('mATNR', 'materialUmconvs'),
        map(ums => ums.filter(i => i.KMEIN !== 'PAL'))
    );

    iconClass$ = this.status$
        .pipe(
            map((status) => {
                switch (status) {
                    case StockService.STATUS_RED:
                        return `pe-7s-close-circle text-${status}`;
                    case StockService.STATUS_YELLOW:
                        return `pe-7s-attention text-${status}`;
                    case StockService.STATUS_GREEN:
                        return `pe-7s-check text-${status}`;
                }
            })
        );

    STATUS_RED = StockService.STATUS_RED;
    STATUS_YELLOW = StockService.STATUS_YELLOW;
    STATUS_GREEN = StockService.STATUS_GREEN;

    iconClasses = {};

    constructor(private service: StockService, private modalService: NgbModal, private materialesService: MaterialesService) {
        this.iconClasses[this.STATUS_RED] = 'pe-7s-close-circle text-' + this.STATUS_RED;
        this.iconClasses[this.STATUS_YELLOW] = 'pe-7s-attention text-' + this.STATUS_YELLOW;
        this.iconClasses[this.STATUS_GREEN] = 'pe-7s-check text-' + this.STATUS_GREEN;
    }

    ngOnInit() {
        this.subscriptions.push(
            this.unidadChange
                .pipe(distinctUntilChanged())
                .subscribe(unidad => {
                    this.item$.next(Object.assign({}, this.item, { UNIDAD: unidad }));
                })
        );
        this.subscriptions.push(
            this.item$
                .pipe(
                    map(item => {
                        let ajuste = item.AJUSTE;
                        let kgUnidad = this.materialesService.calcularPesoPorUnidad(item.mATNR);
                        let display = this.materialesService.calcularDisplay(item.mATNR);
                        switch (item.UNIDAD) {
                            case 'KG': 
                                return ajuste * kgUnidad;
                            case 'CJ': 
                                return ajuste / display;
                            default: 
                                return ajuste;
                        }
                    })
                )
                .subscribe(i => this.ajuste = i)
        );

        this.subscriptions.push(
            this.item$
                .pipe(
                    pluck('mATNR', 'dWERK'),
                    distinctUntilChanged()
                )
                .subscribe(this.centro$)
        );

        this.subscriptions.push(
            combineLatest([this.item$, this.centro$]).pipe(
                debounceTime(600),
                // filter((arr) => arr.every(i => ![null, undefined].includes(i))),
                distinctUntilChanged(([prevItem], [nextItem]) => prevItem.CANTIDAD !== nextItem.CANTIDAD),
                switchMap(([item, centro]) => {
                    return this.service.getStockStatus(item, centro.WERKS, this.ajuste);
                })
            ).subscribe(this.status$)
        );

        this.subscriptions.push(
            this.itemForm.valueChanges.subscribe(value => {
                this.item$.next(Object.assign(this.item, value));
            })
        );
    }

    ngOnDestroy() {
        this.subscriptions.forEach(i => i.unsubscribe());
    }


    open(content) {
        this.modalService.open(content, { size: 'lg', ariaLabelledBy: 'modal-basic-title' });
    }

    onChangeUnidad(unidad: MaterialUmconv) {
        this.unidadChange.emit(unidad.KMEIN);
    }

    get status() {
        return this.status$.getValue();
    }
}