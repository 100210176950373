import { Component, EventEmitter, Input, Output } from '@angular/core';
import * as moment from 'moment';
import { map } from 'rxjs/operators';
import { CondicionesService } from '../condiciones.service';


const habilitados = x => x.filter(y => y.habilitado);

const esVigente = y => {
    const desde = moment(y.vigencia_desde).hour(0).minute(0).second(0).millisecond(0);
    const hasta = moment(y.vigencia_hasta).hour(0).minute(0).second(0).millisecond(0);

    return moment().isBetween(desde, hasta.add(1, 'days'), undefined, '[)');
};

const vigentes = x => x.filter(y => esVigente(y));

@Component({
    selector: 'app-cc-disponibles',
    templateUrl: './condiciones-comerciales-disponibles.component.html'
})
export class CondicionesComercialesDisponiblesComponent {
    @Input() edit: boolean = false;

    readonly data$ = this.condicionesService.notSelectedCondiciones$.asObservable().pipe(
        map(condiciones => habilitados(condiciones)),
        map(condiciones => condiciones.map(condicion => ({ 
            ...condicion, 
            condicionVariantes: vigentes(habilitados(condicion.condicionVariantes))
                .map(variante => ({
                    ...variante,
                    condicionVarianteCombos: habilitados(variante.condicionVarianteCombos),
                    condicionVarianteAccionsecs: habilitados(variante.condicionVarianteAccionsecs)
                })),
        }))),
        map(condiciones => condiciones.filter(x => x.condicionVariantes.length > 0))
    );

    @Output() close: EventEmitter<any> = new EventEmitter();

    constructor(private condicionesService: CondicionesService) {}

}