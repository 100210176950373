import { Component, forwardRef, Input } from "@angular/core";
import { ControlValueAccessor, FormControl, FormGroup, NG_VALUE_ACCESSOR, Validators } from "@angular/forms";
import * as moment from 'moment';
import { BehaviorSubject, combineLatest, of, Subscription } from "rxjs";
import { distinctUntilChanged, filter, map, tap } from "rxjs/operators";

@Component({
    selector: 'app-aniomes-control',
    template: `
        <div class="form-row" [formGroup]="form">
            <div class="col-8">
                <div [ngClass]="{'form-group': showLabels}">
                    <label *ngIf="showLabels">Año</label>
                    <ng-select [clearable]="false" [items]="anios$|async" 
                        bindValue="value" bindLabel="label" 
                        formControlName="anio"
                        (blur)="onTouched($event)">
                    </ng-select>
                </div>
            </div>
            <div class="col-4">
                <div [ngClass]="{'form-group': showLabels}">
                    <label *ngIf="showLabels">Mes</label>
                    <ng-select [clearable]="false" [items]="meses$|async" 
                        bindValue="value" bindLabel="label" 
                        formControlName="mes"
                        (blur)="onTouched($event)">
                    </ng-select>
                </div>
            </div>
        </div>
    `,
    providers: [
        { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => AniomesControlComponent), multi: true }
    ]
})
export class AniomesControlComponent implements ControlValueAccessor {
    private subscription: Subscription;

    form: FormGroup = new FormGroup({
        anio: new FormControl('', [Validators.required]),
        mes: new FormControl('', [Validators.required])
    });

    private anioMin$: BehaviorSubject<string|number> = new BehaviorSubject(moment().year());

    @Input() set anioMin (value: string|number) {
        this.anioMin$.next(value);
    }

    @Input() showLabels: boolean = true;

    set value (value: string) {
        this.form.patchValue({
            anio: value.slice(0, 4),
            mes: value.slice(4),
        });
    }

    anios$ = this.anioMin$.pipe(
        map(x => Number(x)),
        map(anioMin => new Array((moment().year() - anioMin) + 1).fill(anioMin)),
        map(arr => arr.map((x, i) => (x + i).toString())),
        map((anios) => anios.map(x => ({ value: x, label: x })))
    );

    meses$ = of((new Array(12))
        .fill(0)
        .map((x, i) => (i + 1).toString().padStart(2, '0'))
        .map(x => ({ value: x, label: x })));

    onChange = (_) => {};
    onTouched = (_) => {};

    writeValue(obj: string): void {
        this.value = obj;
    }

    registerOnChange(fn: any): void {
        if (this.subscription && !this.subscription.closed) {
            this.subscription.unsubscribe();
        }

        this.onChange = fn;

        this.subscription = combineLatest([
            this.form.valueChanges,
            this.form.statusChanges, 
        ]).pipe(
            filter(([ value, status ]) => status == 'VALID'),
            map(([ { anio, mes }, status ]) => `${anio}${mes}`),
            distinctUntilChanged(),
        ).subscribe(x => this.onChange(x));
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    setDisabledState?(isDisabled: boolean): void {
        if (isDisabled) {
            this.form.disable({ emitEvent: false });
        } else {
            this.form.enable({ emitEvent: false });
        }
    }
}