<div class="card" *ngIf="variante">
    <div class="card-body">
        <div class="row align-items-center">
            <div class="col-auto">
                <h4 class="card-title">
                    {{ condicion.nombre }}
                    <span class="badge badge-warning mx-1" *ngIf="!esHabilitada">
                        No Habilitada
                    </span>
                </h4>
                <h5 class="card-subtitle">{{ condicion.descripcion }}</h5>
            </div>
            <div class="col" *ngIf="variante">
                <app-cc-selector-variantes 
                    [variantes]="condicion.condicionVariantes" 
                    [(seleccionada)]="variante"
                    [readonly]="!edit || (pedido && pedido.estado >= 1)">
                </app-cc-selector-variantes>
            </div>
            <div class="col-auto" *ngIf="edit && (!pedido || pedido.estado < 1)">
                <button class="btn btn-danger btn-block" (click)="onQuitar()" [disabled]="!botonQuitarHabilitado">
                    Quitar
                </button>
            </div>
        </div>
        <ng-container *ngIf="variante">
            <ul ngbNav #nav="ngbNav" class="nav-tabs" [destroyOnHide]="false">
                <li ngbNavItem="descuento" *ngIf="variante.condicionVarianteCombos[0] as combo">
                    <a ngbNavLink>Acciones de Descuento</a>
                    <ng-template ngbNavContent>
                        <div class="row">
                            <div class="col-lg-7">
                                <app-cc-reglas-descuento [combo]="combo" [aplicados]="comboGrupoAplicados"></app-cc-reglas-descuento>
                            </div>
                            <div class="col-lg-5">
                                <app-cc-acciones-combo titulo="Acciones" [acciones]="combo.condicionVarianteComboAccions"></app-cc-acciones-combo>
                            </div>
                        </div>
                    </ng-template>
                </li>
                <li ngbNavItem="entrega" *ngIf="variante?.condicionVarianteEntregas[0]">
                    <a ngbNavLink>Entrega Gratuita</a>
                    <ng-template ngbNavContent>
                        <div *ngFor="let entrega of variante.condicionVarianteEntregas">
                            <div class="card mt-2">
                                <div class="card-header">{{ entrega.descripcion || '(Sin descripción)' }}</div>
                                <div class="card-body">
                                    <div class="row">
                                        <div class="col-lg-7">
                                            <app-cc-reglas-entrega [entrega]="entrega" [aplicados]="entregaGrupoAplicados"></app-cc-reglas-entrega>
                                        </div>
                                        <div class="col-lg-5">
                                            <app-cc-acciones-entrega titulo="Acciones" [acciones]="entrega.condicionVarianteEntregaAccions"></app-cc-acciones-entrega>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ng-template>
                </li>
                <li ngbNavItem="secundarias" *ngIf="secundarias">
                    <a ngbNavLink>Acciones Secundarias</a>
                    <ng-template ngbNavContent>
                        <app-cc-accion-secundaria [acciones]="secundarias" [edit]="edit && (!pedido || pedido.estado < 2)"></app-cc-accion-secundaria>
                    </ng-template>
                </li>
            </ul>
            <!-- <div class="row" *ngIf="variante">
                <div class="col-lg-7 col-md-12">
                </div>
                <div class="col-lg-5 col-md-12" *ngIf="secundarias">
                </div>
            </div> -->
            <div [ngbNavOutlet]="nav"></div> 
        </ng-container>
        <div class="row" *ngIf="!variante">
            <div class="col-12">
                <p class="text-muted">El pedido tiene asociada una variante no habilitada</p>
            </div>
        </div>
    </div>
</div>
