import { Component, Input, OnInit } from "@angular/core";
import { File } from "./models/File.model";

@Component({
    selector: 'app-files',
    templateUrl: './files.component.html',
})
export class FilesComponent {
    @Input() files: File[] = [];
    @Input() title: string;
    @Input() dateFormat: string;
}