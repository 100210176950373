import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxPermissionsService } from 'ngx-permissions';
import { BehaviorSubject, of, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, switchMap } from 'rxjs/operators';
import { User } from '../models/User.model';
import { PaginationDataSource } from 'src/app/utils/datasource/pagination-datasource.service';
import { NotificationService } from 'src/app/utils/notifications/notification.service';
import { VendedoresService } from 'src/app/vendedores.service';
import { ClientesService } from './clientes.service';
import { Cliente, ClienteQuery } from './models/Cliente.model';

@Component({
    selector: 'app-clientes-list',
    templateUrl: './clientes-list.component.html',
    styles: [],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ClientesListComponent implements OnInit {
    private subscriptions: Subscription[] = [];

    public filters = this.fb.group({
        searchTerm: [''],
        vendedor: ['']
    });

    public veVendedores$ = new BehaviorSubject<boolean>(false);

    // Uso un Map para indexar por PERNR y renderizar la columna vendedor.
    // Esto hace más eficiente la request a la api
    // (si no, me traigo repetido un montón de veces los mismos vendedores)
    public vendedores: Map<string, User> = new Map();

    dataSource = new PaginationDataSource<Cliente, ClienteQuery>(
        (request, q) => this.service.page(request, q), 
        {property: 'NAME', order: 'asc'}, // order realmente es ignorado
        {searchTerm: '', vendedor: ''}
    )

    constructor(
        public service: ClientesService,
        private vendedoresService: VendedoresService,
        public router: Router, 
        public route: ActivatedRoute, 
        private fb: FormBuilder,
        private permissionsService: NgxPermissionsService,
        private changeDetector: ChangeDetectorRef,
        private notiService: NotificationService
    ) {}

    ngOnInit() {
        this.subscriptions.push(
            this.filters.valueChanges.pipe(
                debounceTime(500),
                distinctUntilChanged((x, y) => x.searchTerm == y.searchTerm && x.vendedor == y.vendedor)
            )
            .subscribe(query => this.dataSource.queryBy(query))
        )

        this.subscriptions.push(
            this.veVendedores$.pipe(
                switchMap(veVendedores => veVendedores ? 
                    this.vendedoresService.getVendedores() : of([])
                )
            ).subscribe(vendedores => {
                this.vendedores = new Map(vendedores.map(x => ([x.PERNR, x])));
                this.changeDetector.markForCheck()
            })
        );

        this.subscriptions.push(
            this.permissionsService.permissions$.pipe(
                map((permisos) => {
                    let requeridos = ['JDV', 'SDV', 'GCIA_GRAL', 'GCIA_COM', 'Resp_Trade_Mkt', 'DIRECTOR', 'ANALISTA_ADM_VTAS'];
                    // Si no tiene el permiso, permisos[x] === undefined, luego !!permisos[x] === false
                    return requeridos.some(x => !!permisos[x]);
                })
            ).subscribe(this.veVendedores$)
        );
    }

    clientClick(cliente){
        this.router.navigate([cliente.id], { relativeTo: this.route, state: { cliente: cliente.NAME } });
    }

    renderVendedor(pernr: string) {
        if (!this.vendedores || !this.vendedores.has(pernr)) {
            return `${pernr} - (no opera en portal)`;
        }
        let vendedor = this.vendedores.get(pernr);
        return `${pernr} - ${vendedor.firstname} ${vendedor.lastname}`;
    }

}
