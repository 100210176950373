<div class="main-card mb-3 card">
  <div class="card-header">
    <div
      class="card-header-title font-size-lg text-capitalize font-weight-normal"
    >
      Clientes
    </div>

    <!-- 
      <div class="btn-actions-pane-right">
      </div>
     -->
  </div>
  <div class="card-body">
    <ng-container *ngIf="dataSource.page$ | async as page">
      <div class="d-flex justify-content-between align-items-center">
        <div class="form-group form-inline mb-0" [formGroup]="filters">
          <div class="form-inline">
            <label for="searchBox" class="mx-2">Buscar Cliente</label>
            <input
              id="searchBox"
              class="form-control ml-2"
              type="text"
              name="searchTerm"
              formControlName="searchTerm"
            />
          </div>
          <div *ngIf="veVendedores$ | async" class="form-inline">
            <label for="vendedor" class="mx-2">Vendedor</label>
            <select
              id="vendedor"
              class="custom-select"
              formControlName="vendedor"
            >
              <option [value]="''">Indistinto</option>
              <option *ngFor="let v of vendedores | keyvalue" [value]="v.key">
                {{ v.key }} - {{ v.value.firstname }} {{ v.value.lastname }}
              </option>
            </select>
          </div>
        </div>
        <div class="align-self-middle">
          <ngb-pagination
            [collectionSize]="page.totalElements"
            [page]="page.number"
            (pageChange)="dataSource.fetch($event)"
            [maxSize]="10"
            [pageSize]="page.size"
            [rotate]="true"
            [ellipses]="false"
            [boundaryLinks]="true"
          >
          </ngb-pagination>
        </div>
      </div>
      <ng-container *ngIf="dataSource.loading | async as loading; else table">
        <div class="card-body"><p>Cargando...</p></div>
      </ng-container>
      <ng-template #table>
        <ng-container *ngIf="page.content as clientes; else noData">
          <div
            *ngIf="clientes.length > 0; else noData"
            class="table-responsive"
          >
            <table
              class="align-middle text-truncate mb-0 table table-borderless table-hover"
            >
              <thead>
                <tr>
                  <th class="text-center">Nº de Cliente</th>
                  <th class="text-left">Razón Social / Nombre</th>
                  <th *ngIf="veVendedores$ | async" class="text-left">
                    Vendedor
                  </th>
                  <!-- <th class="text-left">Nombre</th> -->
                  <th class="text-left">Ciudad</th>
                  <th class="text-left">Provincia</th>
                  <th class="text-left">País</th>
                  <!-- <th class="text-center">Acciones</th> -->
                </tr>
              </thead>
              <tbody>
                <tr
                  *ngFor="let c of clientes | pagination"
                  (click)="clientClick(c)"
                >
                  <td class="text-center text-muted" style="width: 80px">
                    {{ c.KUNNR }}
                  </td>
                  <td class="text-left text-uppercase">
                    {{ c.NAME }} {{ c.NAME2 }}
                  </td>
                  <td *ngIf="veVendedores$ | async" class="text-left">
                    {{ renderVendedor(c.pernrVendedor) }}
                  </td>
                  <!-- <td class="text-left">{{ c.NAME2 }}</td> -->
                  <td class="text-left text-uppercase">{{ c.CITY }}</td>
                  <td class="text-left text-uppercase">{{ c.PROVINCIA }}</td>
                  <td class="text-left text-uppercase">{{ c.COUNTRY }}</td>
                  <!-- <td class="text-center">
                        <div role="group" class="btn-group-sm btn-group">
                          <button class="btn-shadow btn btn-secondary" [routerLink]="['/clientes', c.id]">Ver Detalle</button>
                        </div>
                      </td> -->
                </tr>
              </tbody>
            </table>
            <!-- <div class="card-footer pb-0">
                    <div class="d-flex w-100 justify-content-between">
                      <ngb-pagination [collectionSize]="clientes.length" [(page)]="page" [pageSize]="pageSize">
                      </ngb-pagination>
                      <select class="custom-select" style="width: auto" name="pageSize" [(ngModel)]="pageSize">
                        <option [ngValue]="5">5 items por página</option>
                        <option [ngValue]="10">10 items por página</option>
                        <option [ngValue]="20">20 items por página</option>
                      </select>
                    </div>
                  </div> -->
          </div>
        </ng-container>
        <ng-template #noData>
          <div class="card-body">
            <p>No hay datos para mostrar</p>
          </div>
        </ng-template>
      </ng-template>
    </ng-container>
  </div>
</div>
