import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, throwError } from "rxjs";
import { catchError } from "rxjs/operators";

@Injectable({ providedIn: "root" })
export class ExcelService {
  constructor(private http: HttpClient) {}

  downloadFile(url, error: (e: any) => Observable<any> = (e) => throwError(e)) {
    this.http
      .get(url, { observe: "response", responseType: "blob" })
      .pipe(catchError(error))
      .subscribe((response) => {
        this.descargar(response);
      });
  }

  descargar(data) {
    const blob = new Blob([data.body], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    const url = window.URL.createObjectURL(blob);
    const filename = this.getFileName(data);
    if (filename !== "") {
      var anchor = document.createElement("a");
      anchor.download = filename;
      anchor.href = url;
      anchor.click();
    } else {
      window.open(url);
    }
  }

  getFileName(data) {
    var filename = "";
    var disposition = data.headers.get("content-disposition");
    if (disposition && disposition.indexOf("attachment") !== -1) {
      var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
      var matches = filenameRegex.exec(disposition);
      if (matches != null && matches[1]) {
        filename = matches[1].replace(/['"]/g, "");
      }
    }
    return filename;
  }
}
