import {
  Component,
  EventEmitter,
  Input,
  NgModule,
  OnDestroy,
  OnInit,
  Output,
  TemplateRef,
} from "@angular/core";
import { FormControl, Validators } from "@angular/forms";
import { NgbModal, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import { BehaviorSubject, combineLatest, Subscription } from "rxjs";
import {
  catchError,
  map,
  sample,
  switchMap,
  tap,
  withLatestFrom,
} from "rxjs/operators";
import { CatalogoFactoryService } from "src/app/catalogos/factory.service";
import { ClientesService } from "src/app/clientes/clientes.service";
import {
  Cliente,
  ClienteDatoComercial,
  Interlocutor,
} from "src/app/clientes/models/Cliente.model";
import { User } from "src/app/models/User.model";
import { OrderHeaderIn } from "src/app/pedidos/models/Order.model";
import { ifNotNullOrUndefined } from "src/app/utils/operators";
import { NgxPermissionsGuard, NgxPermissionsService } from "ngx-permissions";
import { UserService } from "../user.service";
import { of } from "rxjs";

import { ActivatedRoute, Router } from "@angular/router";

type OpcionDireccionEntrega = {
  value: string;
  label: string;
  default: boolean;
};

@Component({
  selector: "app-autorizaciones",
  templateUrl: "./autorizaciones.component.html",
})
export class AutorizacionesComponent implements OnInit, OnDestroy {
  private subscriptions: Subscription[] = [];
  private resultArray = [];
  @Input() editable: boolean = false;

  private user$: BehaviorSubject<User> = new BehaviorSubject(null);
  @Input() set user(val: User) {
    this.user$.next(val);
  }
  get user() {
    return this.user$.getValue();
  }

  opcionSeleccionada: FormControl = new FormControl(null, [
    Validators.required,
  ]);

  /**
   * Evento que sirve para 'samplear' el valor del control al momento de presionar 'Aceptar'
   */
  protected seleccionar: EventEmitter<void> = new EventEmitter();

  constructor(
    private modalService: NgbModal,
    private clientesService: ClientesService,
    private catalogos: CatalogoFactoryService,
    private us: UserService,
    private ps: NgxPermissionsService,
    public router: Router,
    public route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.user = this.us.currentUser;
    let it = this;
    this.resultArray = Object.keys(this.user.autorizaciones).map(function (
      idx
    ) {
      let person = it.user.autorizaciones[idx];
      // do something with person
      return person;
    });
    this.user.autorizaciones = this.resultArray;

    // Object.keys(this.user.autorizaciones).map(function (key) {
    //   this.arr.push({ [key]: this.user.autorizaciones[key] });
    //   return this.arr;
    // });
    this.subscriptions.push(
      this.opcionSeleccionada.valueChanges
        .pipe(
          sample(this.seleccionar)
          // switchMap((opcion) => this.interlocutores$
          //     .pipe(
          //         map(interlocutores => opcion ? interlocutores.find(i => i.KUNN2 === opcion.value) : null)
          //     )
          // )
        )
        .subscribe
        //   this.interlocutorChange
        ()
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }

  open(content: TemplateRef<any>) {
    var subscription = combineLatest([this.user$]).subscribe(([user]) => {});

    this.modalService
      .open(content, { size: "lg" })
      .result.finally(() => subscription.unsubscribe());
  }

  userClick(autorizante) {
    this.us.setImpersonate(autorizante);

    this.router.navigate(["/clientes"]).then((x) => window.location.reload());
    // window.location.reload();
    // return this.us.getUserData().pipe(
    //   tap((user) => {
    //     this.ps.loadPermissions([
    //       ...Object.keys(user.roles),
    //       ...Object.keys(user.permissions),
    //     ]);
    //     this.us.currentUser = user;
    //   }),
    //   catchError((error) => {
    //     this.ps.flushPermissions();
    //     return of(false);
    //   })
    // );
  }

  aceptar(modal) {
    this.seleccionar.emit();
    modal.close();
  }
}
