import { Component, Input } from "@angular/core";

@Component({
    selector: 'app-progress-box',
    template: `
<div class="card mb-3 widget-content">
    <div class="widget-content-outer">
        <div class="widget-content-wrapper">
        <div *ngIf="title && subtitle" class="widget-content-left">
            <div *ngIf="title" class="widget-heading">{{ title }}</div>
            <div *ngIf="subtitle" class="widget-subheading">{{ subtitle }}</div>
        </div>
        <div class="widget-content-right">
            <div [className]="'widget-numbers text-' + displayClass">{{ value | number : '1.2-2' }}%</div>
        </div>
        </div>
        <div class="widget-progress-wrapper">
        <div class="progress-bar-sm progress-bar-animated-alt progress">
            <div [className]="'progress-bar bg-' + displayClass" role="progressbar"
                [attr.aria-valuenow]="value | number : '1.2-2'" aria-valuemin="0" aria-valuemax="100"
                [style.width]="value + '%'"></div>
        </div>
        <div *ngIf="sublabel" class="progress-sub-label">
            <div class="sub-label-left">{{ sublabel }}</div>
            <div class="sub-label-right">{{ value | number:'1.2-2' }}%</div>
        </div>
        </div>
    </div>
</div>    
`
})
export class ProgressBoxComponent {
    @Input() title: string;
    @Input() subtitle: string;
    @Input() value: number = 0;
    @Input() sublabel: string;
    @Input() set displayClass (value: string) {
        
    }

    get displayClass () {
        if (this._displayClass) {
            return this._displayClass;
        }

        if (this.value === 100) {
            return 'success';
        }
        if (this.value === 0) {
            return 'default';
        }
        if (this.value < 50) {
            return 'danger';
        } else {
            return 'primary';
        }
    }

    private _displayClass: string;
}