<div class="app-sidebar sidebar-shadow">
  <div class="app-header__logo">
    <div class="logo-src"></div>
    <div [ngClass]="{ 'ml-auto': !globals.toggleSidebar }">
      <button type="button" class="hamburger close-sidebar-btn hamburger--elastic" (click)="toggleSidebar()">
        <span class="hamburger-box">
          <span class="hamburger-inner"></span>
        </span>
      </button>
    </div>
  </div>
  <div class="app-sidebar-content">
    <div class="app-sidebar-scroll">
      <perfect-scrollbar>
        <div class="v-sidebar-menu vsm-default">
          <div class="vsm-list">
            <ngb-accordion #acc [destroyOnHide]="false" [closeOthers]="false" [activeIds]="extraParameter || []">
              <ng-container *ngFor="let panel of (panels$|async)">
                <ngb-panel [id]="panel.id" *ngxPermissionsOnly="panel.permissions?.only">
                  <ng-template ngbPanelHeader>
                    <button ngbPanelToggle class="vsm-link">
                      <i class="vsm-icon fas fa-angle-right"></i>
                      <span class="vsm-title">{{ panel.title }}</span>
                      <i class="vsm-arrow"></i>
                    </button>
                  </ng-template>
                  <ng-template ngbPanelContent>
                    <div class="vsm-dropdown">
                      <div class="vsm-list">
                        <ng-container *ngFor="let item of (panel.items || [])">
                          <div class="vsm-item" *ngxPermissionsOnly="item.permissions?.only">
                            <ng-container *ngIf="item.routing; else noRouting">
                              <a [routerLink]="item.routing?.routerLink"
                                 [routerLinkActive]="item.routing?.routerLinkActive || 'active-item'"
                                 [title]="item.title"
                                 class="vsm-link">
                                <span class="vsm-title">{{ item.title }}</span>
                              </a>
                            </ng-container>
                            <ng-template #noRouting>
                              <a class="vsm-link">
                                <span class="vsm-title">{{ item.title }}</span>
                              </a>
                            </ng-template>
                          </div>
                        </ng-container>
                      </div>
                    </div>
                  </ng-template>
                </ngb-panel>
              </ng-container>
            </ngb-accordion>
            <a type="button" class="vsm-link" [attr.href]="logopsHref" target="_blank">
              <i class="vsm-icon fas fa-external-link-alt"></i>
              <span class="vsm-title">Logops - Nueva logística</span>
            </a>
          </div>
        </div>
      </perfect-scrollbar>
    </div>
  </div>
</div>
