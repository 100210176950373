import { registerLocaleData } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import localeEs from '@angular/common/locales/es';
import localeEsAr from '@angular/common/locales/es-AR';
import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
// for Core import:
import { LoadingBarModule } from '@ngx-loading-bar/core';
// for HttpClient import:
import { LoadingBarHttpClientModule } from '@ngx-loading-bar/http-client';
// for Router import:
import { LoadingBarRouterModule } from '@ngx-loading-bar/router';
import { NgxPermissionsModule } from 'ngx-permissions';
import { TrendModule } from 'ngx-trend';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ChatComponent } from './chat/chat.component';
import { ClientesModule } from './clientes/clientes.module';
import { AuthInterceptor } from './interceptors/AuthInterceptor';
import { DefaultInterceptor } from './interceptors/DefaultInterceptor';
import { LayoutModule } from './Layout/layout.module';
import { ListaPreciosModule } from './lista-precios/lista-precios.module';
import { PedidosModule } from './pedidos/pedidos.module';
import { SharedModule } from './shared.module';
import { SituacionModule } from './situacion/situacion.module';
import { ThemeOptions } from './theme-options';
import { UtilsModule } from './utils/utils.module';
import { WidgetsModule } from './widgets/widgets.module';


registerLocaleData(localeEs);
registerLocaleData(localeEsAr, 'es-AR');


@NgModule({
  declarations: [
    AppComponent,
    ChatComponent,
  ],
  imports: [
    AppRoutingModule,
    BrowserAnimationsModule,
    BrowserModule,
    SharedModule,
    UtilsModule,
    WidgetsModule,
    PedidosModule,
    ClientesModule,
    LayoutModule,
    ListaPreciosModule,
    SituacionModule,

    // DropzoneModule,
    TrendModule,
    NgxPermissionsModule.forRoot(),
    
    // for HttpClient use:
    LoadingBarHttpClientModule,
    // for Router use:
    LoadingBarRouterModule,
    // for Core use:
    LoadingBarModule,
    NgbModule,
  ],
  providers: [
    /* {
      provide: APP_INITIALIZER,
      useFactory: (ps: NgxPermissionsService, us: UserService) => {
        return () => us.getUserData()
          .pipe(
            catchError(error => { 
              ps.flushPermissions(); return of(EMPTY) 
            }),
            ifNotNullOrUndefined(),
            tap(user => {
              ps.loadPermissions([
                ...Object.keys(user.roles),
                ...Object.keys(user.permissions),
              ])
            },)
          )
      },
      deps: [NgxPermissionsService, UserService],
      multi: true
    }, */
    
    {
      provide: HTTP_INTERCEPTORS,
      useClass: DefaultInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    ThemeOptions,
    {
      provide: LOCALE_ID,
      useValue: 'es-AR'
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {

}
