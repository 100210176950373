<div class="form-row p-2" *ngIf="state$|async as state">
  <div *ngIf="state.loading && !state.pedidos" class="p-3">
    <p>Cargando...</p>
  </div>
  <div *ngIf="state.pedidos as pedidos" class="table-responsive">
    <div class="d-flex justify-content-between align-items-center">
      <div class="form-check form-check-inline p-2">
        <input #check class="form-check-input" type="checkbox" id="pendientes" [ngModel]="state.soloActivos" (ngModelChange)="setSoloActivos(check.checked)" />
        <label class="form-check-label" for="pendientes">Sólo Activos</label>
      </div>
      <div class="p-2 d-flex pull-right">
        <div class="pr-5">
            <ngb-pagination [collectionSize]="pedidos.totalElements" [page]="state.page" (pageChange)="setPage($event)" 
              [maxSize]="10" [pageSize]="pedidos.size" [rotate]="true" [ellipses]="false" [boundaryLinks]="true">
            </ngb-pagination>
        </div>
      </div>
    </div>
    <ng-container *ngIf="pedidos.content && pedidos.content.length > 0; else noData">
      <table class="align-middle text-truncate mb-0 table table-borderless table-hover">
        <thead>
          <tr>
            <th class="text-left">#</th>
            <th class="text-left">SAP</th>
            <th class="text-left">Fecha Requerida</th>
            <th class="text-left">Fecha Doc</th>
            <th class="text-center">Estado</th>
            <th class="text-center">Apr.Cred</th>
            <th class="text-right">Imp. Neto</th>
            <th class="text-right">Pendiente</th>
            <th class="text-right">Días Atraso</th>
            <th class="text-left">Observaciones</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let p of pedidos.content" [className]="'p_estado_' + p.estado" [routerLink]="['/clientes', state.cliente.id, 'pedidos', p.id]" >
            <td class="text-left">{{ p.id }}</td>
            <td class="text-left">{{ p.SALESDOCUMENTIN | ltrim:'0' }}</td>
            <td class="text-left">{{ p.REQ_DATE_H | date: "dd/MM/yyyy" }}</td>
            <td class="text-left">{{ p.DOC_DATE | date: "dd/MM/yyyy" }}</td>
            <td class="text-center">
              <!-- <app-badge-estado [orderHeader]="p"></app-badge-estado> -->
              <span class="badge" attr.style="color:white;background-color: {{ p.estadoColor }}">
                {{ pedidosService.estadoToString(p) }}
              </span>
            </td>
            <td class="text-center">{{ p.apr_cred }}</td>
            <td class="text-right">
              {{ p.importe_total | currency: "ARS" }}
            </td>
            <td class="text-right">
              {{ p.impNetoPendiente | currency: "ARS" }}
            </td>
            <td class="text-right">
              {{ p.diasAtraso > 0 ? (p.diasAtraso | number: "1.0-0") : "-" }}
            </td>
            <td
              class="text-left"
              [innerHtml]="p.ultimaObservacion?.OBSERVACION"
            ></td>
          </tr>
        </tbody>
      </table>
    </ng-container>
    <ng-template #noData>
      <div class="p-2">No hay pedidos para mostrar</div>
    </ng-template>
  </div>
</div>
