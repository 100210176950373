import { Injectable, OnDestroy } from "@angular/core";
import { Component, OnInit } from '@angular/core';
import { Observable, combineLatest, BehaviorSubject, Subscription } from 'rxjs';
import { OrderHeaderIn } from '../models/Order.model';
import { Router, ActivatedRoute } from '@angular/router';
import { PedidosService } from '../pedidos.service';
import { ClientesService } from '../../clientes/clientes.service';
import { Cliente } from '../../clientes/models/Cliente.model';
import { ColumnDefinition } from '../../utils/groups/groups-table.model';
import { formatDate } from '@angular/common';
import { GroupsService } from '../../utils/groups/groups.service';
import { FormControl } from '@angular/forms';
import { startWith, debounceTime, distinctUntilChanged, map, tap, catchError, filter, pluck, withLatestFrom } from 'rxjs/operators';
import { StringHelper } from '../../utils/string.helper';
import { Group } from '../../utils/groups/groups.model';

type SearchFn = (data: any, term: string) => boolean;

@Injectable({
    providedIn: 'root'
})
export class PedidosListService implements OnDestroy {
    private subscriptions: Subscription[] = [];

    private filter$: BehaviorSubject<(i?: OrderHeaderIn) => boolean> = new BehaviorSubject(() => true);

    private data$: BehaviorSubject<any> = new BehaviorSubject(null);

    pushData(data: any) {
        this.data$.next(data);
    }

    private searchFn: SearchFn;

    private collapse$: BehaviorSubject<boolean> = new BehaviorSubject(true);

    private _groups$: BehaviorSubject<Group<any>[]> = new BehaviorSubject([]);

    groups$ = combineLatest([this._groups$, this.collapse$])
        .pipe(
            tap(([groups, collapse]) => groups.forEach(g => g.setCollapse(collapse))),
            map(([groups]) => groups),
        );
    
    private soloActivos$: BehaviorSubject<boolean> = new BehaviorSubject(true);
    get soloActivos() {
        return this.soloActivos$.getValue();
    }
    set soloActivos(val: boolean) {
        this.soloActivos$.next(val);
    }

    constructor(
        public service: ClientesService,
        public pedidosService: PedidosService,
        public router: Router,
        public route: ActivatedRoute,
        private groupsService: GroupsService
    ) 
    {
        this.subscriptions.push(
            combineLatest([
                this.items$, 
                this.groupBy$.pipe(
                    startWith('KUNNR')
                ),
            ])
            .pipe(
                filter(([data]) => ![null, undefined].includes(data)),
                withLatestFrom(this.collapse$),
                map(([[data, groupByProp], collapse]): Group<any>[] => {
                    return this.groupsService.group<any>(
                        data as OrderHeaderIn[], 
                        groupByProp as string, 
                        'kUNNR.NAME', 
                        collapse
                    );
                }),
                map((groups: Group<any>[]) => groups.sort((a, b) => a.label < b.label ? -1 : 1)),
                map((groups: Group<any>[]) => this.groupsService.moveToLastPosition<any>(groups, i => i.value == '?')),
            )
            .subscribe(this._groups$)
        );
    }

    ngOnDestroy() {
        this.subscriptions.forEach(s => s.unsubscribe());
    }

    toggleSoloActivos() {
        this.soloActivos = !this.soloActivos;
    }

    setFilter(fn: (i?: OrderHeaderIn) => boolean) {
        this.filter$.next(fn);
    }

    resetFilter() {
        this.filter$.next(() => true);
    }

    setCollapse(value: boolean) {
        this.collapse$.next(value);
    }

    getCollapse() {
        return this.collapse$.getValue();
    }

    toggleCollapse() {
        this.collapse$.next(!this.collapse$.getValue());
    }

    getEstadoClass(p: OrderHeaderIn) {
        return this.pedidosService.getEstadoClass(p);
    }

    getEstadoLabel(p: OrderHeaderIn) {
        return this.pedidosService.estadoToString(p);
    }

    goToDetail(pedido: OrderHeaderIn) {

        this.router.navigate(['/clientes', pedido.kUNNR.id, 'pedidos', pedido.id], {
            state: {
                pedList: true,
                cliente: pedido.kUNNR.NAME
            }
        });
    }

    private groupBy$: BehaviorSubject<string> = new BehaviorSubject('KUNNR');
    setGroupBy (value: string) {
        this.groupBy$.next(value);
    }
    getGroupBy () {
        return this.groupBy$.getValue();
    }

    setSearchFunction(fn: SearchFn) {
        this.searchFn = fn;
    }

    searchBox = new FormControl('');

    private searchTerm$: Observable<string> = this.searchBox.valueChanges
        .pipe(
            startWith(''), 
            debounceTime(500),
            distinctUntilChanged(),
            map((term) => term.trim()),
            map((term) => term.toUpperCase()),
            map((term) => StringHelper.removeTildes(term)),
            withLatestFrom(this.collapse$),
            map(([term, collapse]) => term)
        );

    private items$: Observable<OrderHeaderIn[]> = 
        combineLatest([
            this.data$, 
            this.searchTerm$,
            this.filter$,
            this.soloActivos$
        ])
        .pipe(
            map(([data, term, fnFilter, soloActivos]) => ([
                data.filter((x: OrderHeaderIn) => fnFilter(x) && (!soloActivos || x.activo)), term
            ])),
            map(([data, term]) => data.filter(i => this.searchFn(i, term))),
        );
}