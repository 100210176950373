import { Component, HostListener, ViewChild, Input } from "@angular/core";
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { CondicionAcuerdo } from '../clientes/models/Cliente.model';

@Component({
    selector: '[app-acuerdos-modal]',
    template: `
<ng-template #content let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Acuerdos</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <app-acuerdos *ngIf="data && data.length > 0; else noDatita" [data]="data" [fechas]="false" [paginado]="false"></app-acuerdos>
    <ng-template #noDatita>
        <p>No hay acuerdos para mostrar</p>
    </ng-template>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="modal.dismiss()">Cerrar</button>
  </div>
</ng-template>
<ng-content></ng-content>
`
})
export class AcuerdosModalComponent {
    
    @ViewChild('content', {static: true}) modalRef: NgbModalRef;

    constructor(private modalService: NgbModal) {}

    @Input() data: CondicionAcuerdo[];
    
    @HostListener('click')
    open() {
        this._open(this.modalRef);
    }

    _open(content: NgbModalRef) {
        this.modalService.open(content, {
            size: (this.data && this.data.length > 0) ? 'xl' : 'md'
        });
    }

}