
import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
    selector: '[decimalPlaces]'
})
export class DecimalPlacesDirective {

    constructor(private el: ElementRef) { }

    @Input() decimalPlaces: number;

    @HostListener('change', ['$event'])
    onChange($event) {
        $event.target.value = $event.target.valueAsNumber.toFixed(this.decimalPlaces);
    }
}
