import { Component, HostListener, Input, ViewChild } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Condicion, CondicionVariante } from '../condiciones.models';

@Component({
    selector: '[app-cc-modal-acciones]',
    template: `
    <ng-template #content let-modal>
        <ng-container *ngIf="variante.condicionVarianteCombos[0] as combo">
            <ng-container *ngIf="combo.condicionVarianteComboAccions as acciones">
                <div class="modal-header">
                    <div>
                        <h4 class="modal-title" id="modal-basic-title">
                            Condicion Comercial {{ condicion.nombre || 'sin Nombre' }}
                        </h4>
                        <h5 class="modal-subtitle">
                            Variante {{ variante.descripcion || 'sin Nombre' }}
                        </h5>
                    </div>
                    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss()">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body py-0">
                    <app-cc-acciones-combo [acciones]="acciones"></app-cc-acciones-combo>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-outline-primary" (click)="modal.dismiss()">Cerrar</button>
                </div>
            </ng-container>
        </ng-container>
    </ng-template>
    <ng-content></ng-content>
`
})
export class CondicionComercialModalAccionesComponent {
    @Input() condicion: Condicion;
    @Input() variante: CondicionVariante;

    @ViewChild('content', {static: true}) modalRef: NgbModalRef;

    constructor(protected modalService: NgbModal) {}

    @HostListener('click')
    onClick() {
        this.modalService.open(this.modalRef, {size: 'lg'});
    }
}