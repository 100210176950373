import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { map } from 'rxjs/operators';
import { PedidosService } from 'src/app/pedidos/pedidos.service';
import { ClientesService } from './clientes.service';


@Component({
    selector: 'app-clientes-detail',
    templateUrl: './clientes-detail.component.html',
    styleUrls: ['../../assets/ventas/pedidos/_pedidos.scss'],
})
export class ClientesDetailComponent {
    cliente$ = this.route.data.pipe(map(x => x.cliente));

    constructor(
        public route: ActivatedRoute,
        public service: ClientesService,
        public router: Router,
        public pedidosService: PedidosService,
    ) {}
}