<div class="main-card mb-3 card">
   <div class="card-body">
      <h5 class="card-title">{{ title }}</h5>
      <ng-container *ngIf="events && events.length > 0; else noevents">
         <div class="scroll-area">
            <div class="scrollbar-container">
               <perfect-scrollbar [autoPropagation]="true">
                  <div class="vertical-timeline vertical-timeline--animate vertical-timeline--one-column">
                     <ng-container *ngFor="let event of events">
                        <app-timeline-event [event]="event" [dateFormat]="dateFormat"></app-timeline-event>
                     </ng-container>
                  </div>
               </perfect-scrollbar>
            </div>
         </div>
      </ng-container>

      <ng-template #noevents>
         <div class="vertical-timeline vertical-timeline--animate vertical-timeline--one-column">
            <div class="vertical-timeline-item vertical-timeline-element">
               <div class="vertical-timeline-element-content bounce-in">
                  <h4 class="timeline-title">No hay eventos para mostrar</h4>
               </div>
            </div>
         </div>
      </ng-template>
   </div>
</div>