import { Component, Input, OnInit } from "@angular/core";
import { TimelineEvent } from '../models/TimelineEvent.model';

@Component({
    selector: 'app-timeline-event',
    templateUrl: './event.component.html'
})
export class EventComponent implements OnInit {
    @Input() event: TimelineEvent;
    @Input() dateFormat: string;
    
    ngOnInit() {
        if (!this.dateFormat) {
            this.dateFormat = 'dd/MM/yyyy HH:mm';
        }
    }
}