import { Component, EventEmitter, Input, Output } from "@angular/core";
import { Cliente, Texto } from '../models/Cliente.model';

@Component({
    selector: 'app-clientes-datos',
    templateUrl: './clientes-datos.component.html'
})
export class ClientesDatosComponent {
    @Input() cliente: Cliente;
    @Output() createEmail: EventEmitter<Partial<Texto>> = new EventEmitter();
}