import { formatPercent } from '@angular/common';
import { Pipe, PipeTransform } from "@angular/core";

@Pipe({name: 'percentOrUndefined'})
export class PercentOrUndefinedPipe implements PipeTransform {

    transform(value: any, digitsInfo?: string, placeholder: string = '-', locale: string = 'es') {
        if ([null, undefined].includes(value) || Number.isNaN(value)) {
            return placeholder;
        }
        return formatPercent(value, locale, digitsInfo);
    }

}