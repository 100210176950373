<ng-template #content let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Etiquetas</h4>
    <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="modal.dismiss('Cross click')"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <app-tags-badge [tags]="tags"></app-tags-badge>
  </div>
  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-outline-dark"
      (click)="modal.close('Save click')"
    >
      Aceptar
    </button>
  </div>
</ng-template>

<ng-container *ngIf="tags?.length > 0">
  <!-- <svg xmlns="http://www.w3.org/2000/svg" > -->
  <!-- <circle cx="5" cy="5" r="5" fill="red" (click)="open(content)"/> -->
  <!-- </svg> -->
  <button
    title="Etiquetas"
    class="btn"
    style="
      width: 20px;
      height: 20px;
      text-align: center;
      padding: 6px 0;
      font-size: 12px;
      line-height: 1.428571429;
      border-radius: 9px;
      background: linear-gradient(to right, red, blue, indigo);
    "
    (click)="open(content)"
  ></button>
</ng-container>
