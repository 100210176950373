import { Component, HostBinding, ViewEncapsulation } from "@angular/core";

@Component({
    selector: '[table-fixed-header]',
    template: `<ng-content></ng-content>`,
    encapsulation: ViewEncapsulation.None,
    styles: [`
        .table-fixed-header {
            overflow-y: auto; 
            max-height: 42em; 
        }
        
        .table-fixed-header thead { 
            position: sticky; 
            top: 0; 
        }
        
        /* Just common table stuff. Really. */
        table  { 
            border-collapse: collapse; 
            width: 100%; 
        }
        
        th, td { 
            padding: 8px 16px; 
        }
        
        thead th { 
            background:rgb(250, 250, 250);
        }`
    ]
})
export class TableFixedHeaderDirective {
    @HostBinding('class') elementClass = "table-fixed-header";
}