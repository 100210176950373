import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { ClientesService } from './clientes.service';
import { Cliente } from './models/Cliente.model';

@Injectable({providedIn: 'root'})
export class ClientesDetailResolverService implements Resolve<Cliente> {

    constructor(private router: Router, private clientesService: ClientesService) {}

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Cliente | Observable<Cliente> | Promise<Cliente> {
        var idCliente = route.paramMap.get('cliente_id');
        if (isNaN(parseInt(idCliente, 10))) {
            return this.router.navigate(['/clientes']).then(x => null);
        }
        return this.clientesService.getCliente(idCliente);
    }
}