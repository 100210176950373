import { Component, Input } from "@angular/core";
import { CondicionAcuerdo } from '../clientes/models/Cliente.model';

@Component({
    selector: 'app-acuerdos',
    templateUrl: './acuerdos.component.html'
})
export class AcuerdosComponent {
    @Input() data: CondicionAcuerdo[];
    @Input() fechas: boolean = true;
    @Input() paginado: boolean = true;
}