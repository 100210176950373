import { Component, Input } from "@angular/core";
import { AccionSecundaria } from '../condiciones.models';

@Component({
    selector: 'app-cc-accion-secundaria',
    template: `
    <table class="table">
        <thead>
            <tr>
                <th scope="col" [attr.colspan]="colspan" class="text-center">
                    <span class="card-title">Acciones Secundarias</span>
                </th>
            </tr>
            <tr *ngIf="acciones && acciones.length">
                <th scope="col">Nombre</th>
                <th scope="col" class="text-right">Máx.</th>
                <ng-container *ngIf="!ocultarAcordado">
                    <th scope="col" class="text-right">Acordado</th>
                    <th scope="col"></th>
                    <th scope="col" class="text-center" *ngIf="edit"></th>
                    <th class="text-center">Estado</th>
                </ng-container>
            </tr>
        </thead>
        <tbody>
            <ng-container *ngIf="acciones && acciones.length; else noData">
                <tr class="align-bottom" *ngFor="let a of acciones">
                    <td>
                        {{ a.nombre || '(sin nombre)' }}
                        <span class="badge badge-warning small" *ngIf="!a.habilitado">
                            No Habilitada
                        </span>
                    </td>
                    <td class="text-right">{{ a.formatValorMax() }}</td>
                    <ng-container *ngIf="!ocultarAcordado">
                        <td class="text-right">
                            <div class="input-group flex-nowrap" *ngIf="edit && a.habilitado && !a.solicitudSecundaria">
                                <div class="input-group-prepend">
                                    <span class="input-group-text">{{ a.dto_tipo == 'F' ? '$' : '%' }}</span>
                                </div>
                                <input 
                                    type="number" 
                                    class="form-control text-right"
                                    style="width: 8em; float: right; clear: both"
                                    [(ngModel)]="a.valor"
                                    min="0"
                                    step="0.01"
                                    [max]="a.dto_monto"
                                    />
                            </div>
                            <span *ngIf="!edit || !a.habilitado || a.solicitudSecundaria">{{ a.formatValor() }}</span>
                        </td>
                        <td class="text-center">
                            <button app-cc-as-modal-contenido 
                                class="btn btn-warning" 
                                [accion]="a"
                                [edit]="edit && !a.solicitudSecundaria" 
                                [(ngModel)]="a.contenido"
                                title="Contenido"
                                *ngIf="edit || a.tieneContenido()">
                                    <!-- <i class="fas fa-info"></i> -->
                                    Contenido
                            </button>
                        </td>
                        <td class="text-center" *ngIf="edit && a.habilitado && !a.solicitudSecundaria">
                            <span class="btn btn-link text-danger" (click)="a.clear()">
                                <i class="pe-7s-close-circle" style="font-size: 16pt" title="Limpiar"></i>
                            </span>
                        </td>
                        <td class="text-center">
                            <app-badge-estado [orderHeader]="a.solicitudSecundaria" *ngIf="a.solicitudSecundaria"></app-badge-estado>
                        </td>
                    </ng-container>
                </tr>
            </ng-container>
            <ng-template #noData>
                <tr>
                    <td [attr.colspan]="colspan" class="text-center">Sin acciones secundarias</td>
                </tr>
            </ng-template>
        </tbody>
    </table>
    
    `
})
export class AccionSecundariaComponent {
    @Input() acciones: AccionSecundaria[] = [];
    @Input() edit: boolean = false;
    @Input() ocultarAcordado: boolean = false;

    get colspan() {
        return this.ocultarAcordado ? 2 : 5;
    }
}