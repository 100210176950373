<nav class="" aria-label="breadcrumb">
  <ol class="breadcrumb">
    <li class="breadcrumb-item">
      <a [routerLink]="['']">
        <span class="fas fa-home is-red"></span>
      </a>
    </li>
    <li class="breadcrumb-item" *ngFor="let breadcrumb of breadcrumbs; let last = last">
      <span [routerLink]="breadcrumb.url" routerLinkActive="router-link-active" [ngClass]="{'is-red': last}">
        {{ breadcrumb.label }}
      </span>
    </li>
  </ol>
</nav>
