import { Injector, Pipe, PipeTransform, ProviderToken, Type } from "@angular/core";

@Pipe({
    name: 'dynamic',
})
export class DynamicPipe implements PipeTransform {
    
    constructor(private readonly injector: Injector) {}

    transform(value: any, token: ProviderToken<PipeTransform>|string, ...args: any[]) {
        if (!token) {
            return value;
        }
        const pipe = this.injector.get(token);
        return pipe.transform(value, ...args);
    }

}