import { Sector } from '../clientes/models/Cliente.model';
import { MaterialBasico, ReglaPredefinida } from '../lista-precios/models/ListaPrecios.model';
import { User } from 'src/app/models/User.model';

export type InformeCierre = {
    order_header_in_id: number;
    contenido: string;
};
export class AccionSecundariaHeaderIn implements IAccionSecundariaHeaderIn {
    order_ta_id: number;
    condicion_variante_id?: number;
    accion_id?: number;
    id?: number;    
    estado?: number;
    estado0?: EstadoPedido;
    wfl_status: string;
    estadoDesc: string;
    activo: boolean;
    confirmado?: boolean;
    importe_total?: number;
    DOC_TYPE?: string;

    informeCierre?: InformeCierre;
}

export type TipoAccion = {
    id: number|string, 
    nombre: string, 
    descripcion: string
}

export interface IAccionSecundariaAplicada {
    id?: number;
    order_header_in_id?: number;
    condicion_variante_id?: number;
    accion_id: number;
    valor: number;
    contenido: string;

    tipo?: 'F'|'V';

    accion?: TipoAccion;
    solicitudSecundaria?: IAccionSecundariaHeaderIn;
}

export class AccionSecundaria {
    id?: number;
    condicion_variante_id?: number;
    accion_id?: number;
    nombre?: string;
    objetivo?: string;
    dto_monto: number;
    dto_tipo: string;
    habilitado: number;
    habilitado_entrega?: number;
    contenido: string;

    //Datos input
    valor?: number;
    solicitudSecundaria?: IAccionSecundariaHeaderIn;

    get selected(): boolean {
        return ![null, undefined].includes(this.valor)
    }

    //Datos AccionSecundariaHeaderIn relacionados
    accion_sec_order_id?: number;

    constructor(values: Partial<AccionSecundaria>) {
        if (values.valor) {
            values.valor = parseFloat(values.valor.toString());
        }
        if (values.dto_monto) {
            values.dto_monto = parseFloat(values.dto_monto.toString());
        }
        Object.assign(this, values);
    }

    formatValorMax() {
        return this.format(this.dto_monto);
    }

    formatValor() {
        return this.format(this.valor);
    }

    isValorFijo() {
        return this.dto_tipo == 'F';
    }

    tieneMaximo() {
        return ![null, undefined].includes(this.dto_monto) && this.dto_monto > 0;
    }

    tieneContenido() {
        return this.contenido && this.contenido.length > 0;
    }

    clear() {
        this.valor = null;
        this.contenido = null;
    }

    private format(monto: unknown) {
        monto = parseFloat(monto as string);
    if (Number.isNaN(monto) || !Number.isFinite(monto)) {
            return '-';
        }

        let fixed = parseFloat(monto as string).toFixed(2);
        return (this.isValorFijo() ? `$${fixed}` : `${fixed}%`);
    }
}

export interface IAccionSecundariaHeaderIn {
    order_ta_id: number;
    condicion_variante_id?: number;
    accion_id?: number;
    id?: number;
    estado?: number;
    estado0?: EstadoPedido;
    wfl_status: string;
    estadoDesc: string;
    activo: boolean;
    confirmado?: boolean;
    vendedor?: User;
    importe_total?: number;
    peso_total?: number;
    apr_cred?: string;

    informeCierre?: InformeCierre;

    /**
     * Clase de documento de ventas (sigla)
     */
    DOC_TYPE?: string;
}


export interface EstadoPedido {
    id: number;
    estado: string;
}


export interface GrpMat {
    id: number;
    KONDM: string;
    VTEXT: string;   
}

interface IRegla {
    SPART: string;
    KONDM: string;
    MATNR: string;

    sPART?: Sector;
    kONDM?: GrpMat;
    mATNR?: MaterialBasico;
}

export interface CondicionVarianteComboGrupoRegla extends IRegla {
    id: number;
    condicion_variante_combo_grupo_id: number;
}

export interface CondicionVarianteEntregaGrupoRegla extends IRegla {
    id: number;
    condicion_variante_entrega_grupo_id: number;
}

export interface IMetrica {
    metrica: string;
    op_aritmetico: string;
    valor: string;    

    valor_aplicado?: number;
    valida_aplicacion?: boolean;
}
export interface CondicionVarianteComboGrupoMetrica extends IMetrica {
    id: number;
    condicion_variante_combo_grupo_id: number;
}

export interface CondicionVarianteEntregaGrupoMetrica extends IMetrica {
    id: number;
    condicion_variante_entrega_grupo_id: number;
}



export type CondicionVarianteComboGrupoReglas = CondicionVarianteComboGrupoRegla[];
export type CondicionVarianteComboGrupoMetricas = CondicionVarianteComboGrupoMetrica[];
export type CondicionVarianteEntregaGrupoReglas = CondicionVarianteEntregaGrupoRegla[];
export type CondicionVarianteEntregaGrupoMetricas = CondicionVarianteEntregaGrupoMetrica[];


interface IGrupo {
    readonly metricaMonto: IMetrica;
    readonly metricaBulto: IMetrica;
    readonly metricaCantidad: IMetrica;
}

export interface ICondicionVarianteComboGrupo {
    id: number;
    condicion_variante_combo_id: number;
    numero: number;

    condicionVarianteComboGrupoReglas?: CondicionVarianteComboGrupoReglas;
    condicionVarianteComboGrupoMetricas?: CondicionVarianteComboGrupoMetricas;
}

export abstract class Grupo implements IGrupo {
    get metricaMonto() {
        return this.getMetrica('MONTO');
    }

    get metricaBulto() {
        return this.getMetrica('BULTO');
    }

    get metricaCantidad() {
        return this.getMetrica('CANTIDAD');
    }

    private getMetrica(nombre: string) {
        return this.getMetricas()
            .filter(y => y.metrica == nombre)
            .find(() => true)
    }

    protected registrarAplicacionInternal(grupoAplicado: GrupoAplicado, prop: string) {
        for (let metrica of this[prop]) {
            switch (metrica.metrica) {
                case 'CANTIDAD':
                    metrica.valor_aplicado = grupoAplicado.cantidad || 0;
                    metrica.valida_aplicacion = grupoAplicado.valida_cantidad;
                    break;
                case 'MONTO':
                    metrica.valor_aplicado = grupoAplicado.monto || 0;
                    metrica.valida_aplicacion = grupoAplicado.valida_monto;
                    break;
                case 'BULTO':
                    metrica.valor_aplicado = grupoAplicado.bulto || 0;
                    metrica.valida_aplicacion = grupoAplicado.valida_bulto;
                    break;
            }
        }
    }

    protected abstract getMetricas();
}

export class CondicionVarianteComboGrupo extends Grupo implements ICondicionVarianteComboGrupo {
    id: number;
    condicion_variante_combo_id: number;
    numero: number;

    condicionVarianteComboGrupoReglas: CondicionVarianteComboGrupoReglas = [];
    condicionVarianteComboGrupoMetricas: CondicionVarianteComboGrupoMetricas = [];

    constructor(data: Partial<ICondicionVarianteComboGrupo>) {
        super();
        Object.assign(this, data);
    }

    registrarAplicacion(grupoAplicado: ComboGrupoAplicado) {
        if (grupoAplicado.condicion_variante_combo_grupo_id != this.id) {
            throw new Error('Se intentó registrar una aplicación de reglas de otro grupo');
        }

        this.registrarAplicacionInternal(grupoAplicado, 'condicionVarianteComboGrupoMetricas');
    }

    protected getMetricas() {
        return this.condicionVarianteComboGrupoMetricas;
    }
}

export class CondicionVarianteEntregaGrupo extends Grupo {
    id: number;
    condicion_variante_entrega_id: number;
    numero: number;

    condicionVarianteEntregaGrupoReglas: CondicionVarianteEntregaGrupoReglas = [];
    condicionVarianteEntregaGrupoMetricas: CondicionVarianteEntregaGrupoMetricas = [];

    constructor(data: Partial<CondicionVarianteEntregaGrupo>) {
        super();
        Object.assign(this, data);
    }

    registrarAplicacion(grupoAplicado: EntregaGrupoAplicado) {
        if (grupoAplicado.condicion_variante_entrega_grupo_id != this.id) {
            throw new Error('Se intentó registrar una aplicación de reglas de otro grupo');
        }

        this.registrarAplicacionInternal(grupoAplicado, 'condicionVarianteEntregaGrupoMetricas');
    }

    protected getMetricas() {
        return this.condicionVarianteEntregaGrupoMetricas;
    }
}

export interface IAccion {
    valor: string;
    SPART: string;
    KONDM: string;
    MATNR: string;
    regla_predefinida_id?: string;
    reglaPredefinida?: ReglaPredefinida;

    sPART?: Sector;
    kONDM?: GrpMat;
    mATNR?: MaterialBasico;
}
export interface CondicionVarianteComboAccion extends IAccion {
    id: number;
    condicion_variante_combo_id: number;
    
    descuentos: string[];
}

export interface CondicionVarianteEntregaAccion extends IAccion {
    id: number;
    condicion_variante_entrega_id: number;
}

export type CondicionVarianteComboAccions = CondicionVarianteComboAccion[];
export type CondicionVarianteComboGrupos = CondicionVarianteComboGrupo[];

export interface CondicionVarianteCombo {
    id: number;
    condicion_variante_id: number;
    descripcion?: string;

    condicionVarianteComboAccions?: CondicionVarianteComboAccions;
    condicionVarianteComboGrupos?: CondicionVarianteComboGrupos;

    habilitado: boolean;
}

export interface CondicionVarianteEntrega {
    id: number;
    condicion_variante_id: number;
    descripcion?: string;

    condicionVarianteEntregaAccions?: CondicionVarianteEntregaAccion[];
    condicionVarianteEntregaGrupos?: CondicionVarianteEntregaGrupo[];

    habilitado: boolean;
}

export type CondicionVarianteCombos = CondicionVarianteCombo[];

export interface CondicionVariante {
    id: number;
    condicion_id: number;
    descripcion?: string;
    orden: number;

    condicion?: Condicion;

    condicionVarianteCombos?: CondicionVarianteCombos;
    condicionVarianteEntregas?: CondicionVarianteEntrega[];
    condicionVarianteAccionsecs?: AccionSecundaria[];

    habilitado: boolean;

    vigencia_desde: Date|string;
    vigencia_hasta: Date|string;
}

export type CondicionVariantes = CondicionVariante[];

export interface Condicion {
    id: number;
    nombre?: string;
    descripcion: string;
    prioridad: number;

    condicionVariantes?: CondicionVariantes;
    variante_id?: number;
    accion_id?: number;
    valor?: number;

    acciones: Partial<IAccionSecundariaAplicada>[];

    habilitado: boolean;
}

export interface CondicionCanal {
    id: number;
    canal_id: number;
    condicion_id: number;
    condicion?: Condicion;
}

export type CondicionCanals = CondicionCanal[];


export interface CondicionVarianteOrderHeaderIn {
    condicion_variante_id: number;
    order_header_in_id: number;
    valida: number;
    condicionVariante: CondicionVariante;
    accion_id?: number;
    valor?: number;

    comboGrupoAplicados: ComboGrupoAplicado[];
    accionesSecundariasAplicadas?: IAccionSecundariaAplicada[];
}

export interface GrupoAplicado {
    order_header_in_id: number;
    
    valida_monto: boolean;
    valida_cantidad: boolean;
    valida_bulto: boolean;

    monto: number|null;
    cantidad: number|null;
    bulto: number|null;
}
export interface ComboGrupoAplicado extends GrupoAplicado {
    condicion_variante_combo_grupo_id: number;
}

export interface EntregaGrupoAplicado extends GrupoAplicado {
    condicion_variante_entrega_grupo_id: number;
}