<ng-template #content let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Autorizaciones</h4>
    <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="modal.dismiss('Cross click')"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div>
      <legend>Seleccione el usuario con el que va a operar</legend>
      <!-- <ng-container *ngIf="currentUser"> -->
      <!-- <div class="d-flex justify-content-between align-items-center">
          <div class="form-group form-inline mb-0" [formGroup]="filters">
            <div class="form-inline">
              <label for="searchBox" class="mx-2">Buscar Usuario</label>
              <input
                id="searchBox"
                class="form-control ml-2"
                type="text"
                name="searchTerm"
                formControlName="searchTerm"
              />
            </div>
          </div>
          <div class="align-self-middle">
            <ngb-pagination
              [collectionSize]="page.totalElements"
              [page]="page.number"
              (pageChange)="dataSource.fetch($event)"
              [maxSize]="10"
              [pageSize]="page.size"
              [rotate]="true"
              [ellipses]="false"
              [boundaryLinks]="true"
            >
            </ngb-pagination>
          </div>
        </div> -->
      <!-- <ng-container *ngIf="dataSource.loading | async as loading; else table"> -->
      <!-- <div class="card-body">
            <p>Cargando...</p>
          </div> -->
      <!-- </ng-container> -->
      <!-- <ng-template #table> -->
      <!-- <ng-container *ngIf="page.content as clientes; else noData"> -->
      <!-- <div
          *ngIf="resultArray.length > 0; else noData"
          class="table-responsive"
        > -->
      <table
        class="align-middle text-truncate mb-0 table table-borderless table-hover"
      >
        <thead>
          <tr>
            <th class="text-center">Id Vendedor</th>
            <th class="text-left">Apellido y Nombre</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let c of user.autorizaciones" (click)="userClick(c)">
            <td class="text-center text-muted" style="width: 80px">
              {{ c.PERNR }}
            </td>
            <td class="text-left text-uppercase">
              {{ c.lastname }} {{ c.firstname }}
            </td>
            <!-- <td *ngIf="veVendedores$ | async" class="text-left">
                {{ renderVendedor(c.pernrVendedor) }}
              </td> -->
            <!-- <td class="text-left text-uppercase">{{ c.CITY }}</td>
                  <td class="text-left text-uppercase">{{ c.PROVINCIA }}</td>
                  <td class="text-left text-uppercase">{{ c.COUNTRY }}</td> -->
          </tr>
        </tbody>
      </table>
      <!-- </div> -->
      <!-- </ng-container> -->
      <!-- <ng-template #noData>
          <div class="card-body">
            <p>No hay datos para mostrar</p>
          </div>
        </ng-template>
      </ng-template> -->
      <!-- </ng-container> -->
    </div>
  </div>
</ng-template>

<button class="btn btn-pill btn-warning text-nowrap" (click)="open(content)">
  Autorizaciones
</button>
