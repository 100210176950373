import { NgModule } from "@angular/core";
import { LoadingBarModule } from "@ngx-loading-bar/core";
import { SharedModule } from "../shared.module";
import { BaseLayoutComponent } from "./base-layout/base-layout.component";
import { BreadcrumbsComponent } from "./Components/breadcrumb/breadcrumbs.component";
import { UserBoxComponent } from "./Components/header/elements/user-box/user-box.component";
import { HeaderComponent } from "./Components/header/header.component";
import { PageTitleComponent } from "./Components/page-title/page-title.component";
import { LogoComponent } from "./Components/sidebar/elements/logo/logo.component";
import { SidebarComponent } from "./Components/sidebar/sidebar.component";
import { AutorizacionesComponent } from "../autorizaciones/autorizaciones.component";

@NgModule({
  declarations: [
    BaseLayoutComponent,
    BreadcrumbsComponent,
    HeaderComponent,
    LogoComponent,
    PageTitleComponent,
    SidebarComponent,
    UserBoxComponent,
    AutorizacionesComponent,
  ],
  imports: [
    SharedModule, 
    LoadingBarModule
  ],
  exports: [
    BaseLayoutComponent,
    HeaderComponent,
    PageTitleComponent,
    SidebarComponent,
  ],
})
export class LayoutModule {}
