import { formatCurrency } from "@angular/common";
import { Injector, LOCALE_ID, Pipe, PipeTransform } from "@angular/core";

@Pipe({ name: 'ars' })
export class ARSPipe implements PipeTransform {
    constructor(private injector: Injector) {}

    transform(value: number, digitsInfo?: string) {
        return formatCurrency(value, this.injector.get(LOCALE_ID), '$', 'ARS', digitsInfo);
    }
}