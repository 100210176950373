import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { take, mergeMap, catchError } from 'rxjs/operators';
import { of } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { ClientesService } from 'src/app/clientes/clientes.service';

@Injectable({
    providedIn: "root"
})
export class ClienteBreadcrumbResolverService implements Resolve<string> {
    
    constructor(private service: ClientesService) {}

    resolve(route: ActivatedRouteSnapshot) {
        return this.service.getClienteName(route.paramMap.get('cliente_id'))
            .pipe(
                take(1),
                mergeMap((name) => of(name)),
                catchError((e: HttpErrorResponse) => of(''))
            );
    }
}