import { Component, Input } from '@angular/core';
import { IAccion } from '../condiciones.models';

@Component({
    selector: 'app-cc-acciones-entrega',
    template: `
    <table class="table">
        <thead>
            <tr *ngIf="titulo">
                <th class="text-center" scope="colgroup" colspan="4">
                    <span class="card-title">{{ titulo }}</span>
                </th>
            </tr>
            <tr>
                <th>Categoría - Subcategoría - Material
                <th>Bonificación</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let a of acciones">
                <td>
                    {{ 
                        a.regla_predefinida_id ? a.mATNR?.MAKTX : (a.sPART?.VTEXT || 'Indistinto') + ' - ' + (a.kONDM?.VTEXT || 'Indistinto') + ' - ' + (a.mATNR?.MAKTX || 'Indistinto')
                    }}
                </td>
                <td>{{ a.valor + ' ' + (a.regla_predefinida_id ? (' CJ cada $' + a.monto_base + ' en ' + a.reglaPredefinida.nombre + ' ') : ' % de CJ a facturar') }}</td>
            </tr>
        </tbody>
    </table>
    `
})
export class CondicionComercialAccionesEntregaComponent {
    @Input() titulo: string;
    @Input() acciones: IAccion[];
}