import { Component, Input, Output, EventEmitter, TemplateRef } from "@angular/core";
import { Texto, Cliente } from '../models/Cliente.model';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, Validators } from '@angular/forms';
import { ClientesService } from '../clientes.service';
import { tap } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import { NotificationService } from 'src/app/utils/notifications/notification.service';

@Component({
    selector: 'app-cliente-emails-modal',
    templateUrl: './cliente-emails-modal.component.html'
})
export class ClienteEmailsModalComponent 
{
    private _emails: Texto[] = [];
    private _default: Texto | null = null;

    @Output() create: EventEmitter<Partial<Texto>> = new EventEmitter();
    @Output() delete: EventEmitter<Partial<Texto>> = new EventEmitter();

    form = this.fb.group({
        clase: 1,
        descripcion: [null, [Validators.required, Validators.email]]
    });
    
    showErrors: boolean = false;

    private _creationMode: boolean = false;
    get creationMode () { return this._creationMode }
    set creationMode (value: boolean) { 
        if (!value) {
            this.form.reset({clase: 1, descripcion: null});
            this.showErrors = false;
        }
        this._creationMode = value;
    }

    loading: boolean = false;

    constructor(private service: ClientesService, private modalService: NgbModal, private notiService: NotificationService, private fb: FormBuilder) {}

    private _cliente: Cliente;
    @Input() set cliente (value: Cliente)
    {
        this._cliente = value;
        this.loadEmails();
    }
    get cliente (): Cliente { return this._cliente; }

    set emails (val: Texto[])
    {
        this._default = val.find(x => x.default);
        this._emails = val.filter(x => !x.default);
    }

    get emails(): Texto[]
    {
        return this._emails;
    }

    get default(): Texto | null 
    {
        return this._default;
    }

    open(content: TemplateRef<any>)
    {
        this.modalService.open(content).result.finally(() => this.creationMode = false);
    }

    onCreate()
    {
        if (this.form.valid)
        {
            this.showErrors = false;
            this.service.createEmail(this.cliente.KUNNR, this.form.value)
                .toPromise()
                .then(x => {
                    this.loadEmails()
                    .then(_ => this.create.emit(x))
                })
                .catch((e: HttpErrorResponse) => {
                    this.showErrors = true;
                    if (e.status == 422)
                    {
                        this.form.get(e.error[0].field).setErrors({notUnique: true})
                    }
                    else
                    {
                        console.log(e);
                        this.notiService.error('Se produjo un error al guardar la dirección', 'Error');
                    }
                });
        }
        else
        {
            this.showErrors = true;
        }
    }

    onDelete(email: Texto)
    {
        
        this.notiService.confirm((email.solicitud && email.solicitud.pendiente) ? 
            "Está por eliminar esta solicitud. ¿Está seguro?"
            : "Está por solicitar la baja de esta dirección. ¿Está seguro?",
            "Confirmar"
        ).then(conf => {
            if (conf) {
                this.service.deleteEmail(this.cliente.KUNNR, email.id)
                    .pipe(tap(_ => this.loading = true))
                    .toPromise()
                    .then(_ => {
                        this.loadEmails()
                        .then(() => this.delete.emit(email))
                    })
                    .catch((e) => {
                        console.log(e);
                        this.notiService.error('Se produjo un error al procesar la eliminación', 'Error');
                    });
            }
        });
    }

    loadEmails()
    {
        this.emails = [];
        return this.service.getEmails(this.cliente.KUNNR)
            .pipe(tap(_ => this.loading = true))
            .toPromise()
            .then(x => {
                this.emails = x;
                this.loading = false;
                return Promise.resolve(true);
            });
    }
}