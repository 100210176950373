import { Component, Output, EventEmitter, HostListener, ViewChild, Input } from "@angular/core";
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: '[app-autorizacion-modal]',
    template: `
<ng-template #content let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">{{ title }}</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <form>
      <div class="form-group">
        <label for="comentarios">Comentarios</label>
        <textarea id="comentarios" name="comment" class="form-control" [(ngModel)]="comment"></textarea>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-success" (click)="onResolver(true)">Autorizar</button>
    <button type="button" class="btn btn-danger" (click)="onResolver(false)">Rechazar</button>
    <button type="button" class="btn btn-outline-dark" (click)="modal.dismiss()">Cancelar</button>
  </div>
</ng-template>
<ng-content></ng-content>
`
})
export class ResolverAutorizacionModal {
    
    @ViewChild('content', { static: true }) modalRef: NgbModalRef;

    comment: string = null;

    @Input() title: string;
    @Output() resolve: EventEmitter<{result: boolean, comment: string}> = new EventEmitter();

    private openModalRef: NgbModalRef;

    constructor(private modalService: NgbModal) {}
    
    @HostListener('click', ['$event'])
    open($event: MouseEvent) {
      $event.stopPropagation();
      this.openModalRef = this.modalService.open(this.modalRef, { size: 'lg' });

      return false;
    }

    onResolver(result) {
      let {comment} = this;
      this.resolve.emit({ result, comment });

      if (this.openModalRef) {
        this.openModalRef.close(result);
      }

      this.comment = null;
    }

}