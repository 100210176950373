import { NgModule } from "@angular/core";
import { PaginationPipe } from './pagination.pipe';
import { PageSelectorComponent } from './page-selector.component';
import { CommonModule } from '@angular/common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule } from '@angular/forms';

@NgModule({
    declarations: [
        PaginationPipe,
        PageSelectorComponent
    ],
    imports: [
        CommonModule,
        NgbModule,
        FormsModule
    ],
    exports: [
        PaginationPipe,
        PageSelectorComponent
    ]
})
export class PaginationModule {}