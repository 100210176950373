<ng-container *ngIf="currentUser !== null">
  <div [ngClass]="containerNgClass" [class]="'app-container app-theme-white'">
    <app-header></app-header>
    <app-sidebar></app-sidebar>
    <div class="app-main__outer">
      <div class="app-main__inner">
        <router-outlet #o="outlet"></router-outlet>
      </div>
    </div>
    <div class="sidebar-menu-overlay" (click)="toggleSidebarMobile()"></div>
  </div>
  <ngx-loading-bar [color]="'var(--primary)'"></ngx-loading-bar>
</ng-container>