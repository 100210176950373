<ng-container *ngIf="data$|async as data">
    <app-drawer (close)="close.emit($event)">
        <span drawerButton>
            Condiciones Comerciales
            <span *ngIf="data" class="badge badge-pill badge-light">{{ data.length }}</span>
        </span>
        <div drawerContent>
            <div class="card-header-tab card-header">
                <div class="card-header-title font-size-lg text-capitalize font-weight-normal">
                    <i class="header-icon pe-7s-server icon-gradient bg-malibu-beach"> </i>
                    Condiciones Comerciales
                </div>
            </div>
            <div class="scrollbar-container">
                <perfect-scrollbar [autoPropagation]="true">
                    <div class="theme-settings__options-wrapper">
                        <div class="card-hover-shadow-2x mb-3 card">
                            <ng-template #noData>
                                <div class="main-card mb-3 card">
                                    <div class="card-body">
                                        <p class="card-text">No hay condiciones comerciales cargadas para la lista de
                                            precios seleccionada</p>
                                    </div>
                                </div>
                            </ng-template>
                            <ul *ngIf="data && data.length > 0; else noData"
                                class="todo-list-wrapper list-group list-group-flush">
                                <li *ngFor="let c of data" class="list-group-item">
                                    <app-cc-disponible [condicion]="c" [edit]="edit"></app-cc-disponible>
                                </li>
                            </ul>
                        </div>
                    </div>
                </perfect-scrollbar>
            </div>
        </div>
    </app-drawer>
</ng-container>