<ng-container *ngIf="cliente$ | async as cliente">
  <div class="app-page-title">
    <div class="page-title-wrapper">
      <div class="page-title-heading">
        <div class="page-title-actions">
          <!--<app-button-back></app-button-back>-->
        </div>
        <div class="page-title-icon">
          <img class="icon" *ngIf="cliente?.avatar" [src]="cliente?.avatar" />
          <i *ngIf="!cliente?.avatar" class="icon pe-7s-id"></i>
        </div>
        <div>
          {{ cliente.NAME }} {{ cliente.NAME2 }}
          <!-- <div class="page-title-subheading">
            {{ cliente.NAME2 }}
          </div> -->
          <div class="page-title-subheading">
            {{ cliente.KUNNR }}
          </div>
        </div>
      </div>
      <div class="page-title-actions">
        <a [routerLink]="['/clientes', cliente.id, 'nuevo-pedido']" class="mb-2 mr-2 btn-hover-shine btn btn-success" role="button">Nuevo Pedido</a>
      </div>
    </div>
  </div>
  <div class="main-card card">
    <div class="card-body">
      <ul ngbNav [activeId]="route.fragment | async" #nav="ngbNav" class="nav-tabs">
        <li [ngbNavItem]="'info'">
          <a ngbNavLink routerLink="." [fragment]="'info'">Información del Cliente</a>
          <ng-template ngbNavContent>
            <div class="row my-3">
              <div class="col">
                <app-clientes-datos [cliente]="cliente"></app-clientes-datos>
              </div>
            </div>
            <div class="row my-3">
              <div class="col-md-6 col-sm-12">
                <app-cliente-credito [cliente]="cliente"></app-cliente-credito>
              </div>
              <div class="col-md-6 col-sm-12">
                <app-clientes-vencimientos [cliente]="cliente"></app-clientes-vencimientos>
              </div>
            </div>
          </ng-template>
        </li>
        <li [ngbNavItem]="'direcciones'">
          <a ngbNavLink routerLink="." [fragment]="'direcciones'">Direcciones de Entrega</a>
          <ng-template ngbNavContent>
            <app-clientes-direcciones [cliente]="cliente"></app-clientes-direcciones>
          </ng-template>
        </li>
        <li [ngbNavItem]="'ventas'">
          <!-- PEDIDOS DEL CLIENTE -->
          <a ngbNavLink routerLink="." [fragment]="'ventas'">Ventas</a>
          <ng-template ngbNavContent>
            <ng-container *ngxPermissionsOnly="'verPedidos'; else noVePedidos">
              <app-clientes-ventas [cliente]="cliente"></app-clientes-ventas>
            </ng-container>
            <ng-template #noVePedidos>
              <div class="p-2">
                <i class="pe-7s-attention text-warning" style="font-size: 24px;"> </i> No tiene permiso para ver el
                listado de pedidos
              </div>
            </ng-template>
          </ng-template>
        </li>
        <li [ngbNavItem]="'historial'">
          <!-- Historia -->
          <a ngbNavLink routerLink="." [fragment]="'historial'">Historia Ventas</a>
          <ng-template ngbNavContent>
            <app-historia-ventas [cliente]="cliente"></app-historia-ventas>
          </ng-template>
        </li>
        <li [ngbNavItem]="'acuerdos'">
          <a ngbNavLink routerLink="." [fragment]="'acuerdos'">Acuerdos</a>
          <ng-template ngbNavContent>
            <app-acuerdos [data]="cliente.acuerdos"></app-acuerdos> 
          </ng-template>
        </li>
      </ul>
      <div [ngbNavOutlet]="nav"></div>
    </div>
  </div>
</ng-container>