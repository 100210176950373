import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, OnInit, Output, TemplateRef } from "@angular/core";
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Observable, Subscription } from 'rxjs';
import { map, sample, take, tap } from 'rxjs/operators';
import { CatalogoFactoryService } from "src/app/catalogos/factory.service";
import { ClientesService } from 'src/app/clientes/clientes.service';
import { Cliente, ClienteDatoComercial, DireccionEntrega, Interlocutor } from 'src/app/clientes/models/Cliente.model';
import { CondicionesEntrega, OrderHeaderIn } from 'src/app/pedidos/models/Order.model';

type OpcionDireccionEntrega = {value: string, label: string, default: boolean};

@Component({
    selector: 'app-condiciones-entrega',
    templateUrl: './condiciones-entrega.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class CondicionesEntregaComponent implements OnInit, OnDestroy {
    private subscriptions: Subscription[] = [];

    readonly form = this.fb.group({
        incoterms: [null, [Validators.required]],
        direccion: [null, [Validators.required]],
    }, 
    {
        validators: [ this.validarIncoterms.bind(this) ]
    });

    @Input() cliente: Cliente;
    @Input() datoComercial: ClienteDatoComercial;
    @Input() editable: boolean = false;
    @Input() incoterm: string;
    @Input() interlocutor: Interlocutor;
    @Input() pedido: OrderHeaderIn;

    @Output() save = new EventEmitter<CondicionesEntrega>();

    get interlocutores() {
        if ( ! this.cliente) {
            return [];
        }

        if ( ! this.datoComercial) {
            return [];
        }
        
        const interlocutores = this.clientesService.getInterlocutoresEntrega(this.cliente);

        const { VKORG, VTWEG } = this.datoComercial;

        return interlocutores.filter(x => x.VKORG === VKORG && x.VTWEG === VTWEG);
    }

    incoterms: any[];
    
    private modalRef: NgbModalRef;

    constructor(
        private modalService: NgbModal, 
        private clientesService: ClientesService,
        private catalogos: CatalogoFactoryService,
        private fb: FormBuilder) {
            
        }

    ngOnInit() {

    }

    ngOnDestroy() {
        this.subscriptions.forEach(s => s.unsubscribe());
    }

    async open(content: TemplateRef<any>) {
        if (typeof this.incoterms === 'undefined') {
            this.incoterms = await this.catalogos.getInco1()
                .list({ inco1_in: 'RET|UN' })
                .toPromise();
        }

        this.preseleccionarOpciones();
        this.modalRef = this.modalService.open(content, { size: 'lg' }); // .result.finally(() => subscription.unsubscribe());
    }

    onSubmit() {
        if (this.form.valid) {
            const { value } = this.form;
            const seleccionado = this.interlocutores.find(x => x.KUNN2 === value.direccion);

            this.save.emit({ interlocutor: seleccionado, incoterms: value.incoterms });

            this.modalRef?.close();
        }
    }

    formatearDireccion(direccion?: DireccionEntrega) {
        return direccion 
            ? `${direccion.STRAS} - ${direccion.CITY} (${direccion.POSTL_COD1})`
            : '-';
    }

    private preseleccionarOpciones() {
        const { datoComercial, interlocutor, interlocutores } = this;
        
        const direccion = interlocutor
            ?? interlocutores.find(x => !!x.DEFPA) 
            ?? interlocutores.find(() => true);

        this.form.reset({
            incoterms: this.incoterm ?? datoComercial?.INCO1 ?? null,
            direccion: direccion?.KUNN2
        });
    }

    private validarIncoterms(control: AbstractControl) {
        const form = <FormGroup>control;
        const { incoterms, direccion } = form.value;

        if (!direccion || !incoterms) {
            return null;
        }

        const interlocutorDireccion = this.interlocutores.find(x => x.KUNN2 === direccion);
        const retira = (interlocutorDireccion.kUNN2.STRAS)
            .toUpperCase()
            .includes('RETIRA DE FABRICA');

        if (retira && incoterms !== 'RET') {
            return { incoterms: 'RET' };
        }

        return null;
    }
}