import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { SharedModule } from "../shared.module";
import { TagsBadgeComponent } from "./tags-badge.component";
import { TagsModalComponent } from "./tags-modal.component";

@NgModule({
    declarations: [
        TagsBadgeComponent,
        TagsModalComponent,
    ],
    imports: [
        SharedModule,
    ],
    exports: [
        TagsBadgeComponent,
        TagsModalComponent,
    ]
})
export class TagsModule {

}
