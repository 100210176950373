import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { cloneDeep } from "lodash";
import { BehaviorSubject, Observable, of } from "rxjs";
import { distinctUntilChanged, map } from "rxjs/operators";
import { URLHelper } from "src/app/utils/url-helper.helper";
import { environment } from "src/environments/environment";
import { IAccionSecundariaAplicada, TipoAccion } from "../condiciones.models";
import { IDropdownOption } from "./model";

@Injectable()
export class AccionesManualesService {
    private readonly acciones = new BehaviorSubject<IAccionSecundariaAplicada[]>([]);
    readonly acciones$: Observable<IAccionSecundariaAplicada[]> = this.acciones.asObservable();

    private readonly tiposAcciones = new BehaviorSubject<TipoAccion[]>([]);
    readonly tiposAcciones$ = this.tiposAcciones.asObservable();

    private readonly tiposDescuentos = new BehaviorSubject<IDropdownOption[]>([]);
    readonly tiposDescuentos$ = this.tiposDescuentos.asObservable();

    private readonly editing = new BehaviorSubject<boolean>(false);
    readonly editing$ = this.editing.asObservable();

    private readonly dirty = new BehaviorSubject<boolean>(false);
    readonly dirty$ = this.dirty.asObservable().pipe(distinctUntilChanged());

    private savepoint: IAccionSecundariaAplicada[] = null;

    constructor(private readonly http: HttpClient) {
        this.fetchTiposAccion();
        this.fetchTiposDescuento();
    }
    

    setAcciones(value: IAccionSecundariaAplicada[]) {
        this.acciones.next(value);
        this.dirty.next(false);
    }

    addAccion(x: IAccionSecundariaAplicada) {
        this.acciones.next([...this.acciones.value, x]);
        this.dirty.next(true);
    }

    removeAccion(index: number) {
        let acciones = [
            ...this.acciones.value.slice(0, index),
            ...this.acciones.value.slice(index + 1)
        ];

        this.acciones.next(acciones);
        this.dirty.next(true);
    }

    updateAccion(index: number, data: IAccionSecundariaAplicada) {
        let acciones = [
            ...this.acciones.value.slice(0, index),
            data,
            ...this.acciones.value.slice(index + 1)
        ];

        this.acciones.next(acciones);
        this.dirty.next(true);
    }

    enterEditMode() {
        this.savepoint = cloneDeep(this.acciones.value);
        this.editing.next(true);
    }

    save() {
        const isEditing = this.editing.value;
        const isDirty = this.dirty.value;

        if (isEditing) {
            if (isDirty) {
                this.savepoint = cloneDeep(this.acciones.value);
            }
            this.editing.next(false);
        }
    }

    cancel() {
        const isEditing = this.editing.value;
        const isDirty = this.dirty;

        if (isEditing) {
            if (isDirty) {
                this.acciones.next(this.savepoint);
            }
            this.editing.next(false);
        }
    }

    private fetchTiposAccion() {
        const url = URLHelper.concat(environment.API_BASE_URL, 'acciones');
        this.http.get<TipoAccion[]>(url).subscribe(x => this.tiposAcciones.next(x));
    }

    private fetchTiposDescuento() {
        of([
            { value: 'F', label: 'Monto Fijo' },
            { value: 'V', label: 'Porcentaje' }
        ]).subscribe(x => this.tiposDescuentos.next(x));
    }
}