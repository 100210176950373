<div class="table-responsive p-2">

  <ng-container *ngIf="data && data.length; else nodata">
    <div *ngIf="paginado" class="d-flex justify-content-end align-items-center">
      <page-selector *ngIf="data.length > 0" [collectionSize]="data.length"></page-selector>
    </div>
    <table class="align-middle text-truncate mb-0 table table-borderless table-hover">
      <thead>
        <tr>
          <ng-container *ngIf="fechas">
            <th class="text-left">Fecha Inicio</th>
            <th class="text-left">Fecha Fin</th>
          </ng-container>
          <th class="text-left">Org. Vtas.</th>
          <th class="text-left">Canal</th>
          <th class="text-left">Categoría</th>
          <th class="text-left">Producto</th>
          <th class="text-center">Condición</th>
          <!-- <th class="text-right">Cant. Base</th> -->
          <!-- <th class="text-right">UM</th> -->
          <th class="text-right">Valor</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let a of (paginado ? (data | pagination) : data)">
          <ng-container *ngIf="fechas">
            <td class="text-left">{{ a.FECHA_INICIO | date:'dd/MM/yyyy' }}</td>
            <td class="text-left">{{ a.FECHA_FIN | date: 'dd/MM/yyyy' }}</td>
          </ng-container>
          <td class="text-left">{{ a.ORGVTA || 'TODAS' }}</td>
          <td class="text-left">{{ a.CANAL || 'TODOS' }}</td>
          <td class="text-left">{{ a.CATEGORIA || (!a.MATERIAL ? 'TODAS' : '-') }}</td>
          <td class="text-left">{{ a.MATERIAL || 'TODOS' }}</td>
          <td class="text-center">{{ a.CONDICION }}</td>
          <!-- <td class="text-right">{{ a.CANT_BASE > 0 ? (a.CANT_BASE | number:'1.0-2') : '-' }}</td> -->
          <!-- <td class="text-right">{{ a.CANT_BASE > 0 && a.UM_BASE ? a.UM_BASE : '-' }}</td> -->
          <td class="text-right">{{ (a.VALOR / 100) | percent: '1.0-2'}}</td>
        </tr>
      </tbody>
    </table>
  </ng-container>
  <ng-template #nodata>
    <div class="p-3">
      <p>No hay acuerdos para mostrar</p>
    </div>
  </ng-template>
</div>