import { HttpClient, HttpHeaders, HttpParams, HttpRequest, HttpResponse } from '@angular/common/http';
import { ApiClient } from './api-client.interface';
import { Injectable, Inject, inject } from '@angular/core';
import { Observable, of, OperatorFunction, UnaryFunction, MonoTypeOperatorFunction } from 'rxjs';
import { environment } from 'src/environments/environment';
import { catchError, filter, map } from 'rxjs/operators';
import { URLHelper } from '../url-helper.helper';
import { Navigable } from './navigable.interface';
import { Page, PageRequest } from '../datasource/page.model';

export class GenericApiClient<T> implements ApiClient<T>, Navigable {
    private baseUrl: string;

    constructor (private resourceURI: string, private httpClient: HttpClient) {
        this.baseUrl = this.getURL(environment.API_BASE_URL, this.resourceURI);
    };

    list(params?: any): Observable<T[]> {
        let url = this.getURL(this.baseUrl);
        return this.httpClient.get<T[]>(url, { params })
            .pipe(catchError(this.handleError<T[]>('list')));
    }

    page(page: PageRequest<T>, params: any = {}): Observable<Page<T>> {
        let url = this.getURL(this.baseUrl);

        params = {...params, page: page.page};
        if (page.sort) {
            const sort = page.sort.property;
            params['sort'] = page.sort.order == 'asc'
                ? sort
                : `-${sort}`;
        }

        let headers = new HttpHeaders();
        headers.append('Access-Control-Expose-Headers', 'X-Pagination-Total-Count, X-Pagination-Current-Page, X-Pagination-Per-Page');

        return this.httpClient.get<T[]>(url, {params, observe: 'response', headers })
            .pipe(
                map(response => ({
                    content: response.body,
                    totalElements: Number.parseInt(response.headers.get('X-Pagination-Total-Count')),
                    number: Number.parseInt(response.headers.get('X-Pagination-Current-Page')),
                    size: Number.parseInt(response.headers.get('X-Pagination-Per-Page'))
                })),
                catchError(this.handleError<Page<T>>('page'))
            )
    }

    retrieve(identifier?: any, params?: any): Observable<T> {
        let url = identifier ? this.getURL(this.baseUrl, identifier) : this.baseUrl;

        return this.httpClient.get<T>(
            this.getURL(url), { params }
        ).pipe(catchError(this.handleError<T>('retrieve')));
    }

    create(body: any, params?: any): Observable<T> {
        return this.httpClient.post<T>(
            this.getURL(this.baseUrl),
            body, 
            { params }
        ).pipe(catchError(this.handleError<T>('create')));
    }

    update(identifier: any, body: any, params?: any): Observable<T> {
        return this.httpClient.put<T>(
            this.getURL(this.baseUrl, identifier as string),
            body,
            { params }
        ).pipe(catchError(this.handleError<T>('update')));
    }

    delete(identifier: any, params?: any): Observable<T> {
        return this.httpClient.delete<T>(
            this.getURL(this.baseUrl, identifier as string),
            { params }
        ).pipe(catchError(this.handleError<T>('retrieve')));
    }

    
    navigate<U>(uri: string): ApiClient<U> {
        return new GenericApiClient<U>(
            this.getURL(this.resourceURI, uri),
            this.httpClient
        );
    }

    of(id: number | string): Navigable {
        return this.navigate<T>(id.toString()) as unknown as Navigable;
    }


    private getURL(...components: string[]): string {
        return URLHelper.concat(...components);
    }

    private handleError<T>(operation = 'operation', result?: T) {
        return (error: any): Observable<T> => {
          console.error(error);
          this.log(`${operation} failed: ${error.message}`);
          throw error;
          // return of(result as T);
        };
      }
    
      private log(message: string) {
        console.log(message);
      }

}