<div class="main-card mb-3 card">
  <div class="card-header">
    <div class="card-header-title font-size-lg text-capitalize font-weight-normal">Pedidos</div>
    <!-- 
        <div class="btn-actions-pane-right">
        </div>
       -->
  </div>

  <ng-container *ngIf="(data$ | async) as data; else noData">
    <div *ngIf="data" class="p-2">
      <div class="form-group form-inline mb-2 justify-content-start float-left">
        <button class="my-2 btn btn-default btn-hover"
          (click)="service.toggleCollapse()">{{ !service.getCollapse() ? '- Colapsar Todo' : '+ Expandir Todo' }}</button>
        <div class="ml-2">
          <input class="form-control ml-2" type="text" id="searchBox" name="searchBox" placeholder="Buscar..."
            [formControl]="service.searchBox" />
        </div>
        <div class="ml-2 form-check form-check-inline">
          <input class="form-check-input" type="radio" id="todos" [formControl]="filtroEstado" [value]="false" />
          <label class="form-check-label" for="todos">Todos</label>
        </div>
        <div class="ml-2 form-check form-check-inline">
          <input class="form-check-input" type="radio" id="pendientes" [formControl]="filtroEstado" [value]="true" />
          <label class="form-check-label" for="pendientes">Pendientes de Aprobación</label>
        </div>
      </div>
    </div>
    <ng-container *ngIf="service.groups$|async as groups">
      <div *ngIf="groups.length > 0" class="float-right">
        <page-selector [collectionSize]="groups.length"></page-selector>
      </div>
    </ng-container>
    <div class="table-responsive p-2">
      <ng-container *ngIf="(service.groups$|async) as groups">
        <ng-container *ngIf="groups.length > 0; else noResults">
          <table class="align-middle text-truncate table table-hover">
            <thead>
              <tr>
                <th class="text-left">#</th>
                <th class="text-left">Fecha Requerida</th>
                <th class="text-left">Nº Cliente</th>
                <th class="text-center">Nombre / Razón Social</th>
                <th class="text-center">Vendedor</th>
                <th class="text-center">Estado</th>
                <th class="text-right">Importe Total</th>
                <th class="text-right">Kilos Total</th>
                <th class="text-center">Acciones</th>
              </tr>
            </thead>
            <tbody *ngFor="let group of (groups | pagination)">
              <tr class="table-sm st-group-header" *ngIf="group.field !== '*'" (click)="group.toggleCollapse()">
                <th>
                  <button type="button" class="btn btn-default">
                    <span class="vsm-title"><i
                        [className]="group.collapsed ? 'vsm-arrow pe-7s-angle-right' : 'vsm-arrow pe-7s-angle-down'"
                        style="font-weight: bold; font-size: large;"></i></span>
                  </button>
                </th>
                <th colspan="8">{{ group.label | uppercase }} ({{ group.length }})</th>
              </tr>
              <ng-container *ngIf="!group.collapsed">
                <ng-container *ngFor="let item of group.data">
                  <tr (click)="service.goToDetail(item)">
                    <td class="text-left"> {{ item.id }} </td>
                    <td class="text-left">{{ item.REQ_DATE_H | date:'dd/MM/yyyy' }}</td>
                    <td class="text-left">{{ item.KUNNR }}</td>
                    <td class="text-center">{{ item.kUNNR.NAME }} {{ item.kUNNR.NAME2 }}</td>
                    <td class="text-center">{{ item.vendedor.PERNR }} / {{ item.vendedor.firstname }}
                      {{ item.vendedor.lastname }}
                      {{ item.vendedor.sn }}</td>
                    <td class="text-center">
                      <app-badge-estado [orderHeader]="item"></app-badge-estado>
                    </td>
                    <td class="text-right">{{ item.importe_total | currency:item.CURRENCY }}</td>
                    <td class="text-right">{{ item.peso_total | number:'1.0-2' }} kg</td>
                    <td class="text-center">
                      <span role="group" class="btn-group-sm btn-group">
                        <button class="btn-shadow btn btn-secondary" (click)="service.goToDetail(item)">Ver
                          Detalle</button>
                      </span>
                    </td>
                  </tr>
                </ng-container>
              </ng-container>
            </tbody>
          </table>
        </ng-container>
        <ng-template #noResults>
          <div class="card-body">
            <p>No se encontraron resultados</p>
          </div>
        </ng-template>
      </ng-container>
    </div>
  </ng-container>
  <ng-template #noData>
    <div class="card-body">
      <p>No hay datos para mostrar</p>
    </div>
  </ng-template>
</div>