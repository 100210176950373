import { Component, Input } from "@angular/core";
import { NotificationService } from "src/app/utils/notifications/notification.service";
import { IAccionSecundariaAplicada } from "../condiciones.models";
import { AccionesManualesService } from "./acciones-manuales.service";

@Component({
    selector: 'app-cc-acciones-manuales',
    templateUrl: './acciones-manuales.component.html',
    styles: [
        `.card-header button { margin: 0.2em auto }`
    ]
})
export class AccionesManualesComponent {
    readonly acciones$ = this.service.acciones$;
    readonly editing$ = this.service.editing$;

    @Input() set editable (value: boolean) {
        if (value) {
            this.service.enterEditMode();
        }
    }

    constructor(
        private readonly service: AccionesManualesService,
        private readonly notiService: NotificationService) {}

    addAccion(x: IAccionSecundariaAplicada) {
        this.service.addAccion(x);
    }

    updateAccion(index: number, data: IAccionSecundariaAplicada) {
        this.service.updateAccion(index, data);
    }

    removeAccion(index: number) {
        this.notiService.confirm('¿Está seguro de quitar la acción?', 'Acciones Manuales').then(remove => {
            if (remove) {
                this.service.removeAccion(index);
            }
        });
    }

    enterEditMode() {
        this.service.enterEditMode();
    }

    cancel() {
        this.service.cancel();
    }

    save() {
        this.service.save();
    }
}