import { NgModule } from "@angular/core";
import { SharedModule } from '../shared.module';
import { WidgetsModule } from '../widgets/widgets.module';
import { SituacionTableComponent } from './situacion-table.component';
import { SituacionVendedorComponent } from './situacion-vendedor.component';
import { SituacionComponent } from './situacion.component';

@NgModule({
    declarations: [
        SituacionTableComponent, 
        SituacionVendedorComponent, 
        SituacionComponent
    ],
    imports: [
        SharedModule,
        WidgetsModule
    ],
    exports: [
        SituacionComponent
    ]
})
export class SituacionModule {}