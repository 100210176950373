<div class="main-card mb-3 card">
   <div class="card-body">
      <h5 class="card-header-title font-size-lg text-capitalize font-weight-normal" *ngIf="title">{{ title }}</h5>
      <ng-container *ngIf="files.length > 0; else nofiles">
         <div class="scroll-area">
            <div class="scrollbar-container">
               <perfect-scrollbar [autoPropagation]="true">
                  <ul class="list-group list-group-flush">
                     <li class="list-group-item" *ngFor="let file of files">
                        <app-file [file]="file" [dateFormat]="dateFormat"></app-file>
                     </li>
                  </ul>
               </perfect-scrollbar>
            </div>
         </div>
      </ng-container>
      <ng-template #nofiles>
         <p>No hay datos.</p>
      </ng-template>
   </div>
</div>