import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { pluck } from 'rxjs/operators';
import { SituacionService } from './situacion.service';

@Component({
    selector: 'app-chat',
    templateUrl: './situacion.component.html',
})
export class SituacionComponent implements OnInit {

    selectedSituacion$ = this.route.data.pipe(
        pluck('situacion')
    );
    
    constructor(public router: Router, public route: ActivatedRoute, public service: SituacionService) {
        
    }

    ngOnInit() {
    }
}
