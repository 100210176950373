import { DecimalPipe } from '@angular/common';
import { Component, Input } from "@angular/core";

@Component({
    selector: 'app-number-box',
    template: `
    <div class="card main-card mb-3">
        <ul class="list-group list-group-flush">
            <li class="list-group-item">
                <div class="widget-content p-0">
                    <div class="widget-content-outer">
                        <div class="widget-content-wrapper">
                            <div class="widget-content-left">
                                <div class="widget-heading">{{ title }}</div>
                            </div>
                            <div class="widget-content-right">
                                <div [className]="'widget-numbers text-' + displayClass">
                                    <ng-content></ng-content>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </li>
        </ul>
    </div>
`
})
export class NumberBoxComponent {
    @Input() title: string;
    @Input() displayClass: string = 'primary';
}