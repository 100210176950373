<div class="vertical-timeline-item vertical-timeline-element">
    <div>
        <span class="vertical-timeline-element-icon bounce-in"><i class="badge badge-dot badge-dot-xl badge-{{ event.level || 'info' }}"> </i></span>
        <div class="vertical-timeline-element-content bounce-in">
            <h4 class="timeline-title">{{ event.title }}</h4>
            <p>{{ event.description.trim() }}.
                <span class="text-muted"><small>{{ event.timestamp | date:dateFormat }}</small></span>
            </p>
            <p *ngIf="event.observaciones"><small>{{event.observaciones}}</small></p>
        </div>
    </div>
</div>