import { Component, OnInit, Injectable } from "@angular/core";
import { ThemeOptions } from "../../../../../theme-options";

import { UserService } from "src/app/user.service";
import { User } from "src/app/models/User.model";
import { environment } from "src/environments/environment";
import { URLHelper } from "src/app/utils/url-helper.helper";
import {
  ANALISTA_ADM_VTAS,
  DIRECTOR,
  GCIA_GRAL,
  GCIA_COM,
  JEFE_DE_VENTAS,
  VENDEDOR,
  SUPERVISOR_DE_VENTAS,
} from "src/app/pedidos/pedidos.permissions";

@Injectable()
@Component({
  selector: "app-user-box",
  templateUrl: "./user-box.component.html",
})
export class UserBoxComponent implements OnInit {
  public defaultAvatar: string = "assets/img/user.png";
  public LOGOUT_URL = URLHelper.concat(
    environment.API_BASE_URL,
    "/_saml/logout"
  );
  roleLabel = "Usuario";

  public nombre: string;
  public apellido: string;
  public estoyImpersonado: boolean;
  public tengoAutorizaciones: boolean;

  constructor(public globals: ThemeOptions, public service: UserService) {
    this.setRoleLabel();
    this.tengoAutorizaciones = false;
    let itoken = JSON.parse(localStorage.getItem("impersonate"));
    this.estoyImpersonado =
      itoken !== null && itoken !== undefined && itoken !== "";
  }

  ngOnInit() {}

  toggleDrawer() {
    this.globals.toggleDrawer = !this.globals.toggleDrawer;
  }

  get currentUser(): User {
    let itoken = JSON.parse(localStorage.getItem("impersonate"));
    this.estoyImpersonado =
      itoken !== null && itoken !== undefined && itoken !== "";
    if (this.estoyImpersonado) {
      this.nombre = "ACTUANDO COMO: " + itoken.firstname;
      this.apellido = itoken.lastname;
    } else {
      this.nombre = this.service.currentUser.firstname;
      this.apellido = this.service.currentUser.lastname;
    }

    if (this.service.currentUser.autorizaciones.length > 0) {
      this.tengoAutorizaciones = true;
    }
    return this.service.currentUser;
  }

  setRoleLabel() {
    const obtenerRoles = () => Promise.all([
      this.service.currentUserCan(DIRECTOR),
      this.service.currentUserCan(GCIA_GRAL),
      this.service.currentUserCan(GCIA_COM),
      this.service.currentUserCan('Resp_Trade_Mkt'),
      this.service.currentUserCan(ANALISTA_ADM_VTAS),
      this.service.currentUserCan(JEFE_DE_VENTAS),
      this.service.currentUserCan(SUPERVISOR_DE_VENTAS),
      this.service.currentUserCan(VENDEDOR)
    ]);

    const labels = [
      'Director',
      'Gerente General',
      'Gerente Comercial',
      'Resp. Trade Mkt.',
      'Analista Adm. Ventas',
      'Jefe de Ventas',
      'Supervisor de Ventas',
      'Vendedor/a'
    ];

    // Busco el primero que dió true en la promise y uso el indice para obtener el label
    // (Notar que están ordenados por jerarquía)

    obtenerRoles()
      .then(roles => roles.findIndex(x => x))
      .then(index => labels[index])
      .then(label => this.roleLabel = label)
  }
}
