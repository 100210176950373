import { NgModule } from "@angular/core";
import { AngularEditorModule } from '@kolkov/angular-editor';
import { SharedModule } from '../shared.module';
import { AniomesControlComponent } from "./aniomes-control.component";
import { BadgeEstadoComponent } from "./badge-estado.component";
import { BigBreadcrumbComponent } from "./big-breadcrumb/big-breadcrumb.component";
import { ButtonBackComponent } from './button-back.component';
import { ButtonGroupSelectorComponent } from "./button-group-selector.component";
import { DrawerComponent } from './drawer.component';
import { FileComponent } from './files/elements/file.component';
import { FilesComponent } from './files/files.component';
import { FooterComponent } from "./footer.component";
import { InformacionComponent } from './informacion/informacion.component';
import { NumberBoxComponent } from './number-box.component';
import { ProgressBoxComponent } from './progress-box.component';
import { ResolverAutorizacionModal } from "./resolver-autorizacion-modal";
import { EventComponent } from './timeline/elements/event.component';
import { TimelineComponent } from './timeline/timeline.component';

@NgModule({
    declarations: [
        InformacionComponent,
        ButtonBackComponent,
        DrawerComponent,
        NumberBoxComponent,
        ProgressBoxComponent,
        FileComponent,
        FilesComponent,
        EventComponent,
        TimelineComponent,
        BadgeEstadoComponent,
        FooterComponent,
        BigBreadcrumbComponent,
        AniomesControlComponent,
        ButtonGroupSelectorComponent,
        ResolverAutorizacionModal,
    ],
    imports: [
        SharedModule,
        AngularEditorModule,
    ],
    exports: [
        FileComponent,
        FilesComponent,
        TimelineComponent,
        ButtonBackComponent,
        NumberBoxComponent,
        ProgressBoxComponent,
        InformacionComponent,
        DrawerComponent,
        BadgeEstadoComponent,
        FooterComponent,
        BigBreadcrumbComponent,
        AniomesControlComponent,
        ButtonGroupSelectorComponent,
        ResolverAutorizacionModal
    ]
})
export class WidgetsModule {}