export const VER_PEDIDOS_PERMISSION = 'verPedidos';
export const CREAR_PEDIDOS_PERMISSION = 'crearPedidos';
export const AUTORIZAR_PEDIDOS_PERMISSION = 'autorizarPedidos';
export const MODIFICAR_PEDIDOS_PERMISSION = 'modificarPedidos';
export const DIRECTOR = 'DIRECTOR';
export const GCIA_GRAL = 'GCIA_GRAL';
export const GCIA_COM = 'GCIA_COM';
export const VENDEDOR = 'VENDEDOR';
export const JEFE_DE_VENTAS = 'JDV';
export const SUPERVISOR_DE_VENTAS = 'SDV';
export const ANALISTA_ADM_VTAS = 'ANALISTA_ADM_VTAS';