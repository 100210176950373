import { Component, Input } from "@angular/core";

@Component({
    selector: 'app-footer',
    template: `
<div [ngClass]="{ 'fixed-footer': fixed }">
    <div class="app-footer">
        <div class="app-footer__inner">
            <!-- <div class="app-footer-left">
            </div> -->
            <div class="app-footer-right">
                <ng-content></ng-content>
            </div>
        </div>
    </div>
</div>
`
})
export class FooterComponent {
    @Input() fixed: boolean = false;
}