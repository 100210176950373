<div class="app-page-title">
  <div class="page-title-wrapper">
    <div class="page-title-heading">
      <div class="page-title-actions">
      </div>
      <div class="page-title-icon">
        <img class="icon pe-7s-id" [src]="cliente?.avatar" />
      </div>
      <div>
        {{ cliente?.NAME }} {{ cliente?.NAME2 }}
        <div class="page-title-subheading">
          {{ cliente?.KUNNR }}
        </div>
      </div>
    </div>
  </div>
</div>

<div class="main-card mb-3 card">
  <div class="card-body">
    <div *ngIf="nuevoPedido" class="form-row">
      <div class="col-md-12">
        <app-pedido-header [form]="formPedido" [esNuevo]="true" [datosComerciales]="datosComerciales$|async"></app-pedido-header>
      </div>
    </div>
    <ul ngbNav [destroyOnHide]="false" #nav="ngbNav" class="nav-tabs">
      <li ngbNavItem="items" [destroyOnHide]="false">
        <a ngbNavLink>Items</a>
        <ng-template ngbNavContent>
          <app-lista-materiales *ngIf="!(loadingListaPrecios|async)" [estado]="0" [items]="listaMateriales" [currency]="currency" [pesoMinimo]="selDatoCom?.vTWEG?.PESO_MINIMO" [edit]="true"></app-lista-materiales>
          <div *ngIf="(loadingListaPrecios|async)" class="card-body">
            <p>Cargando Lista de Precios...</p>
          </div>
        </ng-template>
      </li>
      <li ngbNavItem="info" [destroyOnHide]="false">
        <a ngbNavLink>Información Adicional</a>
        <ng-template ngbNavContent>
          <app-info-detail title="Informacion Adicional" [editInfo]="true" [ultimaObservacion]="ultimaObservacion"></app-info-detail>
        </ng-template>
      </li>
      <li ngbNavItem="condiciones">
        <a ngbNavLink>Condiciones Comerciales</a>
        <ng-template ngbNavContent>
          <app-cc-aplicadas [edit]="true"></app-cc-aplicadas>
          <app-cc-acciones-manuales [editable]="true"></app-cc-acciones-manuales>
        </ng-template>
      </li>
    </ul>
    <div [ngbNavOutlet]="nav"></div>
  </div>
</div>
<div class="app-footer">
  <div class="app-footer__inner">
    <div class="app-footer-right">
      <div class="row">
        <div class="col">
          <!-- <app-condiciones [edit]="true" (onClose)="onCloseCombos($event)"></app-condiciones> -->
          <app-cc-disponibles [edit]="true" (onClose)="onCloseCombos($event)"></app-cc-disponibles>
        </div>
        <div class="col">
          <app-condiciones-entrega 
            [cliente]="cliente" 
            [datoComercial]="selDatoCom" 
            [interlocutor]="interlocutor"
            [incoterm]="formPedido.value.INCOTERMS1"
            [editable]="true" 
            (save)="onSetCondicionesEntrega($event)">
          </app-condiciones-entrega>
        </div>
        <div class="col">
          <button *ngIf="acuerdos" app-acuerdos-modal [data]="acuerdos" class="btn btn-hover-shine btn-primary text-nowrap">Acuerdos</button>
        </div>
        <div class="col">
        <button class="btn-hover-shine btn btn-secondary" (click)="onReiniciarPedido($event)">
          Reiniciar
        </button>
        </div>
        <div class="col">
          <button class="btn btn-success" [disabled]="progress" (click)="onGuardarPedido($event)">
            Guardar<span [class.spinner]="progress"></span>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

