import { Injectable } from '@angular/core';
import { Group } from './groups.model';

@Injectable({
    providedIn: 'root'
})
export class GroupsService {
    group<T>(data: Group<T>[], groupByProp: string, groupLabelProp: string, collapse?: boolean): Group<Group<T>>[];
    group<T>(data: T[], groupByProp: string, groupLabelProp: string, collapse?: boolean): Group<T>[]
    group<T>(data: T[] | Group<T>[], groupByProp: string, groupLabelProp: string, collapse: boolean = true): Group<T>[] | Group<Group<T>> {
        if (!groupByProp) {
            // Si no tengo 'groupBy' creo un solo grupo con todo,
            // el valor '*' es un valor especial que, entre otras posibilidades,
            // indica que no debe mostrarse cabecera de grupo (indica que es el conjunto universal)
            return [new Group<T>('*', '', '', data)];
        }
        var groups = {};
        data.forEach((i) => {
            var fieldValue = i instanceof Group ? i.data[0][groupByProp] : i[groupByProp];
            // Me fijo si ya existe un grupo para este valor del campo
            if (!groups[fieldValue]) {
                // No existe -> lo creo
                groups[fieldValue] = new Group<T>(
                    groupByProp, 
                    fieldValue || '?', 
                    fieldValue ? this.resolveLabel(i, groupLabelProp) : '(desconocido)'
                );
                if (collapse) {
                    groups[fieldValue].setCollapse(collapse);
                }
            }
            // agrego el item al grupo
            groups[fieldValue].push(i)
        });
        return Object.values(groups);
    }

    moveToLastPosition<T>(groups: Group<T>[], finder: (item: Group<T>) => boolean) {
        var toMove: Group<T>[];
        let iToMove = groups.findIndex(finder);
        if (iToMove >= 0) {
            toMove = groups.splice(iToMove, 1);
            groups.push(...toMove);
        }
        return groups;
    }

    private resolveLabel (data: any, labelProp: string) {
        var tree = labelProp.split('.');
        var label = data instanceof Group ? data.data[0] : data;
        for (let branch of tree) {
            label = label[branch];
        }
        return label;
    }
}