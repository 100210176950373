<!-- TODO Se podrá hacer un template (completo) para edición y otro para no edición para simplificar? -->
<ng-container [formGroup]="itemForm" *ngIf="!['28', '29', '30', '31'].includes(item.mATNR.KONDM)">
  <td class="text-left">
    <app-tags-modal [tags]="item.mATNR.tags"> </app-tags-modal>
  </td>
  <td class="text-right">{{ item.mATNR.MATNR | ltrim:'0' }}</td>
  <td class="text-left">{{ item.mATNR.MAKTX }}</td>
  <td class="text-center small" *ngIf="!edit">
    <app-badge-despacho *ngIf="item.estadoLogistico as e" [estado]="e.estado"></app-badge-despacho>
  </td>
  <td class="text-right" *ngIf="!edit">
    <ng-container *ngIf="item.estadoDespacho as e">{{ e.pendiente }}</ng-container>
  </td>
  <td class="text-right">{{ tieneDisplay ? getDisplay() : '' }}</td>

  <td *ngIf="edit" class="text-center">
    <app-pallets-badge *ngIf="getMostrarUNporCJ() &&
        CANTIDAD?.value > 0 && UNIDAD?.value === 'CJ' && registraCajasXPallet()
      " [cajasRestantes]="getCajasParaCompletar()" [completos]="getPalletsCompletos()"></app-pallets-badge>
  </td>

  <td *ngIf="edit; else cantidadView">
    <ng-container *ngIf="!editaCantidad">
      <ng-select style="width: 90%; margin-left: 10%" formControlName="CANTIDAD" [clearable]="false" [items]="[]"
        notFoundText="Solo 0">
      </ng-select>
    </ng-container>
    <ng-container *ngIf="editaCantidad">
      <input [readonly]="!editaCantidad" style="width: 50pt; float: right; clear: both" placeholder=""
        type="number" min="0" class="form-control-sm form-control pedido-cantidad" formControlName="CANTIDAD"
        (click)="$event.target.select()" />
    </ng-container>
  </td>
  <ng-template #cantidadView>
    <td class="text-right">
      {{ item.CANTIDAD | number: "1.0-2" }}
      <span *ngIf="item.cantidadBonificada > 0" [ngClass]="{'text-decoration-line-through': item.bonificacionAnulada }">
        (+{{ item.cantidadBonificada | number: "1.0-2" }})
      </span>
    </td>
  </ng-template>

  <td *ngIf="edit; else umView" class="text-right">
    <ng-select *ngIf="getMostrarUnidadMedida()" formControlName="UNIDAD" [clearable]="false" [items]="unidadesMedida$ | async" bindValue="KMEIN"
      bindLabel="kMEIN.descripcion"></ng-select>
  </td>
  <ng-template #umView>
    <td class="text-right">
      {{ item.unidad_label }}
    </td>
  </ng-template>
  <td class="text-right">
    <ng-container *ngIf="getMostrarPeso()">
      {{ getKilos() | number: "1.0-2" }} kg
    </ng-container>
  </td>
  <!-- <td class="text-right">{{ item.mATNR.MEINS }}</td> -->
  <td class="text-right" [style.text-decoration]="isPrecioFijo ? 'line-through' : 'none'">
    {{ (item.KBETR | currency: currency) }}
  </td>
  <td class="text-right" [style.text-decoration]="isPrecioFijo ? 'line-through' : 'none'">
    {{ tieneDisplay && (item.KBETR > 0) ? (item.KBETR * getDisplay() | currency: currency) : '' }}
  </td>

  <!-- BEGIN Descuento Cliente -->
  <td *ngIf="edit && habilitarDtoAdicional(); else descuentoView" class="text-right">
    <input *ngIf="item.CANTIDAD > 0" style="width: 50pt; float: right; clear: both" 
      placeholder="" type="number" min="0.00"
      class="form-control-sm form-control" 
      formControlName="DESCUENTO" step=".01" 
      (click)="$event.target.select()" />
  </td>
  <ng-template #descuentoView>
    <td class="text-right">
      <ng-container *ngIf="item.DESCUENTO > 0">
        {{
        item.COND_TYPE == "PR00"
        ? (item.DESCUENTO || 0 | currency: currency)
        : ((item.DESCUENTO || 0) / 100 | percent: "1.0-2")
        }}
      </ng-container>
    </td>
  </ng-template>
  <td class="text-right">
    <ng-container *ngIf="!itemForm.errors?.CANTIDAD">
      <ng-container *ngIf="item.CANTIDAD > 0 && item.DESCUENTO > 0">
        {{ getDescuento() | currency: currency }}
      </ng-container>
    </ng-container>
  </td>
  <!-- END Descuento Cliente -->

  <!-- BEGIN Descuento Combo -->
  <td class="text-right">
    {{
    item.DESCUENTO_COMBO > 0
    ? ((item.DESCUENTO_COMBO || 0) / 100 | percent: "1.0-2")
    : ""
    }}
  </td>
  <td class="text-right">
    <ng-container *ngIf="!itemForm.errors?.CANTIDAD">
      <ng-container *ngIf="item.CANTIDAD > 0 && item.DESCUENTO_COMBO > 0">
        {{ getDescuentoCombos() | currency: currency }}
      </ng-container>
    </ng-container>
  </td>
  <!-- END Descuento Combo -->

  <td class="text-right">
    <ng-container *ngIf="item.CANTIDAD > 0 && itemForm.errors?.CANTIDAD; else ok">
      <span class="text-danger">{{ itemForm.errors.CANTIDAD.value }}</span>
    </ng-container>
    <ng-template #ok>
      <ng-container *ngIf="item.CANTIDAD > 0">
        {{ getImporte() | currency: currency }}
      </ng-container>
    </ng-template>
  </td>
  <td *ngIf="edit" class="text-center">
    <div *ngIf="item.CANTIDAD > 0 && estado === 0" app-semaforo-stock [item]="item" [itemForm]="itemForm"></div>
  </td>
  <td class="text-center">
    <input *ngIf="item.CANTIDAD > 0" type="checkbox" id="lm-bonifica" formControlName="bonifica_canal">
  </td>
</ng-container>