import { Component, Input } from "@angular/core";

@Component({
    selector: 'app-pallets-badge',
    template: `
        <ng-container *ngIf="cajasRestantes as cajas; else esCompleto">
            <span class="badge badge-danger"
                triggers="hover focus click"
                [ngbTooltip]="incompletosTooltip">
                {{ completos }} / {{ completos + 1 }}
            </span>
            <ng-template #incompletosTooltip>
                {{ 'Falta' | pluralizeIf:(cajas > 1):'n' }} {{ cajas }} {{ 'caja' | pluralizeIf:(cajas > 1) }} para completar {{ (completos || 0) + 1 }} {{ 'pallet' | pluralizeIf:(completos > 0) }}
            </ng-template>
        </ng-container>
        <ng-template #esCompleto>
            <span class="badge badge-success align-middle"
                triggers="hover focus click"
                [ngbTooltip]="completosTooltip">
                {{ completos }} / {{ completos }}
            </span>
            <ng-template #completosTooltip>
                {{ completos === 1 ? 'Se completó 1 pallet' : 'Se completaron ' + completos + ' pallets' }}
            </ng-template>
        </ng-template>
    `
})
export class PalletsBadgeComponent {
    @Input() completos: number;
    @Input() cajasRestantes: number;
}