import { Component, Input } from "@angular/core";
import { ComboGrupoAplicado, Condicion, CondicionVariante } from 'src/app/condiciones-comerciales/condiciones.models';
import { CondicionesService } from '../condiciones.service';
import { OrderHeaderIn } from 'src/app/pedidos/models/Order.model';
import { NotificationService } from "src/app/utils/notifications/notification.service";

@Component({
    selector: 'app-cc-aplicada',
    templateUrl: './condicion-comercial-aplicada.component.html'
})
export class CondicionComercialAplicadaComponent {
    private _condicion: Condicion;
    private _variante: CondicionVariante;
    
    botonQuitarHabilitado: boolean = true;
    
    get esHabilitada (): boolean { return this.condicion?.habilitado };

    get comboGrupoAplicados() { return this.getGrupos('comboGrupoAplicados'); }
    get entregaGrupoAplicados() { return this.getGrupos('entregaGrupoAplicados'); }

    @Input() pedido: OrderHeaderIn;

    @Input() set condicion (value: Condicion) {
        this._condicion = value;
        this.variante = this.condicion.condicionVariantes
            .find(x => x.id == this.condicion.variante_id);

        if (this.condicion.acciones) {
            for (let accion of this.condicion.acciones) {
                let acc = this.variante.condicionVarianteAccionsecs.find(x => x.accion_id == accion.accion_id);
                if (acc) {
                    acc.valor = accion.valor;
                    acc.contenido = accion.contenido;
                    acc.solicitudSecundaria = accion.solicitudSecundaria;
                }
            }
        }
    }

    get condicion() {
        return this._condicion;
    }

    get variante(): CondicionVariante {
        return this._variante;
    }

    set variante(v: CondicionVariante) {
        this.condicion.variante_id = v?.id;
        this._variante = v;
    }

    get secundarias() {
        if (!this.variante || !this.variante.condicionVarianteAccionsecs) {
            return [];
        }
        return this.variante.condicionVarianteAccionsecs
            .filter(x => x.habilitado || this.condicion.acciones?.some(y => y.accion_id == x.accion_id))
    }

    @Input() edit: boolean = false;

    constructor(private condicionesService: CondicionesService, private notiService: NotificationService) {}

    onQuitar() {
        this.botonQuitarHabilitado = false;
        this.notiService.confirm(`¿Quitar la condición comercial '${this.condicion.nombre}'?`, 'Confirmar')
            .then(confirma => {
                if (confirma) {
                    this.condicionesService.unselect(this.condicion);
                }
            })
            .finally(() => this.botonQuitarHabilitado = true);
    }

    private getGrupos(prop: string) {
        if (!this.pedido || !this.pedido.condicionVarianteOrderHeaderIns) {
            return undefined;
        }
        return this.pedido.condicionVarianteOrderHeaderIns
            .filter(x => x.condicion_variante_id == this.variante.id)
            .map(x => x[prop])
            .find(() => true);
    }
}