import { Component, Input } from "@angular/core";
import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { ifNotNullOrUndefined } from 'src/app/utils/operators';
import { ClientesService } from '../clientes.service';
import { Cliente, Interlocutor } from '../models/Cliente.model';

const equalsInProps = (x: Interlocutor, y: Interlocutor, props: (keyof Interlocutor)[]) =>
    props.every(prop => x[prop] === y[prop])

/**
 * La condición del filtro se lee: 
 * "El índice del elemento actual es identico al indice del primer elemento 
 * cuyas propiedades (señaladas en 'props') son identicas a las del elemento actual"
 */
const selectDistinct = (arr: Interlocutor[], props: (keyof Interlocutor)[]) =>
    arr.filter((x, i, arr) => i === arr.findIndex(y => equalsInProps(x, y, props)));

@Component({
    selector: 'app-clientes-direcciones',
    templateUrl: './clientes-direcciones.component.html'
})
export class ClientesDireccionesComponent {
    private cliente$ = new BehaviorSubject<Cliente>(null);
    @Input() set cliente(value: Cliente) {
        this.cliente$.next(value)
    }

    get cliente(): Cliente {
        return this.cliente$.getValue();
    }

    interlocutores$ = this.cliente$.pipe(
        ifNotNullOrUndefined(),
        map((cliente) => this.clientesService.getInterlocutoresEntrega(cliente)),
        map(direcciones => selectDistinct(direcciones, ['KUNNR', 'KUNN2']))
    );

    constructor(private clientesService: ClientesService) {}

}