import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { distinctUntilChanged, filter } from 'rxjs/operators';
import { IBreadCrumb } from './models/Breadcrum.model';

@Component({
    selector: 'app-breadcrumb',
    templateUrl: './breadcrumbs.component.html',
    styleUrls: ['./breadcrumbs.component.scss']
})
export class BreadcrumbsComponent implements OnInit {
    public breadcrumbs: IBreadCrumb[]

    constructor(private router: Router) {
        this.buildBreadCrumb(this.router.routerState.root).then(x => this.breadcrumbs = x);
    }


    ngOnInit() {
        this.router.events.pipe(
            filter((e): e is NavigationEnd => e instanceof NavigationEnd),
            distinctUntilChanged(),
        ).subscribe((e) => {
            this.buildBreadCrumb(this.router.routerState.root).then(x => this.breadcrumbs = x);
        })
    }


    /**
     * Recursively build breadcrumb according to activated route.
     * @param route
     * @param url
     * @param breadcrumbs
     */
    buildBreadCrumb(route: ActivatedRoute, url: string = '', breadcrumbs: IBreadCrumb[] = []): Promise<IBreadCrumb[]> {
        if (!route) {
            return Promise.resolve(breadcrumbs);
        }

        return new Promise((resolve) => {
            let subscription = route.data.subscribe(data => {
                let label: string, path: string = '';

                if (route.routeConfig) {
                    path = route.routeConfig.path;

                    if (route.routeConfig.data && route.routeConfig.data.breadcrumb !== undefined) {
                        // Si es null, entro igual
                        label = route.routeConfig.data.breadcrumb;
                    } else {
                        label = data.breadcrumb;
                    }
                }

                // If the route is dynamic route such as ':id', remove it
                const lastRoutePart = path.split('/').pop();
                const isDynamicRoute = lastRoutePart.startsWith(':');

                if (isDynamicRoute && !!route.snapshot) {

                    const paramName = lastRoutePart.split(':')[1];
                    path = path.replace(lastRoutePart, route.snapshot.params[paramName]);

                    if (!label || label.startsWith(':')) {
                        let value = route.snapshot.params[paramName].toString();
                        label = value.split('-')
                            .map((x: string) => `${x.charAt(0).toUpperCase()}${x.substring(1)}`)
                            .join(' ');
                    }
                }

                //In the routeConfig the complete path is not available,
                //so we rebuild it each time

                const nextUrl = path ? `${url}/${path}` : url;

                const breadcrumb: IBreadCrumb = {
                    label: label,
                    url: nextUrl,
                };
                // Only adding route with non-empty label
                let newBreadcrumbs = breadcrumb.label ? [...breadcrumbs, breadcrumb] : [...breadcrumbs];

                resolve(this.buildBreadCrumb(route.firstChild, nextUrl, newBreadcrumbs));
            });
        });
    }
}
