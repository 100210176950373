import { Component, OnInit } from '@angular/core';
import { UserService } from './user.service';
import { NgSelectConfig } from '@ng-select/ng-select';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
    title = 'lhtrVendedor';

    constructor(private ngSelectConfig: NgSelectConfig, public userService: UserService) {
        this.userService.currentUser = null;
    }

    ngOnInit() {
        this.ngSelectConfig.notFoundText = "No se encontraron opciones";
        this.ngSelectConfig.appendTo = 'body';
    }

}
