<div class="p-2">
    <app-groups-table 
        [data]="(items$ | async)" 
        [paginate]="true" 
        [groupBy]="groupBy" 
        [groupsDefs]="groupsDefs" 
        [columnDefs]="columnDefs" 
        [ordering]="groupOrdering" 
        [collapse]="collapse"
        (search)="search$.next($event)"
    ></app-groups-table>
</div>
