import { Component, Input } from "@angular/core";
import { BreadcrumbPart } from "./big-breadcrumb.model";

@Component({
    selector: 'app-big-breadcrumb',
    template: `
    <div class="d-flex align-items-middle">
        <span class="align-middle">
            <span *ngFor="let p of parts; let last = last">
                <span class="p-0" [ngClass]="{'btn btn-link': !p.active, 'active': p.active}" (click)="onBreadcrumbClick(p, $event)">
                    {{ p.title }}
                </span>
                <i class="pe-7s-angle-right align-middle" style="font-size: 24pt;" *ngIf="!last"></i>
            </span>
        </span>
    </div>
`,
    styleUrls: ['./big-breadcrumb.component.scss']
})
export class BigBreadcrumbComponent {
    @Input() parts: BreadcrumbPart[] = [];

    onBreadcrumbClick(breadcrumb: BreadcrumbPart, $event: MouseEvent) {
        if (breadcrumb.click) {
            breadcrumb.click($event);
        }
    }
}