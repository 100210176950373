import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { BehaviorSubject, EMPTY, Subscription } from 'rxjs';
import { catchError, distinctUntilChanged, filter, finalize, map, switchMap, tap } from 'rxjs/operators';
import { OrderHeaderIn } from 'src/app/pedidos/models/Order.model';
import { PedidosService } from 'src/app/pedidos/pedidos.service';
import { Cliente } from '../models/Cliente.model';
import { Page, PageRequest } from 'src/app/utils/datasource/page.model';

type ClientesVentasState = {
    cliente: Cliente|null;
    soloActivos: boolean;
    loading: boolean;
    page: number;
    pedidos: Page<OrderHeaderIn>|null;
}

@Component({
    selector: 'app-clientes-ventas',
    templateUrl: './clientes-ventas.component.html'
})

export class ClientesVentasComponent implements OnInit, OnDestroy {
    private pedidosSubscription?: Subscription;

    private readonly state = new BehaviorSubject<ClientesVentasState>({
        cliente: null,
        soloActivos: true,
        loading: false,
        page: 1,
        pedidos: null,
    });

    readonly state$ = this.state.asObservable();


    @Input() set cliente (value: Cliente) {
        this.state.next({ ...this.state.value, cliente: value });
    }

    constructor(public pedidosService: PedidosService) { }

    ngOnInit() { 
        this.setupPedidosSubscription();
    }

    ngOnDestroy(): void {
        this.pedidosSubscription?.unsubscribe();
    }

    setPage(value: number) {
        this.state.next({ ...this.state.value, page: value });
    }

    setSoloActivos(value: boolean) {
        this.state.next({ ...this.state.value, soloActivos: value });
    }

    private setupPedidosSubscription() {
        const checkDistinct = (x: ClientesVentasState, y: ClientesVentasState): boolean => {
            return x.cliente.KUNNR === y.cliente.KUNNR
                && x.page === y.page
                && x.soloActivos === y.soloActivos;
        };

        const pedidos$ = this.state$.pipe(
            filter(state => state.cliente !== null),
            distinctUntilChanged(checkDistinct),
            tap(() => setTimeout(() => this.state.next({ ...this.state.value, loading: true }))),
            switchMap(({ cliente, page, soloActivos }) => {
                const pageRequest = <PageRequest<OrderHeaderIn>> { 
                    page, 
                    size: 10, 
                    sort: { 
                        property: 'DOC_DATE', 
                        order: 'desc' 
                    }
                };

                return this.pedidosService.pagePedidosByCliente(cliente.KUNNR, pageRequest, { activo: soloActivos }).pipe(
                    catchError(() => EMPTY),
                    finalize(() => setTimeout(() => this.state.next({ ...this.state.value, loading: false }))),
                );
            }),
        );

        this.pedidosSubscription = pedidos$.subscribe(pedidos => {
            this.state.next({ ...this.state.value, pedidos });
        });
    }
}