import { formatDate } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject, EMPTY, Observable, Subscription } from 'rxjs';
import { catchError, concatMap, finalize, map, switchMap, tap } from 'rxjs/operators';
import { ClientesService } from 'src/app/clientes/clientes.service';
import { Cliente, ClienteDatoComercial, Interlocutor } from 'src/app/clientes/models/Cliente.model';
import { OrderHeaderIn, OrderHeaderObservacion, OrderItemsIn, OrderPartners } from 'src/app/pedidos/models/Order.model';
import { PedidosService } from 'src/app/pedidos/pedidos.service';
import { AccionesManualesService } from '../condiciones-comerciales/acciones-manuales/acciones-manuales.service';
import { Condicion } from '../condiciones-comerciales/condiciones.models';
import { CondicionesService } from '../condiciones-comerciales/condiciones.service';
import { ListaPreciosService } from '../lista-precios/lista-precios.service';
import { ListaPrecios, MaterialPrVtas } from '../lista-precios/models/ListaPrecios.model';
import { UserService } from '../user.service';
import { NotificationService } from '../utils/notifications/notification.service';
import { ifNotNullOrUndefined, ifNullOrUndefined } from '../utils/operators';
import { minDate } from '../utils/validators/min-date.validator';

@Component({
    selector: 'app-pedidos-new',
    templateUrl: './pedidos-new.component.html',
    styleUrls: ['../../assets/ventas/pedidos/_pedidos.scss'],
    providers: [ AccionesManualesService ]
})
export class PedidosNewComponent implements OnInit, OnDestroy {
    private subscriptions: Subscription[] = [];

    ultimaObservacion: OrderHeaderObservacion
    get TODAY() {
        const today = new Date();
        return formatDate(today, 'yyyy-MM-dd', 'es-AR');
    }

    prodsTemp: MaterialPrVtas[];
    listaMateriales: MaterialPrVtas[] = [];
    nuevoPedido: boolean = true;
    cliente: Cliente;
    pedido: OrderHeaderIn;
    progress: boolean | number = false;
    condiciones = [];

    get direccion_entrega(): OrderPartners {
        return this.interlocutor && {
            ITM_NUMBER: '000000',
            PARTN_NUMB: this.interlocutor.KUNN2,
            PARTN_ROLE: 'WE',
        }
    }

    selectedCondiciones: Condicion[] = [];
    private selectedCondicionesOriginal = [];

    loadingListaPrecios = new BehaviorSubject(false);

    formPedido = this.fb.group({
        fechaRequerida: ['', [Validators.required, minDate(new Date())]],
        ordencompra: [null],
        selDatoCom: [null, [Validators.required]],
        // listaPrecio: [null, [Validators.required]],
        condicionPago: [null, [Validators.required]],
        estado: [0],
        accionesSecundariasManuales: [[]],
        INCOTERMS1: [null, [Validators.required]]
    });

    entregaGratuita: OrderHeaderIn = null;

    startLoading() {
        this.progress = true; // starts spinner
    }

    stopLoading() {
        this.progress = false; // stops spinner
    }

    datosComerciales$: Observable<ClienteDatoComercial[]>;

    get selDatoCom(): ClienteDatoComercial {
        return this.formPedido.get('selDatoCom').value;
    }

    // get listaPrecio(): ListaPrecios {
    //     return this.formPedido.get('listaPrecio').value;
    // }

    get acuerdos() {
        if (!this.cliente || !this.cliente.acuerdos) {
            return null
        }
        if (this.selDatoCom) {
            return this.cliente.acuerdos.filter(x =>
                [null, this.selDatoCom.VKORG].includes(x.VKORG)
                && [null, this.selDatoCom.VTWEG].includes(x.VTWEG)
                // && [null, this.datoComercial.SPART].includes(x.SPART)
            );
        }
        return this.cliente.acuerdos;
    }

    interlocutor: Interlocutor;

    set condicionPago(val: string) {
        this.formPedido.patchValue({condicionPago: val});
    }

    get condicionPago(): string {
        return this.formPedido.get('condicionPago').value;
    }

    currency: string = 'ARS';

    constructor(
        private route: ActivatedRoute,
        public lpService: ListaPreciosService,
        public cliService: ClientesService,
        public pedService: PedidosService,
        public router: Router,
        private fb: FormBuilder,
        private condicionesService: CondicionesService,
        private notiService: NotificationService,
        private readonly accionesManualesService: AccionesManualesService,
        private readonly userService: UserService,
    ) {
        this.ultimaObservacion = new OrderHeaderObservacion();
    }

    async ngOnInit() {
        const clienteId = parseInt(this.route.snapshot.paramMap.get('cliente_id'));

        if (Number.isNaN(clienteId)) {
            return this.router.navigate(['/clientes']);
        }

        this.cliente = await this.cliService.getCliente(clienteId).toPromise();

        if (this.cliente === null) {
            return this.router.navigate(['/clientes']);
        }

        this.datosComerciales$ = this.cliService.getAreasVentas(
            this.cliente.KUNNR,
            this.userService.currentUser.PERNR ?? undefined
        );

        var datoComSource$ = this.formPedido.get('selDatoCom').valueChanges;

        this.subscriptions.push(datoComSource$.pipe(ifNotNullOrUndefined()).subscribe(datoComercial => {
            if (datoComercial.cond_pago) {
                this.condicionPago = datoComercial.cond_pago
            }
        }));

        const listaPreciosSource$ = datoComSource$.pipe(
            ifNotNullOrUndefined(),
            tap(() => this.loadingListaPrecios.next(true)),
            switchMap((datoComercial: ClienteDatoComercial) => this.lpService.getPrecios(datoComercial.id).pipe(
                finalize(() => this.loadingListaPrecios.next(false))
            )),
        );

        this.subscriptions.push(listaPreciosSource$.subscribe((precios: MaterialPrVtas[]) => {
            this.listaMateriales = this.lpService.updateCantidades(precios);
        }));

        const condicionesSource$ = datoComSource$.pipe(
            ifNotNullOrUndefined(),
            switchMap((datoCom => this.lpService.getCondiciones(datoCom.PLTYP, undefined, datoCom.KUNNR)))
        );

        this.subscriptions.push(condicionesSource$.subscribe(condiciones => {
            this.condicionesService.setCondiciones(condiciones);
            this.condicionesService.select(...this.condiciones);
        }));

        this.subscriptions.push(
            datoComSource$.pipe(ifNullOrUndefined())
                .subscribe(() => {
                    this.condicionesService.setCondiciones([]);
                    this.listaMateriales = [];
                })
        );

        this.subscriptions.push(
            this.condicionesService.selectedCondiciones$
                .subscribe(condiciones => {
                    this.selectedCondiciones = [];
                    condiciones.forEach(c => {
                        this.selectedCondiciones.push(c);
                    });
                })
        );

        this.subscriptions.push(
            this.accionesManualesService.acciones$.subscribe(acciones => {
                this.formPedido.patchValue({ accionesSecundariasManuales: acciones });
            })
        )
    }

    ngOnDestroy() {
        this.subscriptions.forEach(s => s.unsubscribe());
        this.condicionesService.cleanUp();
    }


    onReiniciarPedido($event) {
        this.formPedido.reset();
        this.listaMateriales = [];
    }

    onSetCondicionesEntrega({ incoterms, interlocutor }) {
        this.interlocutor = interlocutor;
        this.formPedido.patchValue({ INCOTERMS1: incoterms });
    }

    async onGuardarPedido($event) {
        this.startLoading();

        let newPedido = await this.pedService.prepararPedido(Object.assign({}, this.formPedido.value, {
            fechaRequerida: this.formPedido.get('fechaRequerida'),
            cliente: this.cliente,
            listaMateriales: this.listaMateriales,
            currency: this.currency,
            direccion_entrega: this.direccion_entrega,
            ultimaObservacion: this.ultimaObservacion,
            condicionPago: this.condicionPago,
        }));

        if (!newPedido) {
            this.stopLoading();
            return;
        }

        newPedido.aplicar_combos = [];

        for (let c of this.selectedCondiciones) {
            let combo_data: any = {
                id: c.id,
                variante_id: c.variante_id,
            };

            let variante = c.condicionVariantes.find(x => x.id == c.variante_id);

            if ( ! variante.condicionVarianteAccionsecs) {
                newPedido.aplicar_combos.push(combo_data);
                continue;
            }

            combo_data.acciones_secundarias = [];

            for (let accionSec of variante.condicionVarianteAccionsecs) {
                if (accionSec.tieneMaximo() && accionSec.valor > accionSec.dto_monto) {
                    this.notiService.warning('Existen acciones secundarias con valor mayor al permitido', 'Condiciones Comerciales');
                    this.stopLoading();
                    return;
                }

                if (accionSec.valor < 0) {
                    this.notiService.warning('El valor de la acción secundaria debe ser igual o mayor a 0', 'Condiciones Comerciales');
                    this.stopLoading();
                    return;
                }

                if (accionSec.valor > 0 && !accionSec.tieneContenido()) {
                    this.notiService.warning('Debe ingresar un contenido para las acciones secundarias', 'Condiciones Comerciales');
                    this.stopLoading();
                    return;
                }

                combo_data.acciones_secundarias.push({
                    condicion_variante_id: c.variante_id,
                    accion_id: accionSec.accion_id,
                    valor: accionSec.valor,
                    contenido: accionSec.contenido
                });
            }

            newPedido.aplicar_combos.push(combo_data);
        }

        try
        {
            let data = await this.pedService.createPedido(newPedido).toPromise();

            if (Array.isArray(data)) {
                data = data[0];
            }

            this.router.navigate(['/clientes', this.cliente.id, 'pedidos', data.id]);
        }
        catch (error: any)
        {
            let msg = error.error?.message
                ?? "Ha ocurrido un error al guardar el pedido";

            if (error.error?.errors) {
                const errors = Object.values(error.error.errors)
                    .map((value: string[]) => value.join('\n'))
                    .join('')

                msg = `${msg}\n${errors}`;
            }

            this.notiService.error(msg, 'Error');

            console.log(error);
        }
        finally
        {
            this.stopLoading();
        }
    }

    onCloseCombos($event) {
        if (this.hayCambiosEnCombos()) {
            this.notiService.info("Para aplicar los cambios de selección debe guardar el pedido.", "Selección de Combos");
        }
    }

    hayCambiosEnCombos(): boolean {
        let seQuitaronCombos = this.selectedCondicionesOriginal.some(condicion => !this.condicionesService.isSelected(condicion));
        let seAgregaronCombos = this.selectedCondiciones.some(combo => !this.selectedCondicionesOriginal.some(e => (e.id === combo.id && e.behavior_id === combo.variante_id)));
        return seQuitaronCombos || seAgregaronCombos;
    }

    goBack() {
        this.router.navigate([`/clientes/${this.cliente.id}`]);
    }

    hayItemsSeleccionados() {
        return this.listaMateriales.some(i => i.CANTIDAD > 0);
    }
}
