import { Component, Input, OnInit } from "@angular/core";
import { Situacion } from './models/situacion.model';
import { BehaviorSubject } from 'rxjs';

@Component({
    selector: 'app-situacion-vendedor',
    templateUrl: './situacion-vendedor.component.html'
})
export class SituacionVendedorComponent implements OnInit {

    private situacion$: BehaviorSubject<Situacion> = new BehaviorSubject(null);
    @Input() set situacion (value: Situacion) {
        this.situacion$.next(value);
    }

    get situacion() {
        return this.situacion$.getValue();
    }

    percentageValue: (value: number) => string;

    gaugeValues: any = {
        1: 100,
        2: 50,
        3: 50,
        4: 50,
        5: 50,
        6: 50,
        7: 50
    };

    interval: any;

    constructor() {
        this.percentageValue = function (value: number): string {
            return `${Math.round(value)} / ${this.max}`;
        };
    }

    ngOnInit(): void {
        const updateValues = (): void => {
            this.gaugeValues = {
                1: Math.round(Math.random() * 100),
                2: Math.round(Math.random() * 100),
                3: Math.round(Math.random() * 100),
                4: Math.round(Math.random() * 100),
                5: Math.round(Math.random() * 200),
                6: Math.round(Math.random() * 100),
                7: Math.round(Math.random() * 100)
            };
        };

        const INTERVAL = 5000;

        this.interval = setInterval(updateValues, INTERVAL);
        updateValues();
    }

    ngAfterViewInit(): void {
        // var svg = document.querySelector("svg.gauge"); 
        //svg.setAttribute("viewBox", "0 0 100 50");
        // svg.setAttribute("viewBox", "-5 0 110 50");
        // svg.setAttribute("viewBox", "0 0 95 50");
        // svg.setAttribute("viewBox", "1.8 0 95 50");
        
        // var text = document.querySelector("text.value-text"); 
        // text.setAttribute("y", "45");
    }

    ngOnDestroy(): void {
        clearInterval(this.interval);
    }

}