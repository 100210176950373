import {Component, OnInit} from '@angular/core';
import { Router } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html',
  styles: [],
  providers: []
})
export class ChatComponent implements OnInit {

  heading = 'Chat';
  subheading = 'Tables are the backbone of almost all web applications.';
  icon = 'pe-7s-drawer icon-gradient bg-happy-itmeo';

  chatUrl = 'https://chat.corsisa.com.ar/channel/noticias?layout=embedded';
  sanitizedURL: any;

  constructor(public router: Router, public sanitizer: DomSanitizer) {
    this.sanitizedURL= null;
  }

  ngOnInit() {
    this.sanitizedURL = this.sanitizer.bypassSecurityTrustResourceUrl(this.chatUrl);
  }
}
