import { Component, Input } from "@angular/core";
import { Cliente } from '../models/Cliente.model';

@Component({
    selector: 'app-clientes-vencimientos',
    template: `
    <div class="card">
        <div class="card-header">
            Vencimientos
        </div>
        <div class="card-body">
            <p>Sin datos disponibles para el cliente</p>
            <!-- <div class="widget-content">
                <div class="widget-content-outer">
                    <div class="widget-content-wrapper">
                        <div class="widget-content-left">
                            <div class="widget-heading">Grado de Agotamiento</div>
                        </div>
                        <div class="widget-content-right">
                            <div [className]="'widget-numbers'">{{ 1 | percent: '1.0-2'}}</div>
                        </div>
                    </div>
                </div>
            </div> -->
        </div>

    </div>
`
})
export class ClientesVencimientosComponent {
    @Input() cliente: Cliente;
}