import { BehaviorSubject, combineLatest, Observable, Subject, throwError } from 'rxjs'
import { switchMap, startWith, map, shareReplay, tap, catchError } from 'rxjs/operators';
import { Page, Sort, PaginationEndpoint } from './page.model';
import { SimpleDataSource } from './simple-datasource.interface';

export class PaginationDataSource<T, Q> implements SimpleDataSource<T> {
    private pageNumber = new Subject<number>();
    private sort: BehaviorSubject<Sort<T>>;
    private query: BehaviorSubject<Q>;

    public totalPages: BehaviorSubject<number> = new BehaviorSubject(0);

    public loading: BehaviorSubject<boolean> = new BehaviorSubject(false);

    public page$: Observable<Page<T>>;

    constructor(endpoint: PaginationEndpoint<T, Q>, initialSort: Sort<T>, initialQuery: Q, size = 20) {
        this.sort = new BehaviorSubject<Sort<T>>(initialSort);
        this.query = new BehaviorSubject(initialQuery)
        this.page$ = combineLatest([this.sort, this.query]).pipe(
            switchMap(([sort, filters]) => this.pageNumber.pipe(
                tap(() => this.loading.next(true)),
                startWith(0),
                switchMap(page => endpoint({ page, sort, size }, filters)),
                tap(page => this.totalPages.next(page.totalElements / (page.size || 1)))
            )),
            catchError((e: any) => {
                this.loading.next(false);
                return throwError(e);
            }),
            tap(() => this.loading.next(false)),
            shareReplay(1)
        );
    }

    sortBy(sort: Partial<Sort<T>>): void {
        const lastSort = this.sort.getValue()
        const nextSort = { ...lastSort, ...sort }
        this.sort.next(nextSort)
    }

    fetch(page: number): void {
        this.pageNumber.next(page);
    }

    queryBy(query: Partial<Q>): void {
        const lastQuery = this.query.getValue();
        const nextQuery = { ...lastQuery, ...query };
        this.query.next(nextQuery);
    }

    connect(): Observable<T[]> {
        return this.page$.pipe(map(page => page.content));
    }

    disconnect(): void { }

}