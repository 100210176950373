import { NgModule } from "@angular/core";
import { SharedModule } from "../shared.module";
import { StockModule } from "./stock/stock.module";
import { ListaMaterialesItemComponent } from "./lista-materiales-item.component";
import { ListaMaterialesComponent } from "./lista-materiales.component";
import { PalletsBadgeComponent } from "./pallets-badge.component";
import { TagsModule } from "../tags/tags.module";
import { BadgeDespachoComponent } from "./badge-despacho.component";

@NgModule({
  declarations: [
    BadgeDespachoComponent,
    PalletsBadgeComponent,
    ListaMaterialesItemComponent,
    ListaMaterialesComponent,
  ],
  imports: [
    SharedModule,
    StockModule,
    TagsModule,
  ],
  exports: [ListaMaterialesComponent],
})
export class ListaPreciosModule {}
