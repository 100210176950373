import { Component, Input } from "@angular/core";
import { OrderHeaderIn } from "../pedidos/models/Order.model";

@Component({
  selector: "app-badge-estado",
  template: `
    <span
      class="{{ textOnly ? '' : 'badge' }}"
      style="{{
        fontColor ? 'color:#' + fontColor + ';' : 'color:white;'
      }} background-color:{{ klass }}"
    >
      {{ description }}
    </span>
  `,
})
export class BadgeEstadoComponent {
  klass: string;
  color: string;
  description: string;

  @Input() textOnly: boolean;
  @Input() fontColor: string;
  @Input() set orderHeader(value: OrderHeaderIn) {
    this.klass = this.getEstadoColor(value);
    this.color = this.getEstadoColor(value);
    this.description = this.estadoToString(value);
  }

  getEstadoColor(p: OrderHeaderIn): string {
    return p.estadoColor;
  }

  getEstadoClass(p: OrderHeaderIn): string {
    const LEVELS = {
      "0": { "wflpedido/creado": "secondary", "wflpedido/rechazado": "danger" },
      "1": { "*": "warning" },
      "2": { "wflpedido/factparcial": "info", "*": "success" },
      "21": { "*": "success" },
      "90": { "*": "danger" },
    };

    return LEVELS[p.estado][p.wfl_status] || LEVELS[p.estado]["*"] || "default";
  }

  estadoToString(p: OrderHeaderIn): string {
    return p.estadoDesc || "Desconocido";
  }
}
