import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, Validators } from '@angular/forms';
import { BehaviorSubject, combineLatest, Observable, of, Subscription } from 'rxjs';
import { catchError, debounceTime, delay, distinctUntilChanged, filter, map, pluck, shareReplay, startWith, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { Group } from 'src/app/utils/groups/groups.model';
import { GroupsService } from 'src/app/utils/groups/groups.service';
import { ifNotNull } from 'src/app/utils/operators';
import { StringHelper } from 'src/app/utils/string.helper';
import { ClientesService } from '../clientes.service';
import { Cliente, HistoriaVentas } from '../models/Cliente.model';

type SearchFn = (data: any, term: string) => boolean;

const createMesValidator = (anioCtlName: string, mesCtlName: string) =>
    (control: AbstractControl) => {
        let now = new Date();
        if (control.get(anioCtlName).value < now.getFullYear()) {
            return null;
        }
        if (control.get(mesCtlName).value > now.getMonth()) {
            control.get(mesCtlName).setValue(now.getMonth());
            return null;
            // return ({[mesCtlName]: "Mes/Año no puede ser mayor al actual"});
        }
    };

const createAnioBasedMesesObservable = (ctlAnio: AbstractControl) => 
    ctlAnio.valueChanges.pipe(
        distinctUntilChanged(),
        map(x => x < (new Date).getFullYear() ?
                (new Array(12))
                : (new Array(new Date().getMonth() + 1)
            )
        ),
        map(x => x.fill(0).map((curr, i) => i)),
        delay(0)
    );

@Component({
    selector: 'app-historia-ventas',
    templateUrl: './clientes-historia-ventas.component.html',
    styleUrls: ['./clientes-historia-ventas.component.scss']
})
export class ClientesHistoriaVentasComponent implements OnInit, OnDestroy {
    private subscriptions: Subscription[] = [];

    private cliente$ = new BehaviorSubject<Cliente>(null);

    @Input() set cliente(value: Cliente) { this.cliente$.next(value); }
    get cliente(): Cliente { return this.cliente$.getValue(); }

    loading$ = new BehaviorSubject(false);

    historia$ = this.cliente$
        .pipe(
            ifNotNull(),
            pluck('KUNNR'),
            tap(() => setTimeout(() => this.loading$.next(true))),
            switchMap(KUNNR => this.clientesService.getHistoriaVentas(KUNNR)),
            catchError(() => of(null)),
            tap(() => setTimeout(() => this.loading$.next(false))),
            shareReplay(5)
        );

    
    private collapse$: BehaviorSubject<boolean> = new BehaviorSubject(true);

    private _groups$: BehaviorSubject<Group<any>[]> = new BehaviorSubject([]);

    groups$ = combineLatest([this._groups$, this.collapse$])
        .pipe(
            tap(([groups, collapse]) => {
                groups.forEach(g => {
                    // xq los grupos son de grupos
                    // g.data.forEach(x => x.setCollapse(collapse));
                    g.setCollapse(collapse);
                })
            }),
            map(([groups]) => groups),
            delay(0),
            shareReplay(2)
        );

    constructor(private clientesService: ClientesService, private groupsService: GroupsService, private fb: FormBuilder) {}

    summarizer = (entries) => {
        let summary: Partial<HistoriaVentas> = {
            valorPendFact: 0,
            cantPendFact: 0,
            AEMENGE: 0,
            AENETWR: 0,
            REKZWI2: 0,
            REMENGE: 0,
            GUKZWI2: 0,
            GUNETWR: 0,
            UMNETWR: 0,
            FKIMG: 0
        };
        entries.forEach(entry => {
            Object.keys(summary).forEach(prop => {
                summary[prop] += parseFloat(entry[prop] || 0);
            })
        });
        return summary;
    };

    groupSummarizer = (groups: Group<HistoriaVentas>[]) => this.summarizer(groups.map(x => this.summarizer(x.data)));
    
    ngOnInit() {
        this.subscriptions.push(
            this.items$
                .pipe(
                    filter((data) => ![null, undefined].includes(data)),
                    withLatestFrom(this.collapse$),
                    /* map(([data, collapse]): [Group<HistoriaVentas>[], boolean] => 
                        [this.groupsService.group<HistoriaVentas>(data, 'SPART', 'nombreCategoria', collapse), collapse]), */
                    map(([data, collapse]): Group<HistoriaVentas>[] => 
                        this.groupsService.group(data, 'KONDM', 'nombreSubcategoria', collapse)
                    ),
                    map((categorias: Group<any>[]) => categorias.sort((a, b) => a.label < b.label ? -1 : 1)),
                    map((categorias: Group<any>[]) => this.groupsService.moveToLastPosition<any>(categorias, i => i.value == '?')),
                )
                .subscribe(this._groups$)
            );
    }

    ngOnDestroy() {
        this.subscriptions.forEach(s => s.unsubscribe());
    }

    setCollapse(value: boolean) {
        this.collapse$.next(value);
    }

    getCollapse(): boolean {
        return this.collapse$.getValue();
    }

    toggleCollapse() {
        this.setCollapse(!this.getCollapse());
    }

    searchBox = new FormControl('');

    private now = new Date();
    filterForm = this.fb.group({
        anioDesde: [this.now.getFullYear(), [Validators.max(this.now.getFullYear())]],
        mesDesde: [this.now.getMonth(), [Validators.max(12)]],
        anioHasta: [this.now.getFullYear(), [Validators.max(this.now.getFullYear())]],
        mesHasta: [this.now.getMonth(), [Validators.max(12)]],
        categoria: ['*']
    }, {
        validators: [
            (control: AbstractControl) => {
                let ctlAnioDesde = control.get('anioDesde'), ctlMesDesde = control.get('mesDesde');
                let ctlAnioHasta = control.get('anioHasta'), ctlMesHasta = control.get('mesHasta');

                if (ctlAnioDesde.value < ctlAnioHasta.value) 
                {
                    // Es ok, no importa el mes
                    return null;
                }

                if (ctlAnioDesde.value > ctlAnioHasta.value)
                {
                    // return ({'anioDesde': 'Año desde no puede ser mayor que año hasta'});
                    ctlAnioDesde.setValue(ctlAnioHasta.value);
                    return null;
                }

                if (ctlMesDesde.value > ctlMesHasta.value)
                {
                    // En este punto sabemos que anioDesde == anioHasta
                    // return ({'mesDesde': 'Mes desde no puede ser mayor que mes hasta'});
                    ctlMesDesde.setValue(ctlMesHasta.value);
                    return null;
                }
            },
            createMesValidator('anioDesde', 'mesDesde'),
            createMesValidator('anioHasta', 'mesHasta'),
        ]
    });

    private searchTerm$: Observable<string> = this.searchBox.valueChanges
        .pipe(
            startWith(''), 
            debounceTime(500),
            distinctUntilChanged(),
            map((term) => term.trim()),
            map((term) => term.toUpperCase()),
            map((term) => StringHelper.removeTildes(term)),
            withLatestFrom(this.collapse$),
            map(([term, collapse]) => term)
        );

    private searchFn: SearchFn = (data: HistoriaVentas, term: string) => ([
            data.MATNR, 
            data.SPART, 
            data.KONDM, 
            data.nombreCategoria, 
            data.nombreSubcategoria, 
            data.nombreProducto
        ].some(i => 
            i.toUpperCase().includes(term.toUpperCase())
        )
    );

    private items$: Observable<HistoriaVentas[]> = 
        combineLatest([
            this.historia$, 
            this.searchTerm$,
            this.filterForm.valueChanges
        ])
        .pipe(
            map(([data, term, filters]) => [data.filter(i => this.searchFn(i, term)), filters]),
            map(([data, filters]) => data.filter(x => {
                let matcheaFecha = (x) => {
                    let anioDesde = parseInt(filters.anioDesde);
                    let mesDesde = parseInt(filters.mesDesde) + 1;
                    let anioHasta = parseInt(filters.anioHasta);
                    let mesHasta = parseInt(filters.mesHasta) + 1;

                    // Comparo lexicográficamente para no tener mil condiciones:

                    let fecha = x.SPMON.split('-').slice(0,2).join('-');

                    return fecha >= [anioDesde, mesDesde.toString().padStart(2, "0")].join('-')
                        && fecha <= [anioHasta, mesHasta.toString().padStart(2, "0")].join('-');
                };

                return (filters.categoria == '*' || x.SPART == filters.categoria) && matcheaFecha(x);
            }))
        );
    
    anios$ = this.historia$.pipe(
        map(x => x.map(y => y.SPMON)),
        filter(x => x.length > 0),
        map(x => x.reduce((min, y) => y < min ? y : min)),
        map(x => x.split('-').map(y => parseInt(y))),
        tap(([anioMin, mesMin]) => this.filterForm.patchValue({
            anioDesde: anioMin,
            mesDesde: mesMin - 1,
            anioHasta: new Date().getFullYear(),
            mesHasta: new Date().getMonth()
        })),
        map(([anioMin]) => {
            let anios = [];
            for (let i = anioMin; i <= (new Date()).getFullYear(); i++)
            {
                anios.push(i);
            }
            return anios;
        }),
    );

    categorias$ = this.historia$.pipe(
        map(data => data.map(x => ({value: x.SPART, label: x.nombreCategoria}))),
        // Elimino duplicados:
        map(categorias => categorias.filter((x, i) => categorias.findIndex(y => y.value == x.value) === i)),
        // Agrego categoria "Todas"
        map(x => [{ value: '*', label: 'Todas' }, ...x])
    )

/*     sumarioCategoria$ = this.groups$.pipe(
        map(groups => this.groupSummarizer(groups))
    ) */

    mesesDesde$ = createAnioBasedMesesObservable(this.filterForm.get('anioDesde'));
    mesesHasta$ = createAnioBasedMesesObservable(this.filterForm.get('anioHasta'));
    
}