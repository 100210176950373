<div class="app-page-title">
  <div class="page-title-wrapper">
    <div class="page-title-heading">
      <div class="page-title-icon">
        <i class="icon {{icon}}"></i>
      </div>
      <div>
        {{heading}}
        <div
          class="page-title-subheading">
          {{subheading}}
        </div>
      </div>
    </div>
    <div class="page-title-actions">
      <button type="button" class="btn-shadow mr-3 btn btn-dark">
        <i class="fas fa-star"></i>
      </button>
      <button (click)="handleClick($event)" type="button" class="btn-shadow d-inline-flex align-items-center btn btn-success">
        <i class="fas fa-plus mr-2"></i>
        Nuevo
      </button>
    </div>
  </div>
</div>
