import { Component, Input, OnInit } from "@angular/core";
import { TimelineEvent } from './models/TimelineEvent.model';

@Component({
    selector: 'app-timeline',
    templateUrl: './timeline.component.html'
})
export class TimelineComponent {
    @Input() title: string;
    @Input() events: TimelineEvent[];

    @Input() dateFormat: string;
}