import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
//import { Observable } from 'rxjs/Observable';

@Injectable()
export class InformacionService{
    public url: string;
    constructor(
        //@Inject(ListaPreciosApiClient) private api: GenericApiClient<ListaPrecios>,
        public _http: HttpClient
    ){
        this.url = "https://reqres.in/";
    }

    getUser(){
        return this._http.get(this.url+'api/users/2')
    }
}