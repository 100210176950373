import { Injectable } from "@angular/core";
import { Stock } from './Stock.model';
import { MaterialPrVtas, MaterialBasico } from '../models/ListaPrecios.model';
import { MaterialesService } from 'src/app/materiales.service';
import { Observable, of } from 'rxjs';
import { PedidosService } from 'src/app/pedidos/pedidos.service';
import { map, switchMap } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { URLHelper } from 'src/app/utils/url-helper.helper';
import { environment } from 'src/environments/environment';



@Injectable({
    providedIn: 'root'
})
export class StockService {

    static STATUS_GREEN = 'success';
    static STATUS_YELLOW = 'warning';
    static STATUS_RED = 'danger';

    constructor (private materialesService: MaterialesService, private pedidosService: PedidosService, private http: HttpClient) {}

    getBrutoByCentro(material: MaterialPrVtas, werks: string) {
        return this.getBruto(material, i => i.WERKS === werks);
    }

    getBrutoByCentroAndAlmacen(material: MaterialPrVtas, werks: string, lgort: string) {
        return this.getBruto(material, i => i.WERKS === werks && i.LGORT === lgort);
    }

    getStockByCentro(material: MaterialPrVtas, werks: string): number {
        let bruto = this.getBrutoByCentro(material, werks);
        return werks ? this.convertir(material, bruto) : 0;
    }

    getStockByCentroAndAlmacen(material: MaterialPrVtas, werks: string, lgort: string) {
        let bruto = this.getBrutoByCentroAndAlmacen(material, werks, lgort);
        return werks ? this.convertir(material, bruto) : 0;
    }

    getStockByCentroGroupByAlmacen(material: MaterialPrVtas, werks: string) {
        return material.mATNR.stocks
                .filter(s => s.WERKS === werks)
                .map(s => ({LGORT: s.LGORT, NAME: s.lGORT.NAME}))
                .map(({LGORT, NAME}) => ({
                    LGORT,
                    NAME,
                    STOCK: this.getStockByCentroAndAlmacen(material, werks, LGORT)
                }));
    }

    getStockStatus(material: MaterialPrVtas, werks: string, ajuste?: number): Observable<string> {
        return this.getPedidosPendientes(material)
            .pipe(
                switchMap((pendientes) => this.convertirPedidosPendientes(material, pendientes, ajuste)),
                map(pendientes => {
                    var disponible = this.getStockByCentro(material, werks);
                    disponible -= pendientes;

                    var status: string;
                    if (disponible < material.CANTIDAD || disponible <= 0) {
                        status = StockService.STATUS_RED;
                    } else if (disponible <= material.CANTIDAD * 2) {
                        status = StockService.STATUS_YELLOW;
                    } else {
                        status = StockService.STATUS_GREEN;
                    }

                    return status;
                })
            );
    }

    getPedidosPendientes(material: MaterialPrVtas/* , ajuste: number = 0 */): Observable<number> {
        const URL = URLHelper.concat(environment.API_BASE_URL, 'stock', 'pedidos-pendientes');
        return this.http
            .get<{result: number}>(
                URL, {
                params: {
                    matnr: material.MATNR
                }
            })
            .pipe(
                map(({result}) => result)
                /* map(({result}) => {
                    // result tiene la cantidad en Unidades

                    let kgUnidad = this.materialesService.calcularPesoPorUnidad(material.mATNR);
                    let display = this.materialesService.calcularDisplay(material.mATNR);
                    switch (material.UNIDAD) {
                        case 'UN': return result - ajuste;
                        case 'KG': return (result ) * kgUnidad - ajuste;
                        case 'CJ': return (result ) / display - ajuste;
                        default: return 0;
                    }
                }), */
            )
    }

    convertirPedidosPendientes(material: MaterialPrVtas, cantidad: number, ajuste: number = 0): Observable<number> {
        ajuste = !isNaN(ajuste) && isFinite(ajuste) ? ajuste : 0;
        let kgUnidad = this.materialesService.calcularPesoPorUnidad(material.mATNR);
        let display = this.materialesService.calcularDisplay(material.mATNR);
        switch (material.UNIDAD) {
            case 'UN': return of(cantidad - ajuste);
            case 'KG': return of(cantidad * kgUnidad - ajuste);
            case 'CJ': return of(cantidad / display - ajuste);
            default: return of(0);
        }
    }

    private getFloat(numero: number | string) {
        return parseFloat(numero as unknown as string);
    }

    private getBruto(material: MaterialPrVtas, filterCondition?: (i: Stock) => boolean) {
        const fn = filterCondition || (() => true);
        return material.mATNR.stocks
            .filter(fn)
            .reduce((sum, i) => sum + this.getFloat(i.LABST), 0);
    }

    private convertirAUnidades (material: MaterialBasico, kg: number): number {
        return kg / this.materialesService.calcularPesoPorUnidad(material);
    }

    private convertirACajas (material: MaterialBasico, kg: number): number {
        return this.convertirAUnidades(material, kg) / this.materialesService.calcularDisplay(material);
    }

    private convertir (material: MaterialPrVtas, cantidad: number): number {
        switch (material.UNIDAD) {
            case 'UN':
                return this.convertirAUnidades(material.mATNR, cantidad);
            case 'CJ':
                return this.convertirACajas(material.mATNR, cantidad);
            default:
                return cantidad;
        }
    }

}