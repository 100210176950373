import { Component, Input } from "@angular/core";
import { Condicion } from 'src/app/condiciones-comerciales/condiciones.models';
import { CondicionesService } from '../condiciones.service';
import { OrderHeaderIn } from 'src/app/pedidos/models/Order.model';

@Component({
    selector: 'app-cc-aplicadas',
    templateUrl: './condiciones-comerciales-aplicadas.component.html'
})
export class CondicionesComercialesAplicadasComponent {
    @Input() edit: boolean = false;
    @Input() loading: boolean = false;
    @Input() pedido: OrderHeaderIn;

    @Input() pedidoTieneCambios = false;

    get condiciones(): Condicion[] {
        return this.condicionesService.selectedCondiciones$.getValue();
    }

    constructor(private condicionesService: CondicionesService) {}
}