import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { tap, map } from "rxjs/operators";
import { IAccionSecundariaHeaderIn } from "src/app/condiciones-comerciales/condiciones.models";
import { OrderHeaderIn } from "src/app/pedidos/models/Order.model";
import { GenericApiClient } from "src/app/utils/api/generic-api-client.service";
import { NoRecordsError } from "src/app/utils/errors/NoRecordsError";
import { URLHelper } from "src/app/utils/url-helper.helper";
import { environment } from "src/environments/environment";
import { Page, PageRequest } from "../../utils/datasource/page.model";

export type SolicitudesCounts = {
  pendientes: number;
  aprobacion: number;
  facturados: number;
  todos: number;
};

const detailFieldsAndExpand = {
  fields: [
    "id",
    "SALESDOCUMENTIN",
    "DOC_DATE",
    "estadoDesc",
    "estado",
    "kUNNR.KUNNR",
    "kUNNR.NAME",
    "kUNNR.NAME2",
    "kUNNR.id",
    "claseDocsSap.denominacion",
    "parent.id",
    "parent.SALESDOCUMENTIN",
    "oRDREASON.BEZEI",
    "sALESORG.VTEXT",
    "dISTRCHAN.VTEXT",
    "dIVISION.VTEXT",
    "accionSecundariaAplicada.accion.nombre",
    "wfl_status",
  ].join(","),
  expand: [
    "kUNNR",
    "parent",
    "claseDocsSap",
    "oRDREASON",
    "sALESORG",
    "dISTRCHAN",
    "dIVISION",
    "orderHeaderObservaciones",
    "accionSecundariaAplicada.accion",
    "accionSecundariaAplicada.condicionVariante",
    "accionSecundariaAplicada.condicionVariante.condicion",
  ].join(","),
};
@Injectable({ providedIn: "root" })
export class SolicitudesSecundariasService {
  private apiClient: GenericApiClient<any>;

  constructor(private http: HttpClient) {
    this.apiClient = new GenericApiClient<any>("/secundarias", this.http);
  }

  fetchCounts(q: any, params: any = {}): Observable<SolicitudesCounts> {
    if (q) {
      Object.entries(q).forEach(([key, value]) => {
        if (value) {
          params[`SolicitudSecundariaSearch[${key}]`] = value;
        }
      });
    }

    params["count"] = true;

    const source = this.apiClient.list(params) as unknown as Observable<{
      counts: SolicitudesCounts;
    }>;
    return source.pipe(map((x) => x.counts));
  }

  fetchPage(
    request: PageRequest<any>,
    q: any,
    params: any = {}
  ): Observable<Page<any>> {
    params = Object.assign(
      {},
      {
        fields: [
          "id",
          "observaciones",
          "kUNNR.KUNNR",
          "kUNNR.NAME",
          "kUNNR.NAME2",
          "vendedor.PERNR",
          "vendedor.firstname",
          "vendedor.lastname",
          "DOC_DATE",
          "estadoDesc",
          "estado",
          "impNetoPendiente",
          "importe_total",
          "accionSecundariaAplicada.accion.descripcion",
          "dISTRCHAN.VTEXT",
          "informeCierre.order_header_in",
          "informeCierre.contenido",
          "wfl_status",
          "parent.importe_total",
        ].join(","),
        expand: [
          "kUNNR",
          "vendedor",
          "accionSecundariaAplicada.accion",
          "valorAcordado",
          "estadoColor",
          "valorFacturado",
          "observaciones",
          "dISTRCHAN",
          "informeCierre",
          "parent",
        ].join(","),
      },
      params
    );

    if (q) {
      Object.entries(q).forEach(([key, value]) => {
        if (value) {
          params[`SolicitudSecundariaSearch[${key}]`] = value;
        }
      });
    }

    return this.apiClient.page(request, params);
  }

  fetchOne(id, params: any = {}): Observable<any> {
    params = Object.assign({}, detailFieldsAndExpand, params);
    return this.apiClient.retrieve(id, params);
  }

  fetchItems(
    id,
    request: PageRequest<any>,
    q?: any,
    params: any = {}
  ): Observable<any> {
    params = Object.assign(
      {},
      {
        fields: [
          "mATERIAL.MAKTX",
          "MATERIAL",
          "TARGET_QTY",
          "TARGET_QU",
          "precioXUnidad",
          "importe",
          "tARGETQU.descripcion",
        ].join(","),
        expand: ["mATERIAL", "tARGETQU"].join(","),
      },
      params
    );

    if (q && q.searchTerm) {
      params["OrderItemsInSearch[searchTerm]"] = q.searchTerm;
    }
    return this.apiClient.of(id).navigate("items").page(request, params);
  }

  fetchPendientes(
    id,
    request: PageRequest<any>,
    q?: any,
    params: any = {}
  ): Observable<any> {
    params = Object.assign(
      {},
      {
        fields: [
          "mATERIAL.MAKTX",
          "MATERIAL",
          "TARGET_QTY",
          "TARGET_QU",
          "precioXUnidad",
          "importe",
        ].join(","),
        expand: "mATERIAL",
      },
      params
    );

    if (q && q.searchTerm) {
      params["OrderItemsInSearch[searchTerm]"] = q.searchTerm;
    }
    return this.apiClient.of(id).navigate("pendientes").page(request, params);
  }

  fetchParent(id, params: any = {}): Observable<OrderHeaderIn> {
    params = Object.assign(
      {},
      {
        fields: [
          "id",
          "SALESDOCUMENTIN",
          "claseDocsSap.denominacion",
          "estado",
          "estadoDesc",
          "DOC_DATE",
          "valorFacturado",
          "impNetoPendiente",
          "orderPartners.nombre",
          "orderPartners.rol",
          "orderPartners.ITM_NUMBER",
          "orderPartners.PARTN_NUMB",
        ].join(","),
        expand: ["claseDocsSap", "orderPartners"].join(","),
      },
      params
    );

    return this.apiClient
      .of(id)
      .navigate<OrderHeaderIn>("parent")
      .retrieve(undefined, params);
  }

  getExcelDownloadUrl(params: any) {
    const url = URLHelper.concat(
      environment.API_BASE_URL,
      "secundarias",
      "excel"
    );

    const newParams: any = {};

    Object.entries(params).forEach(([key, value]) => {
      if (value) {
        newParams[`SolicitudSecundariaSearch[${key}]`] = value;
      }
    });

    return this.http
      .get<{ count: number }>(url, { params: { ...newParams, count: true } })
      .pipe(
        map(({ count }) => {
          if (count == 0) {
            throw new NoRecordsError(
              "No hay registros para el filtro seleccionado"
            );
          }
          let urlSearchParams = new URLSearchParams();
          Object.keys(newParams).forEach((k) => {
            urlSearchParams.append(k, newParams[k]);
          });
          return `${url}?${urlSearchParams.toString()}`;
        })
      );
  }

  fetchAnios() {
    const url = URLHelper.concat(
      environment.API_BASE_URL,
      "secundarias",
      "anios"
    );

    return this.http.get<{ anioMin: number; anioMax: number }>(url);
  }

  update(data: IAccionSecundariaHeaderIn, params: any = {}) {
    const _params = Object.assign({}, detailFieldsAndExpand, params);
    return this.apiClient.update(data.id, data, _params);
  }

  autorizar(data: {
    order_header_in_id: number;
    autorizado: boolean;
    comentario: string;
  }) {
    return this.apiClient
      .of(data.order_header_in_id)
      .navigate<any>("autorizacion")
      .create(data);
  }
}
