import { Component, Input, Output, EventEmitter } from "@angular/core";
import { Texto } from '../models/Cliente.model';

@Component({
    selector: '[email-list-element]',
    template: `
    {{email.descripcion}} 
    <i *ngIf="email.solicitud && email.solicitud.pendiente" class="text-muted">({{ email.solicitud.es_baja ? 'baja' : 'alta' }} solicitada)</i> 
    <a href="javascript:void(0)" (click)="delete.emit($event)"><span class="pe-7s-trash text-danger"> </span></a>
    `
})
export class EmailListElementComponent {
    @Input() email: Texto;
    @Output() delete: EventEmitter<any> = new EventEmitter();
}