<div *ngIf="clientesDatasource" class="main-card mb-3 card">
  <div class="card-header">
    <div class="card-header-title font-size-lg text-capitalize font-weight-normal">Pedidos</div>
  </div>

  <ng-container *ngIf="(clientesDatasource.page$ | async) as page; else noData">
    <div *ngIf="page" class="p-2">
      <div class="form-group form-inline mb-2 justify-content-start float-left" [formGroup]="filters">
        <button class="my-2 btn btn-default btn-hover"
          (click)="toggleCollapse()">{{ !collapsed ? '- Colapsar Todo' : '+ Expandir Todo' }}</button>
        <div class="ml-2">
          <input class="form-control ml-2" type="text" id="searchBox" name="searchBox" placeholder="Buscar..."
            formControlName="searchTerm" />
        </div>
        <div class="ml-2 form-check form-check-inline">
          <input class="form-check-input" type="checkbox" id="pendientes" formControlName="con_pedidos_activos" />
          <label class="form-check-label" for="pendientes">Sólo Activos</label>
        </div>
      </div>
      <ng-container *ngIf="groups$|async as groups">
        <div *ngIf="groups.length > 0" class="float-right">
          <!-- <page-selector [collectionSize]="groups.length"></page-selector> -->
          <ngb-pagination 
              [collectionSize]="page.totalElements" 
              [page]="page.number" 
              (pageChange)="clientesDatasource.fetch($event)" 
              [maxSize]="10" 
              [pageSize]="page.size"
              [rotate]="true" 
              [ellipses]="false" 
              [boundaryLinks]="true">
            </ngb-pagination>
        </div>
      </ng-container> 
    </div>
    <div class="table-responsive p-2">
      <ng-container *ngIf="(groups$|async) as groups">
        <ng-container *ngIf="groups.length > 0; else noResults">
          <table class="align-middle text-truncate table table-hover" style="max-width: 100%;">
            <thead>
              <tr>
                <th class="text-left">#</th>
                <th class="text-left">Fecha Requerida</th>
                <th class="text-left">Fecha Doc</th>
                <th class="text-center">Estado</th>
                <th class="text-center">Apr.Cred</th>
                <th class="text-right">Imp. Neto</th>
                <th class="text-right">Pendiente</th>
                <th class="text-right">Días Atraso</th>
                <th class="text-left">Observaciones</th>
              </tr>
            </thead>
            <tbody *ngFor="let group of groups">
              <tr class="table-sm st-group-header" *ngIf="group.field !== '*'" (click)="group.toggleCollapse()">
                <th>
                  <button type="button" class="btn btn-default">
                    <span class="vsm-title"><i
                        [className]="group.collapsed ? 'vsm-arrow pe-7s-angle-right' : 'vsm-arrow pe-7s-angle-down'"
                        style="font-weight: bold; font-size: large;"></i></span>
                  </button>
                </th>
                <th colspan="9">{{ group.label | uppercase }}</th>
              </tr>
              <ng-container *ngIf="!group.collapsed">
                <tr *ngIf="group.loading"><td colspan="9">Cargando...</td></tr>
                <ng-container *ngFor="let item of group.data">
                  <tr [routerLink]="['/clientes', group.userdata?.cliente_id, 'pedidos', item.id]">
                    <td class="text-left"> {{ item.id }} </td>
                    <td class="text-left">{{ item.REQ_DATE_H | date:'dd/MM/yyyy' }}</td>
                    <td class="text-left">{{ item.DOC_DATE | date:'dd/MM/yyyy' }}</td>
                    <td class="text-center">
                      <app-badge-estado [orderHeader]="item"></app-badge-estado>
                    </td>
                    <td class="text-center">{{ item.apr_cred }}</td>
                    <td class="text-right">{{ item.importe_total | currency:'ARS' }}</td>
                    <td class="text-right">{{ item.impNetoPendiente | currency:'ARS' }}</td>
                    <td class="text-right">{{ (item.diasAtraso > 0 ? (item.diasAtraso | number:'1.0-0') : '-') }} </td>
                    <td class="text-left" [innerHtml]="item.ultimaObservacion?.OBSERVACION"></td>
                  </tr>
                </ng-container>
                <tr *ngIf="!group.loading">
                  <td colspan="9" [routerLink]="['/clientes', group.userdata?.cliente_id]" [fragment]="'ventas'">
                    <span class="btn btn-link" role="button">Ver más...</span>
                  </td>
                </tr>
              </ng-container>
            </tbody>
          </table>
        </ng-container>
        <ng-template #noResults>
          <div class="card-body">
            <p>No se encontraron resultados</p>
          </div>
        </ng-template>
      </ng-container>
    </div>
  </ng-container>
  <ng-template #noData>
    <div class="card-body">
      <p>No hay datos para mostrar</p>
    </div>
  </ng-template>
</div>