import { formatDate } from "@angular/common";
import { Component, Input } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { ClienteDatoComercial } from "../../clientes/models/Cliente.model";

@Component({
  selector: "app-pedido-header",
  templateUrl: "./pedido-header.component.html",
})
export class PedidoHeaderComponent {
  @Input() edit: boolean = true;
  @Input() form: FormGroup;
  @Input() esNuevo: boolean = false;

  private _datosComerciales: ClienteDatoComercial[];
  @Input() set datosComerciales(datos: ClienteDatoComercial[]) {
    if (datos != null) {
      this._datosComerciales = datos;
      if (datos.length == 1 && !this.selDatoCom.value) {
        setTimeout(() => this.selDatoCom.reset(datos[0]), 500);
      }
    }
  }

  @Input() vendedor: string;
  get datosComerciales() {
    return this._datosComerciales;
  }

  get TODAY() {
    const today = new Date();
    return formatDate(today, "yyyy-MM-dd", "es-AR");
  }

  // get listaPrecio() {
  //   return this.form.get("listaPrecio");
  // }

  get selDatoCom() {
    return this.form.get("selDatoCom");
  }

  get condicionPago() {
    return this.form.get("condicionPago");
  }
}
