<div class="card main-card mb-3" *ngIf="editInfo;else mostrarInfo">
  <div class="card-body">
    <angular-editor [(ngModel)]="ultimaObservacion.OBSERVACION" [config]="config"></angular-editor>

    <div class="divider"></div>
    <b>Previsualización:</b>
    <pre>
      {{ ultimaObservacion.OBSERVACION }}
    </pre>
  </div>
</div>

<ng-template #mostrarInfo>
  <div class="main-card mb-3 card">
    <div class="card-body">
      <div class="card-title">Informacion:</div>
      <div
        *ngIf="ultimaObservacion && ultimaObservacion.OBSERVACION; else nohayinformacion">
        <div [innerHTML]="ultimaObservacion.OBSERVACION | keepHtml"></div>
      </div>
      <ng-template #nohayinformacion>
        <p>No hay informacion para mostrar</p>
      </ng-template>
    </div>
  </div>
</ng-template>
