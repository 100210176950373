import { Component, Input } from "@angular/core";
import { OrderItemsIn } from './models/Order.model';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { FormControl } from '@angular/forms';
import { debounceTime, distinctUntilChanged, map, startWith } from 'rxjs/operators';
import { StringHelper } from '../utils/string.helper';

@Component({
    selector: 'items-pendientes',
    templateUrl: './items-pendientes.component.html',
    styles: [
        `
        .text-decoration-line-through {
            text-decoration-line: line-through !important;
        }
        `
    ]
})
export class ItemsPendientesComponent {
    private itemsPendientes$: BehaviorSubject<OrderItemsIn[]> = new BehaviorSubject([]);

    @Input() set itemsPendientes (value: OrderItemsIn[]) {
        this.itemsPendientes$.next(value);
    }

    get itemsPendientes () {
        return this.itemsPendientes$.getValue();
    }

    searchBox = new FormControl();

    items$ = combineLatest([
        this.itemsPendientes$, 
        this.searchBox.valueChanges.pipe(
            startWith(''),
            debounceTime(500),
            distinctUntilChanged(),
            map(searchTerm => StringHelper.removeTildes(searchTerm).trim().toLowerCase())
        )
    ]).pipe(
        map(([items, searchTerm]) => {
            return items.filter(i => {
                var descripcion = StringHelper.removeTildes(i.mATERIAL.MAKTX);
                var nroMaterial = StringHelper.removeTildes(i.MATERIAL);
                return descripcion.trim().toLowerCase().includes(searchTerm)
                    || nroMaterial.trim().toLowerCase().includes(searchTerm);
            });
        })
    )

    esBonificacionAnulada(item: { itemEntregaGratuita?: { ABGRU: string|null }}) {
        return !!item.itemEntregaGratuita && !!item.itemEntregaGratuita.ABGRU
    }
}