import { Component, Input } from "@angular/core";
import { Cliente, ClienteCredito } from '../models/Cliente.model';

@Component({
    selector: 'app-cliente-credito',
    templateUrl: './cliente-credito.component.html'
})
export class ClienteCreditoComponent {
    private _cliente: Cliente;

    @Input() set cliente (value: Cliente) {
        this._cliente = value;
        if (value) {
            let creditos = this.cliente.clienteCreditos;
            this.data = creditos && creditos.length ? creditos[0] : null;
        }
    }

    get cliente() { return this._cliente; }
    
    data: ClienteCredito = null;

    get disponible(): number {
        return Math.max(this.data.KLIMK - this.data.comptotal, 0);
    }

    get pendientes(): number {
        return this.data.montoPedidosPendientes;
    }

    get saldo(): number {
        return /* Math.max( */this.disponible - this.pendientes/* , 0) */;
    }

    get gradoAgotamiento(): number {
        return this.data.comptotal / (this.data.KLIMK || this.data.comptotal); // para evitar posible division por 0
    }

    get pendientesClass() {
        return this.disponibleClass;
    }

    get saldoClass() {
        return (this.saldo === 0) ? 'warning' :
            (this.saldo < 0) ? 'danger' : 'success';
    }

    get disponibleClass() {
        return (this.disponible < this.pendientes) ? 'danger' :
            (this.disponible * 2 < this.pendientes) ? 'warning' : 'success';
    }

    get agotamientoClass() {
        return (this.gradoAgotamiento >= 1) ? 'danger' :
            (this.gradoAgotamiento >= 0.5) ? 'warning' : 'success';
    }
}