import { Component, Input } from "@angular/core";
import { Tag } from "../lista-precios/models/MaterialBasico.model";

@Component({
  selector: "app-tags-badge",
  template: `
    <ng-container *ngFor="let tag of tags">
      <span class="badge" style="color:white;background-color:{{ tag.color }}">
        {{ tag.nombre }} </span
      ><br />
    </ng-container>
  `,
})
export class TagsBadgeComponent {
  @Input() tags: Array<Tag>;
}
